
import { Component, Vue } from 'vue-property-decorator';
import ProfileInfo from '@/components/users/profile/ProfileInfo.vue';
import Withdrawal from '@/components/users/profile/Withdrawal.vue';
import PaymentInfo from '@/components/users/profile/PaymentInfo.vue';

@Component({
  components: {
    PaymentInfo,
    ProfileInfo,
    Withdrawal,
  },
})
export default class Profile extends Vue {
  get userNickname(): string {
    const userNickname = this.$store.getters.userNickname;
    if (!userNickname) return '등록된 닉네임이 없습니다.';
    return userNickname;
  }
}
