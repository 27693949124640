
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ISurveyValue } from '@/interface/survey/question';
import type { IKeyValueSetting, IQuotaInterValue, IQuotaResetOption, IQuotaValue } from '@/interface/survey/quota';
import { QUOTA } from '@/types/quota';
import CreateNewProjectResetButton from '@/components/main/create/button/CreateNewProjectResetButton.vue';
import SkeletonTable from '@/components/main/create/sample/skeletonTable.vue';
import SETTING_OPTION = QUOTA.SETTING_OPTION;
import EventBus from '@/utils/EventBus';
import { STYLE } from '@/interface/style';
import CreateNewProjectResetButtonModal from '@/components/main/create/button/CreateNewProjectResetButtonModal.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import PlusIcon from "@/components/icons/PlusIcon.vue";

@Component({
  components: {
    PlusIcon,
    SkeletonTable,
    CreateNewProjectResetButton,
    CreateNewProjectResetButtonModal,
  },
})
export default class CreateNewProject extends Vue {
  @Prop() private items: Array<ISurveyValue>;
  @Prop() private viewFlag: string;
  @Prop() private surveyType: number;
  @Prop({ default: false }) private payment: boolean;
  @Prop({ default: false }) private samplingUpdate: boolean;


  settingData: IQuotaInterValue = QUOTA.settingDataInit();
  settingQuota: IQuotaValue = {
    RESPONSE_CNT: 0,
    GENDER: '',
    AGE_UNIT: '',
    AGE_10: [],
    AGE_5: [],
    QUOTA_SET: '',
    QUOTA_CNT: QUOTA.quotaCntInit(),
    AREA: [],
  };
  resetOption: IQuotaResetOption = QUOTA.resetSettingLoad();
  showing = false;
  responseCount: number = 0;
  // 패널 조사의 경우 최대 샘플 수 7150
  // COST 컬렉션 기준으로 변경 COST 컬렉션 최대 5000 샘플 기준
  limitResponseCountPanel: number = 5000;
  // 리스트 조사의 경우 최대 샘플 수 3000
  limitResponseCountList: number = 3000;
  currResClick: number = 0;
  currGenderClick: number = -1;
  currAgeUnitClick: number = -1;
  currAllocateClick: number = -1;
  // 쿼터 설정 성별
  arrayGender: Array<number> = [];
  // 쿼터 설정 나이 기준 5세, 10세
  arrayAge_5: Array<number> = [];
  arrayAge_10: Array<number> = [];
  // 쿼터 설정 나이 전체
  arrayAgeTotal: Array<number> = [2];
  // 지역 설정
  arrayArea: Array<number> = [];

  setFlag: string = '1';
  sumAgeArray: Array<number> = [];
  sumGenderArray: Array<number> = [];
  loadingTable: boolean = false;
  ageToggle1: boolean = false;
  ageToggle2: boolean = false;
  allocateToggle: boolean = false;
  responseWarningMsg: boolean = false;
  quotaLimit = {};
  quotaOverResult: boolean = false;
  quotaOverError: string = '';
  warningMsg: string = '';
  isLoading = false;

  //디자인 관련 상태값들
  nextBtnHover = false;
  next_hover = STYLE.NEXT_HOVER;
  next_unhover = STYLE.NEXT_UNHOVER;
  background_disabled = STYLE.BACKGROUND_DISABLED;

  nextStep = 0;
  lastStep = 4;

  responseHover: number = -1;
  genderHover: number = -1;
  ageHover: number = -1;
  ageGroup: number = -1;
  resetModalShow = false;

  created(): void {
    if (this.viewFlag) this.setFlag = this.viewFlag;
    this.load();
    this.eventBusListener();
  }

  eventBusListener() {
    EventBus.$on('quota-reset', (payload: QUOTA.SETTING_OPTION) => {
      this.quotaReset(payload);
    });
    EventBus.$on('modal-confirm', (payload) => {
      this.$bvModal.hide('create-project-reset-modal');
      if (this.resetOption.ALL === SETTING_OPTION.ALL) this.quotaReset(SETTING_OPTION.ALL);
    });
  }

  beforeDestroy() {
    EventBus.$off('quota-reset');
    EventBus.$off('modal-confirm');
  }

  async load(): Promise<void> {
    try {
      this.showing = true;
      const { data } = await this.axios.get( '/project/quota-inter');
      this.settingData = data;
      this.settingData?.RESPONDENT_COUNT?.push({ key: 9999, value: '직접 입력' });
      this.showing = false;
    } catch (e) {
      console.error(e);
    }
  }

  minResponseCount(responseCount: number) {
    if (responseCount < 100 && responseCount !== 0)
      this.warningMsgComputed = '최소 응답자 수는 100 명 입니다. 응답자 수를 다시 확인해 주세요.';
    else this.warningMsgComputed = '';
  }

  assignButtonDescription(type: number) {
    switch (type) {
      case 1:
        return '설정한 샘플 수를 인구주택총조사에 비례하여 각 쿼터에 할당합니다.';
      case 2:
        return '설정한 샘플 수를 각 쿼터에 균등하게 할당합니다.';
      case 3:
        return '설정한 샘플 수를 사용자가 임의로 할당합니다.';
    }
  }

  // 리스트 조사의 경우 3000 이상일 시 경고문 보여주기
  @Watch('responseCount')
  async watchResponseCount(curVal, prevVal) {
    let toastFlag = false;
    this.responseCount = +curVal;
    this.minResponseCount(this.responseCount);

    if (this.setFlag === '1' && this.responseCount > this.limitResponseCountPanel) {
      this.responseCount = this.limitResponseCountPanel;
      toastFlag = true;
    } else if (this.setFlag === '2' && this.responseCount > 3000) {
      this.warningMsgComputed = '리스트 조사의 경우 최대 3000 명 까지 설정 가능합니다.';
      this.responseCount = 3000;
      toastFlag = true;
    }

    if (toastFlag) {
      this.$common.makeToast(
        `응답자 최대 설정은 ${this.$common.numberFormat(this.responseCount)}명 까지 설정가능합니다.`,
        ToastVariant.DANGER,
        this.$bvToast
      );
    }
  }

  limitOver (v2: number, v3: number) {
    return this.settingQuota.QUOTA_CNT[v2 + '//' + v3 + '/'] > this.quotaLimitCount(v2 + '//' + v3 + '/')
  }

  set limitResponseCount(showMsg: boolean) {
    this.responseWarningMsg = showMsg;
  }
  get limitResponseCount() {
    return this.responseWarningMsg;
  }
  get getDiffQuotaCnt(): number {
    return Math.abs(this.responseCount - this.quotaTotal);
  }

  get isInputRespondent(): boolean {
    return this.responseCount >= 100;
  }

  get isInputGenderQuota(): boolean {
    return this.arrayGender.length !== 0;
  }

  get isInputQuota(): boolean {
    return this.currGenderClick !== -1 && this.currAgeUnitClick !== -1;
  }

  get isInputAllocate(): boolean {
    return this.currAllocateClick !== -1;
  }

  get isInputArea(): boolean {
    return this.arrayArea.length !== 0;
  }

  set warningMsgComputed(msg: string) {
    this.warningMsg = msg;
  }
  get warningMsgComputed() {
    return this.warningMsg;
  }

  get totalCnt(): number[] {
    const allGender = this.arrayGender[0] === 0;
    const returnArray: number[] = [];
    const total = Object.values(this.settingQuota.QUOTA_CNT);

    if (allGender) {
      const sliceNumber = total.length / 2;

      const man = total.slice(0, sliceNumber);
      const woman = total.slice(sliceNumber);

      let manSum = 0;
      let womanSum = 0;

      for (let i = 0; i < sliceNumber; i++) {
        manSum += Number(man[i]);
        womanSum += Number(woman[i]);
        const value = Number(man[i]) + Number(woman[i]);
        returnArray.push(value);
      }
      this.sumGenderArray = [manSum, womanSum];

      return returnArray;
    } else {
      let totalSum = 0;
      for (let i of total) {
        totalSum += Number(i);
        returnArray.push(+i);
      }

      this.sumGenderArray = [totalSum];
      return returnArray;
    }
  }

  get quotaTotal(): number {
    const values = Object.values(this.settingQuota.QUOTA_CNT);
    let total = 0;
    for (let i of values) {
      total += +i;
    }
    return total;
  }

  get returnArray(): Array<number> {
    let currKey = this.currAgeUnitClick;
    let result: Array<number> = [];
    if (currKey === 0) result = this.arrayAgeTotal;
    if (currKey === 1) result = this.arrayAge_10.sort();
    else if (currKey === 2) result = this.arrayAge_5.sort((a, b) => (+a < +b ? -1 : 1));
    return result;
  }

  get sortingGenderArray(): Array<number> {
    let arr: Array<number>;
    if (this.arrayGender.indexOf(0) !== -1) arr = [1, 2];
    else arr = this.arrayGender.sort();
    return arr;
  }

  get getQuotaCount(): number {
    let resultSum = 0;
    if (this.settingQuota?.QUOTA_CNT) {
      resultSum = Object.values(this.settingQuota?.QUOTA_CNT).reduce((p, c) => p + parseInt(c), 0);
    }
    return resultSum;
  }

  isNextBtnHover() {
    if (this.activeBtn !== undefined) {
      this.nextBtnHover = this.activeBtn;
    }
  }

  get activeBtn() {
    if (this.setFlag === '1') {
      switch (this.nextStep) {
        case 0:
          return this.isInputRespondent;
        case 1:
          return (
            this.isInputGenderQuota &&
            (this.currAgeUnitClick === 0 ||
              (this.currAgeUnitClick === 1 && this.arrayAge_10.length > 0) ||
              (this.currAgeUnitClick === 2 && this.arrayAge_5.length > 0))
          );
        case 2:
          if (this.isInputArea) {
            return true;
          }
          break;
        case 3:
          if (this.payment) return false;
          const responseCheck = this.isInputRespondent && this.isInputGenderQuota;
          const allAgeCheck = this.currAgeUnitClick === 0;
          const ageCheck = ((this.currAgeUnitClick === 1 && this.arrayAge_10.length > 0) ||
            (this.currAgeUnitClick === 2 && this.arrayAge_5.length > 0)) && this.checkQuotaCount
          const quotaCountCheck = this.totalCnt[0] !== 0;

          return responseCheck && (allAgeCheck || (ageCheck && quotaCountCheck))
        default:
          return false;
      }
    } else {
      return this.isInputRespondent;
    }
  }

  get nextBtnContent() {
    if (this.samplingUpdate) {
      if (this.payment) {
        if (this.surveyType === 1) {
          return {
            text: '결제 완료',
            next: true,
            disabled: true,
          };
        } else {
          return {
            text: '링크 확정 완료',
            next: true,
            disabled: true,
          };
        }
      }
    }

    if (
      this.setFlag === '2' ||
      (this.nextStep === 2 && this.currAgeUnitClick === 0 && this.currGenderClick === 0) ||
      (this.nextStep === 3 && this.isInputArea)
    )
      return {
        //text: '템플릿 선택',
        text: this.samplingUpdate ? '샘플링 수정' : '문항 편집/추가', //템플릿이 없어서 일단 문항 편집으로
        next: true,
        disabled: false,
      };

    if (
      this.settingData.AREA &&
      this.nextStep > 1 &&
      this.setFlag === '1' &&
      (this.isInputQuota || this.isInputAllocate)
    ) {
      return {
        text: `3/${this.lastStep}`,
        next: false,
        disabled: false,
      };
    }
    if (
      this.settingData.QUOTA.GENDER &&
      this.nextStep > 0 &&
      this.setFlag === '1' &&
      (this.isInputRespondent || this.isInputGenderQuota)
    ) {
      return {
        text: `2/${this.lastStep}`,
        next: false,
        disabled: false,
      };
    }
    return {
      text: `1/${this.lastStep}`,
      next: false,
      disabled: false,
    };
  }

  get allocateShow() {
    if (this.currGenderClick === -1) return false;
    if (this.currAgeUnitClick > 0 && this.arrayAge_5.length === 0 && this.arrayAge_10.length === 0) return false;
    return this.setFlag === '1' &&
      this.isInputArea &&
      (this.currGenderClick !== 0 || this.currAgeUnitClick !== 0) &&
      this.nextStep >= 3;

  }

  get allocateSort() {
    if (this.settingData.ALLOCATE) {
      return this.settingData.ALLOCATE.sort((a, b) => {
        const aNum = a.key === 3 ? 0 : a.key || 1;
        const bNum = b.key === 3 ? 0 : b.key || 1;
        return aNum < bNum ? -1 : 1;
      });
    }
    return [];
  }

  // key 1: 인구비례, key 3: 균등
  async getQuotaPart(key: number): Promise<void> {
    this.loadingTable = true;
    const sendData = {
      RESPONSE_CNT: Number(this.responseCount),
      GENDER: this.arrayGender.toString(),
      AGE_UNIT: this.currAgeUnitClick.toString(),
      AGE_10: this.arrayAge_10.map((x) => x.toString()),
      AGE_5: this.arrayAge_5.map((x) => x.toString()),
      key: key,
    };

    try {
      const { data } = await this.axios({
        url: '/project/quota/part',
        method: 'POST',
        data: sendData,
      });
      const { result } = data;
      if (result) {
        if (this.settingQuota?.QUOTA_CNT) this.settingQuota.QUOTA_CNT = result.cnt;
        this.sumAgeArray = result.SUM_AGE;
        this.sumGenderArray = result.SUM_GENDER;
        await this.getQuotaLimit();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingTable = false;
    }
  }

  bindNumber(event): void {
    !isNaN(event) ? (this.responseCount = +event) : (this.responseCount += event);
  }

  isUserInput(key: number): boolean {
    return key === 9999;
  }

  // 응답자 수
  responseClick(key: number, value: string | number): void {
    this.currResClick = key;
    let flag = true;
    const isUserInput = this.isUserInput(key);
    // isUserInput false 일 경우 최대치가 아니다 따라서 최대 토탈을 증가
    if (!isUserInput) this.responseCount = this.$common.sum(this.responseCount, value);

    // 총합이 0이 아닐 경우 TRUE
    if (!this.isInputRespondent) flag = false;

    if (!this.isInputQuota) flag = false;
    if (this.currAllocateClick < 1) {
      flag = false;
    } else if (this.currAllocateClick !== 0) {
      if (this.arrayArea.length === 0) flag = false;
    }
    if (flag) this.setAllocateState(this.currAllocateClick);
  }

  ageUnitClick(key: number): void {
    if (this.currAgeUnitClick !== key) this.resetLocation();
    this.currAgeUnitClick = key;

    if (key === 1) {
      this.ageToggle1 = true;
      this.ageToggle2 = false;
      this.arrayAge_5 = [];
    } else if (key === 2) {
      this.ageToggle1 = false;
      this.ageToggle2 = true;
      this.arrayAge_10 = [];
    } else {
      this.arrayAge_5 = [];
      this.arrayAge_10 = [];
      this.ageToggle1 = false;
      this.ageToggle2 = false;
      this.setAllocateState(this.currAllocateClick);
    }
    this.nextStepCheck(1);
  }

  // 쿼터 설정 : 성별 버튼 variant
  getGenderArray(key: number): number {
    return this.arrayGender.indexOf(key);
  }
  // 쿼터 설정 : 성별 버튼 이벤트
  async setGenderArray(key: number): Promise<void> {
    this.currGenderClick = key;
    if (this.settingData?.QUOTA?.GENDER) {
      this.settingData.QUOTA.GENDER.find((ele) => {
        if (key === ele.key) {
          // 키가 없을 경우 push
          if (this.arrayGender.indexOf(key) < 0) {
            //안에 존재하지 않을 경우 push
            this.arrayGender.push(key);
          } else {
            // 같은걸 다시 클릭 했을 경우
            this.arrayGender.splice(this.arrayGender.indexOf(key), 1);
          }
        }
      });

      if (this.arrayGender.length >= 2) {
        this.arrayGender = [key];
      }
      if (this.arrayGender.length === 0) {
        this.arrayGender = [key];
      }
      if (this.currAllocateClick > 0) {
        await this.setAllocateState(this.currAllocateClick);
      }
    }
    await this.getQuotaLimit();
    await this.nextStepCheck(1);
  }

  async setAgeFiveArray(key: number): Promise<void> {
    this.settingData?.QUOTA?.AGE_5?.find((ele) => {
      if (key === ele.key) {
        if (this.arrayAge_5.indexOf(key) < 0) {
          this.arrayAge_5.push(key);
        } else {
          this.arrayAge_5.splice(this.arrayAge_5.indexOf(key), 1);
        }
      }
    });

    if (this.currAllocateClick > 0) await this.setAllocateState(this.currAllocateClick);
    await this.getQuotaLimit();
  }

  getAgeFiveArray(key: number): number {
    return this.arrayAge_5.indexOf(key);
  }

  async setAgeTenArray(key: number): Promise<void> {
    this.settingData?.QUOTA?.AGE_10?.find((ele) => {
      if (key === ele.key) {
        if (this.arrayAge_10.indexOf(key) < 0) {
          this.arrayAge_10.push(key);
        } else {
          this.arrayAge_10.splice(this.arrayAge_10.indexOf(key), 1);
        }
      }
    });
    if (this.currAllocateClick > 0) await this.setAllocateState(this.currAllocateClick);
  }

  getAgeTenArray(key: number): number {
    return this.arrayAge_10.indexOf(key);
  }

  async setAreaArray(key: number) {
    let flag = true;
    this.loadingTable = true;
    if (!this.isInputAllocate) flag = false;
    this.settingData?.AREA?.find((ele) => {
      if (key === ele.key) {
        if (this.arrayArea.indexOf(key) < 0) {
          this.arrayArea.push(key);
        } else {
          this.arrayArea.splice(this.arrayArea.indexOf(key), 1);
        }
      }
    });

    if (this.arrayArea.length === 0) this.arrayArea.push(key);
    if (this.arrayArea.length >= 2) {
      if (this.arrayArea.indexOf(4) !== -1 && key !== 4) {
        const index = this.arrayArea.findIndex((f) => f === 4);
        this.arrayArea.splice(index, 1);
      } else if (
        (this.arrayArea.indexOf(1) !== -1 && this.arrayArea.indexOf(2) !== -1 && this.arrayArea.indexOf(3) !== -1) ||
        key === 4
      ) {
        this.arrayArea = [4]; // 전국 제외 모두 선택
      }
    }
    try {
      if (this.currAllocateClick === -1) {
        this.currAllocateClick = 3;
        await this.setAllocateState(this.currAllocateClick);
      }
      await this.getQuotaLimit();
      await this.nextStepCheck(2);
    } catch (e) {
      console.error(e);
    }
    this.loadingTable = false;
  }

  getAreaArray(key: number): number {
    return this.arrayArea.indexOf(key);
  }

  translateArr(array: Array<IKeyValueSetting>, key: number): string {
    let res = '';
    array.filter((item) => {
      if (key === item.key) {
        if (item.value) res = item.value.toString();
      }
    });
    return res;
  }

  async sendNextView() {
    if (this.nextStep === 0) {
      if (!this.isInputRespondent) {
        this.$common.makeToast(ToastMessage.SAMPLING_RESPONSE_NOT_MATCH, ToastVariant.DANGER, this.$bvToast);
        return false;
      } else {
        if (this.setFlag === '1') {
          this.nextStep = 1;
          return false;
        }
      }
    }

    if (this.setFlag === '1') {
      if (this.nextStep === 1) {
        if (!this.isInputGenderQuota) {
          this.$common.makeToast(ToastMessage.SAMPLING_GENDER, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
        if (this.currAgeUnitClick !== 0) {
          if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
            this.$common.makeToast(ToastMessage.SAMPLING_AGE, ToastVariant.DANGER, this.$bvToast);
            return false;
          }
        }

        this.nextStep = 2;
        return false;
      }

      if (this.nextStep === 2) {
        if (this.currAgeUnitClick !== 0) {
          if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
            this.$common.makeToast(ToastMessage.SAMPLING_AGE, ToastVariant.DANGER, this.$bvToast);
            return false;
          }
        }

        if (!this.isInputArea) {
          this.$common.makeToast(ToastMessage.SAMPLING_LOCATION, ToastVariant.DANGER, this.$bvToast);
          return false;
        } else if (this.currGenderClick !== 0 || this.currAgeUnitClick !== 0) {
          this.nextStep = 3;
          return false;
        }
      }

      if (this.nextStep === 3 && (this.currGenderClick !== 0 || this.currAgeUnitClick !== 0)) {
        if (this.currAllocateClick === -1) {
          this.$common.makeToast(ToastMessage.SAMPLING_QUOTA_TOTAL, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
        if (this.currAgeUnitClick !== 0 && this.currAllocateClick === 0) {
          this.$common.makeToast(ToastMessage.SAMPLING_QUOTA, ToastVariant.DANGER, this.$bvToast);
          return false;
        }

        if (this.currAgeUnitClick !== 0) {
          if (!this.checkQuotaCount()) {
            this.$common.makeToast(ToastMessage.SAMPLING_QUOTA_BUTTON, ToastVariant.DANGER, this.$bvToast);
            return false;
          }
        }
        if (this.quotaOverResult) {
          this.$common.makeToast(ToastMessage.SAMPLING_QUOTA_MAX, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
      }
    }
    this.isLoading = true

    if (this.settingQuota) {
      this.settingQuota.RESPONSE_CNT = Number(this.responseCount);
      if (this.setFlag === '1') {
        this.settingQuota.GENDER = this.arrayGender.toString();
        this.settingQuota.AGE_UNIT = this.currAgeUnitClick.toString();
        this.settingQuota.AGE_10 = this.arrayAge_10.map((x) => x.toString());
        this.settingQuota.AGE_5 = this.arrayAge_5.map((x) => x.toString());
        this.settingQuota.QUOTA_SET = this.currAllocateClick.toString(); // 위 AGE_UNIT 과 동일하여 실수인거 같아 변경하였음
        this.settingQuota.AREA = this.arrayArea.map((x) => x.toString());
        // 전체 / 전체 일 경우 초기값으로
        if (this.currAgeUnitClick.toString() === '0' && this.arrayGender.toString() === '0') {
          this.settingQuota.QUOTA_CNT = QUOTA.quotaCntInit();
          this.settingQuota.QUOTA_SET = '1';
        }
      }
    }
    this.$emit('next-view', this.settingQuota);
  }

  checkQuotaCount(): boolean {
    return this.getQuotaCount === Number(this.responseCount);
  }

  async setAllocateState(key: number): Promise<Boolean> {
    if (!this.isInputRespondent) {
      this.$common.makeToast(ToastMessage.SAMPLING_RESPONSE_NOT_MATCH, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.currAgeUnitClick < 0 || !this.isInputGenderQuota) {
      return false;
    } else if (this.currAgeUnitClick > 0) {
      if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
        this.$common.makeToast(ToastMessage.SAMPLING_AGE_RANGE, ToastVariant.DANGER, this.$bvToast);
        return false;
      }
    }
    this.currAllocateClick = key;
    if ((key > 0 && this.currAgeUnitClick > 0) || this.currGenderClick > 0) {
      await this.getQuotaPart(key);
      this.allocateToggle = true;
    } else {
      this.allocateToggle = false;
    }
    return true;
  }

  resetRespondent() {
    this.responseCount = 0;
    this.currResClick = -1;
  }

  resetQuota() {
    this.currAgeUnitClick = -1;
    this.currGenderClick = -1;
    this.arrayAge_5 = [];
    this.arrayAge_10 = [];
    this.ageToggle1 = false;
    this.ageToggle2 = false;
    this.arrayGender = [];
  }

  resetAllocate() {
    this.currAllocateClick = -1;
    this.sumAgeArray = [];
    this.sumGenderArray = [];
    this.allocateToggle = false;
    this.arrayGender = [];
  }
  resetLocation() {
    this.sumAgeArray = [];
    this.sumGenderArray = [];
    this.allocateToggle = false;
  }

  resetArea() {
    this.arrayArea = [];
  }

  async quotaReset(option: QUOTA.SETTING_OPTION): Promise<void> {
    if (this.samplingUpdate && this.payment) {
      this.$common.makeToast(
        `${this.surveyType === 1 ? '결제를' : '링크 확정을'} 완료하여 초기화가 불가능 합니다.`,
        ToastVariant.DANGER,
        this.$bvToast
      );
      return;
    }

    switch (option) {
      case QUOTA.SETTING_OPTION.ALL:
        this.resetRespondent();
        this.resetQuota();
        this.resetAllocate();
        this.resetArea();
        this.nextStep = 0;
        break;
      case QUOTA.SETTING_OPTION.QUOTA:
        this.resetQuota();
        this.resetArea();
        this.resetAllocate();
        this.nextStep = 0;
        break;
      case QUOTA.SETTING_OPTION.AREA:
        this.resetArea();
        this.resetLocation();
        this.nextStep = 1;
        break;
      case QUOTA.SETTING_OPTION.ALLOCATE:
        this.resetLocation();
        this.nextStep = 2;
        break;
      default:
        this.settingData = QUOTA.settingDataInit();
        break;
    }
  }

  setSettingQuota(samplingQuota: IQuotaValue, surveyType: string) {
    const { RESPONSE_CNT, AGE_UNIT, AGE_10, AGE_5, GENDER, QUOTA_SET, AREA } = samplingQuota;
    this.settingQuota = samplingQuota;
    if (surveyType) this.setFlag = surveyType;
    if (RESPONSE_CNT) this.responseCount = RESPONSE_CNT;
    if (AGE_UNIT) {
      this.currAgeUnitClick = +AGE_UNIT;
      this.ageUnitClick(this.currAgeUnitClick);
      if (this.currAgeUnitClick !== 0) {
        if (AGE_5) this.arrayAge_5 = AGE_5.map((m) => +m);
        if (AGE_10) this.arrayAge_10 = AGE_10.map((m) => +m);
      }
    }

    if (GENDER) {
      this.currGenderClick = +GENDER;
      this.arrayGender = [+GENDER];
    }
    if (AREA) this.arrayArea = AREA.map((m) => +m);
    if (QUOTA_SET && +QUOTA_SET !== 0) {
      this.currAllocateClick = +QUOTA_SET;
      this.allocateToggle = true;
    }

    this.nextStep = 0; // 리스트 조사일 경우
    if (this.setFlag === '1') {
      this.nextStep = 3; // 쿼터 설정이 전체가 아닐 경우
      if (this.currGenderClick === 0 && this.currAgeUnitClick === 0) {
        this.nextStep = 2; // 쿼터 설정이 전체 일 경우
      }
    }
    this.getQuotaLimit();
  }

  async getQuotaLimit() {
    if (!this.settingData.ALLOCATE || this.setFlag !== '1' || !this.isInputArea) return;
    try {
      const { data } = await this.axios({
        url: '/project/quota/limit',
        method: 'POST',
        data: {
          RESPONSE_CNT: this.settingQuota.RESPONSE_CNT,
          GENDER: this.arrayGender.toString(),
          AGE_UNIT: this.currAgeUnitClick.toString(),
          AGE_10: this.arrayAge_10.map((x) => x.toString()),
          AGE_5: this.arrayAge_5.map((x) => x.toString()),
          AREA: this.arrayArea,
          QUOTA_SET: this.settingQuota.QUOTA_SET,
          QUOTA_CNT: this.settingQuota.QUOTA_CNT,
        },
      });
      const { result, limit } = data;
      if (result) {
        this.quotaLimit = limit;
        this.quotaLimitOver();
      }
    } catch (e) {
      console.error(e);
    }
  }

  quotaLimitCount(key) {
    let num = 9999;
    const value = this.quotaLimit[key];
    if (value) num = +value;
    return num;
  }

  quotaInputNumberCheck(evt) {
    const { target } = evt;
    const { value } = target;
    target.value = String(value)
      .replace(/[^0-9]/g, '')
      .replace(/(^0+)/, '');

    this.quotaLimitOver();
  }

  quotaLimitOver() {
    this.quotaOverResult = false;
    this.quotaOverError = '';

    const inputs = this.settingQuota.QUOTA_CNT;
    for (const i in inputs) {
      const input = +inputs[i];
      const max = +this.quotaLimit[i];
      if (isNaN(input) || isNaN(max)) {
        this.quotaOverResult = true;
        this.quotaOverError = '할당 설정 최대값 확인 오류 발생하였습니다. 관리자에게 문의하여 주세요.';
        break;
      } else if (input > max) {
        this.quotaOverResult = true;
        this.quotaOverError =
          '할당 설정표에 MAX 초과로 입력할 수 없습니다. MAX 초과로 입력하고 싶은 경우 관리자에게 문의하여 주세요.';
        break;
      }
    }
  }

  async nextStepCheck(curStep) {
    if (this.currGenderClick === 0 && this.currAgeUnitClick === 0) {
      this.lastStep = 3;
    } else {
      this.lastStep = 4;
    }

    if (
      curStep === 1 &&
      curStep < this.nextStep &&
      this.currAgeUnitClick !== 0 &&
      this.arrayAge_10.length === 0 &&
      this.arrayAge_5.length === 0
    ) {
      this.nextStep = 2;
    }
  }

  async updateQuotaSet(event) {
    this.$common.onlyNumberEvent(event);
    if (this.responseCount === 0 && this.isInputGenderQuota) {
      await this.quotaReset(SETTING_OPTION.QUOTA);
      await this.quotaReset(SETTING_OPTION.ALLOCATE);
      await this.quotaReset(SETTING_OPTION.AREA);
    }
    let flag = true;
    if (this.currAllocateClick < 1) {
      flag = false;
    } else if (this.currAllocateClick !== 0) {
      if (this.arrayArea.length === 0) flag = false;
    }

    if (flag) await this.setAllocateState(this.currAllocateClick);
  }
}
