import Chart from 'chart.js';
import { IViewOption } from '@/interface/survey/chart';

export function formatterSetting(value, context, option): string {
  const { dataIndex } = context;
  const cnt = option.cntArray[dataIndex];
  return getFormatString(value, cnt, option.viewOption);
}

export function getFormatString(value, cnt, viewOption: IViewOption) {
  // 100이 넘어가면 Label Width 가 오버 됩니다.
  if (value === 100) return '';
  if (!value) return '';
  switch (viewOption) {
    case 'all': {
      return `${value}% ( ${cnt}명 )`;
    }
    case 'n': {
      return ` ${cnt} 명 `;
    }
    case 'percent': {
      return `${value}%`;
    }
    default: {
      return `${value}%`;
    }
  }
}

export function pieOption(cntArray: number[], keyArray: string[], viewOption: IViewOption): Chart.ChartOptions {
  const option = {
    cntArray,
    viewOption,
  };

  return {
    plugins: {
      datalabels: {
        anchor: 'center',
        borderRadius: 3,
        color: (context) => {
          const idx = context.dataIndex;
          const dataArray = context.dataset.data as number[];
          const isNull = !dataArray[idx];
          return isNull ? '' : '#f3f3f3';
        },
        padding: 3,
        textStrokeColor: '#3f3f3f',
        textStrokeWidth: 2.5,
        formatter: (value, context) => formatterSetting(value, context, option),
        align: 'end',
        offset: (context) => {
          const idx = context.dataIndex;
          const dataArray = context.dataset.data as number[];
          return dataArray[idx] > 5 ? -17 : dataArray[idx] < 3 ? Math.round(100 / (dataArray[idx] + 1)) : 50;
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      callbacks: {
        title(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
          const idx = item[0].index as number;
          const { labels } = data as { labels: [] };
          return labels[idx];
        },
        beforeBody(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
          const idx = item[0].index || 0;
          return cntArray[idx] + '번 선택됨';
        },
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          const idx = tooltipItem.index as number;
          const { datasets } = data as { datasets: { backgroundColor: []; data: [] }[] };
          return datasets[0].data[idx] + '%';
        },
      },
    },
    legend: {
      position: 'bottom',
      display: true,
      align: 'start',
      labels: {
        padding: 15,
        boxWidth: 30,
      }
    },
    layout: {
      padding: 30,
    },
    scales: {
      scaleLabel: {
        display: true,
      },
    },
  };
}

export function horizontalBarOption(
  cntArray: number[],
  keyArray: string[],
  viewOption: IViewOption
): Chart.ChartOptions {
  const option = {
    cntArray,
    viewOption,
  };
  return {
    plugins: {
      datalabels: {
        anchor: 'end',
        borderRadius: 3,
        color: (context) => {
          const idx = context.dataIndex;
          const dataArray = context.dataset.data as number[];
          const isNull = !dataArray[idx];
          return isNull ? '' : '#000000';
        },
        padding: 3,
        formatter: (value, context) => formatterSetting(value, context, option),
        backgroundColor: (context) => {
          const idx = context.dataIndex;
          const dataArray = context.dataset.data as number[];
          const isNull = !dataArray[idx];
          if (dataArray[idx] === 100) return '';
          return isNull ? '' : '#ffffff';
        },
        align: 'right',
        offset: 13,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      callbacks: {
        title(item: Chart.ChartTooltipItem[]): string | string[] {
          return item[0].yLabel + '';
        },
        beforeBody(item: Chart.ChartTooltipItem[], data: Chart.ChartData): string | string[] {
          const idx = item[0].index || 0;
          return cntArray[idx] + '번 선택됨';
        },
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          return tooltipItem.xLabel + '%';
        },
      },
    },
    legend: {
      display: false,
    },
    layout: {
      padding: 5,
    },
    scales: {
      scaleLabel: {
        display: true,
      },
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback(
              value: number | string,
              index: number,
              values: number[] | string[]
            ): string | number | null | undefined {
              return value + '' + '%';
            },
            beginAtZero: true, // minimum value will be 0.
            max: 100,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            callback(value: number | string, idx: number): any {
              const xLabel = '' + value;
              let defaultIndex = 35;
              const isXLabelOver = xLabel.length > defaultIndex;
              const key = `[${keyArray[idx]}]`;

              if (isXLabelOver) {
                const textArray: string[] = [];
                const loopCount = Math.ceil(xLabel.length / defaultIndex);
                for (let i = 0; i < loopCount; i++) {
                  let label = xLabel.substring(defaultIndex * i, (i + 1) * defaultIndex);
                  if (i === loopCount - 1) label += ` ${key}`;
                  textArray.push(label);
                }
                return textArray
              } else {
                return xLabel + ' ' +key;
              }
            },
            padding: 5,
            fontSize: 12,
            beginAtZero: true, // minimum value will be 0.
            max: 100,
          },
        },
      ],
    },
  };
}
