
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ITemplate } from '@/interface/template/template';
import PreviewTemplateModal from '@/components/main/PreviewTemplateModal.vue';

@Component({
  components: {
    PreviewTemplateModal,
  },
})
export default class TemplateCard extends Vue {
  @Prop() template: ITemplate;
  @Prop() readonly favorite: boolean;
  @Prop() readonly height: number;
  @Prop({ default: '' }) color: string;
  @Prop() private modalFlag: string;

  cardHover: boolean = true;

  registerFavoriteTemplate() {
    this.$emit('favoriteChange', this.template);
  }

  sendTemplate(): void {
    this.$bvModal.show(`preview-template-modal-${this.modalFlag}-${this.template._id}`);
  }

  hoverCard(): void {
    this.cardHover = !this.cardHover;
  }
}
