
import { Component, Vue } from 'vue-property-decorator';
import WithdrawalInformation from '@/components/users/profile/withdrawal/WithdrawalInformation.vue';
import WithdrawalReason from '@/components/users/profile/withdrawal/WithdrawalReason.vue';
import WithdrawalModal from '@/components/users/profile/withdrawal/WithdrawalModal.vue';
import '@/assets/css/component/layout/user-setting.scss';

@Component({
  components: { WithdrawalModal, WithdrawalInformation, WithdrawalReason },
})
export default class UserDeleteBtn extends Vue {
  openWithdrawInformation = false;
  openWithdraw() {
    this.openWithdrawInformation = true;
  }
}
