
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ITemplate } from '@/interface/template/template';
import PreviewTemplateModal from '@/components/main/PreviewTemplateModal.vue';
import CreateNewProjectModal from '@/components/main/create/CreateNewProjectModal.vue';
import TemplateCard from '@/components/project/template/TemplateCard.vue';
import { TEMPLATE } from '@/types/template';
import { ITemplateLoadDto } from '@/interface/common';
import EventBus from '@/utils/EventBus';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    CreateNewProjectModal,
    PreviewTemplateModal,
    TemplateCard,
    TextBoxModal,
  },
})
export default class TemplateContent extends Vue {
  @Prop({ default: 0 }) readonly height: number;

  private items: TEMPLATE.List = [];
  loading: boolean = true;
  flag: number = 0;
  mode: number = 0;

  pageNum: number = 1;
  currentPage: number = 1;
  totalPage: number = 0;
  limit: number = 3;

  searchWord: string = '';

  modalFlag: string = 'content';
  templateId: number = 0;
  searchButtonHover: boolean = true;
  nextPageButton: boolean = false;
  prevPageButton: boolean = false;

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  async created() {
    await this.templateLoad();
  }

  mounted() {
    EventBus.$on('reloadTemplateGallery', (payload) => {
      if (payload) this.templateReload();
    });
  }

  sendTemplateId(id: number) {
    this.templateId = id;
  }

  async reload(sendData: ITemplateLoadDto) {
    this.loading = true;
    const { data } = await this.axios({
      url: '/template/list',
      method: 'GET',
      params: sendData,
    });
    const { info, result } = data;
    const { survey, total } = info;
    this.totalPage = Math.ceil(total / this.limit);

    if (result === true && survey.length > 0) {
      this.items = survey;
    }
    this.loading = false;
  }

  async nextPage() {
    this.currentPage++;
    this.pageNum++;
    this.nextPageButton = false;

    const sendData: ITemplateLoadDto = this.createSendData();
    await this.reload(sendData);
  }

  async prevPage() {
    this.currentPage--;
    this.pageNum--;
    this.prevPageButton = false;

    const sendData: ITemplateLoadDto = this.createSendData();
    await this.reload(sendData);
  }

  async templateLoad(): Promise<void> {
    try {
      this.loading = true;
      const sendData: ITemplateLoadDto = this.createSendData();
      const { data } = await this.axios({
        url: '/template/list',
        method: 'GET',
        params: sendData,
      });
      const { info, result } = data;
      const { survey, total } = info;
      this.totalPage = Math.ceil(total / this.limit);

      if (result === true && survey.length > 0) {
        this.items.push(...survey);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  updateTemplateFavorite(value: ITemplate): void {
    this.updateTemplateFix(value);
  }

  async templateListCount(): Promise<number | undefined> {
    try {
      const { data } = await this.axios({
        url: '/template/book-mark',
        method: 'GET',
        params: { pageNum: 1 },
      });

      const { getData } = data as { getData: TEMPLATE.List };
      return getData?.length > 0 ? getData?.length : 0;
    } catch (e) {
      console.log(e);
    }
  }

  async registerFavoriteTemplate() {
    const size = await this.templateListCount();
    if (size !== undefined) {
      if (size < 10) return true;
      else this.$common.makeToast(ToastMessage.LIMIT_FIXED_TEMPLATE, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
  }

  async updateTemplateFix(value: ITemplate): Promise<void> {
    if (!value.CHECKED) {
      const templateLimit = await this.registerFavoriteTemplate();
      if (!templateLimit) return;
    }

    value.CHECKED = !value.CHECKED;
    try {
      const sendData = {
        sNum: value._id,
        userId: this.$store.getters.userId,
        checked: value.CHECKED,
      };
      const { data } = await this.axios({
        url: '/template/book-mark',
        method: 'POST',
        data: sendData,
      });
      const { result } = data;

      if (result) {
        let variant = '';
        const status = value.CHECKED ? '즐겨찾기 등록' : '즐겨찾기 해제';
        const message = `템플릿 [${value.TITLE}] ${status}되었습니다`;
        value.CHECKED ? (variant = 'info') : (variant = 'warning');
        this.$common.makeToast(message, variant, this.$bvToast);
        await this.templateReload();
        await this.templateUpdate();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async templateReload() {
    try {
      const sendData = this.createSendData();
      await this.reload(sendData);
    } catch (e) {
      console.log(e);
    }
  }

  createSendData(): ITemplateLoadDto {
    return {
      pageNum: this.pageNum,
      searchWord: this.searchWord,
      parPage: this.limit,
    };
  }

  async searchTemplate() {
    const sendData = this.createSendData();
    await this.reload(sendData);
  }

  templateUpdate(): void {
    this.$emit('template-update');
  }
}
