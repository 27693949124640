var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"create-complete",attrs:{"question-name":_vm.data.NAME,"question-type":_vm.data.TYPE,"data-page_end":_vm.data.PAGE_END,"data-type":_vm.data.TYPE,"data-name":_vm.data.NAME}},[_c('QuestionHtml',{attrs:{"QNUM":_vm.data.QNUM,"QUESTION":_vm.data.QUESTION,"DESC":_vm.data.DESC,"question-type-name":_vm.questionTypeName}}),_c('div',{ref:"s-answer",staticClass:"s-answer question-content-body-2"},[_c('div',{class:_vm.data.NO_BACKGROUND ? '' : 's-desc',style:([
        _vm.data.BG
          ? {
              backgroundColor: _vm.data.BG,
            }
          : '',
        _vm.data.BORDER
          ? {
              border: _vm.data.BORDER,
            }
          : '',
      ])},_vm._l((_vm.data.DATA),function(value,index){return _c('div',{key:index},[_c('p',{style:(value.ALIGN ? `text-align: ${value.ALIGN}` : ''),domProps:{"innerHTML":_vm._s(value.HTML)}})])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }