
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionRadioSets } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';
import { IAnswerValue } from '@/interface/survey/answer';
import { QUESTION } from '@/types/question';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class RadioSets extends Vue {
  @Prop() private data: IQuestionRadioSets;
  @Prop() private questionTypeName?: string;

  answers: IAnswerValue[] = [];
  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      ANSWERS = [],
      RANDOM,
      BACKGROUND = '',
      H_HEAD = '',
      H_WIDTH = '',
      BY_CARD = false,
      KEY_SHOW = false,
      NAME,
      V = [],
      H = [],
      SHOW = [],
      HIDE = [],
      HSHOW = [],
      HHIDE = [],
      MOBILE_UI = false,
      FIXED_THEAD = false,
      V_WIDTH = '40',
      COL_WIDTHS = '',
      V_HEAD = '',
    } = this.data;
    let BY_LINE = this.data.BY_LINE || false;
    const answerBox = this.$refs['s-answer'];
    const colors = this.$common.makeGradient(BACKGROUND, H.length);
    let cardDiv;
    /*
        var show=Condition(data.SHOW);	//보여질 문항
        var hide=Condition(data.HIDE);	//가려질 문항
        var hshow=Condition(data.HSHOW);	//보여질 값
        var hhide=Condition(data.HHIDE);	//가려질 값
        */

    //카드로 하나씩 제시인가?
    if (BY_CARD) {
      cardDiv = document.createElement('div');
      cardDiv.classList.add('card-div');
      cardDiv.setAttribute('qname', NAME);
      cardDiv.style.width = '100%';
      cardDiv.style.margin = '24px 0';
      cardDiv.style.border = '1px solid #999';
      cardDiv.style.boxShadow = '3px 3px 8px';
      cardDiv.style.borderRadius = '.5em';
      cardDiv.style.fontSize = '150%';
      cardDiv.style.textAlign = 'center';
      cardDiv.style.padding = '.5em 0 .8em';
      const span = document.createElement('span');
      span.innerText = '&nbsp';
      cardDiv.appendChild(span);
      answerBox.appendChild(cardDiv);

      if (MOBILE_UI) cardDiv.style.fontSize = '150%';
      //카드로 하나씩이면 한줄씩은 무조건 적용해야 한다
      BY_LINE = true;
    }

    let vWidth, tr, th, catTr, table, thead;
    table = document.createElement('table');
    table.classList.add('pure-table', 'pure-table-bordered', 'w-100', 'pure-table-striped', 'common', 'fixed');
    answerBox.appendChild(table);
    if (FIXED_THEAD) table.classList.add('fixed-top');
    thead = document.createElement('thead');
    table.appendChild(thead);
    vWidth = V_WIDTH;
    if (H.length > 0 && (H[0].C1 || '') !== '') {
      catTr = document.createElement('tr');
      thead.appendChild(catTr);
      th = document.createElement('th');
      th.classList.add('first-th');
      th.style.width = `${vWidth}$`;
      th.setAttribute('rowspan', '2');
      catTr.appendChild(th);
      if (BY_CARD) th.style.display = 'none';
      tr = document.createElement('tr');
      thead.appendChild(tr);
    } else {
      tr = document.createElement('tr');
      thead.appendChild(tr);
      th = document.createElement('th');
      th.classList.add('first-th');
      th.style.width = `${vWidth}$`;
      if (BY_CARD) th.style.display = 'none';
      tr.appendChild(th);
    }
    //설명 머릿말 있으면 표시
    if (V_HEAD !== '') {
      th.innerHTML = V_HEAD;
    }

    //보기 분류가 있는 경우
    if (V.length > 0 && (V[0].C1 || '') != '') th.setAttribute('colspan', '2');

    //열 폭이 직접 지정된 경우
    if (COL_WIDTHS !== '') {
      table.querySelectorAll('td, th').forEach((item) => (item.style.width = ''));
      const widths = COL_WIDTHS.split(',');
      const colGroup = document.createElement('colgroup');
      table.insertBefore(colGroup, table.firstChild);

      widths.forEach(function (width) {
        const col = document.createElement('col');
        col.style.width = width.replace(/ /g, '');
        colGroup.appendChild(col);
      });
    }

    //모바일 UI
    if (MOBILE_UI) thead.classList.add('mobile-ui-hide');
    let hCount = 0;
    let headValueMaxHeight = 0;

    for (let h = 0; h < H.length; h++) {
      /*
            if(hshow!=null && hshow.indexOf(data.H[h].K)<0)continue;	//보여질 문항 처리
            if(hhide!=null && hhide.indexOf(data.H[h].K)>=0)continue;	//가려질 문항 처리
            */
      hCount++;

      //카테고리 추가
      if ((H[h].C1 || '') != '') {
        const lastTh = catTr.querySelector('th:last-child');
        const lastThText = lastTh.innerHTML;
        if (lastThText != '' && H[h].C1 === lastThText) {
          //마지막 카테고리가 공백이 아니고 이번 카테고리와 같으면 병합
          const colspan = Number(lastTh.getAttribute('colspan') || '1');
          lastTh.setAttribute('colspan', String(colspan + 1));
        } else {
          th = document.createElement('th');
          th.innerHTML = H[h].C1;
          th.setAttribute('column-index', String(hCount));
          th.style.padding = '8px 2px';
          catTr.appendChild(th);
          //카테고리와 응답값 머릿말이 같으면 병합한다
          if (H[h].C1 === H[h].V) th.setAttribute('rowspan', '2');
        }
      }

      //카테고리와 응답값 머릿말이 같으면 병합했을테니 다를 경우에만 응답값 머릿말을 추가한다
      if ((H[h].C1 || '') != H[h].V) {
        //응답값 머릿말 추가
        th = document.createElement('th');
        tr.appendChild(th);
        if (colors !== null) th.style.backgroundColor = colors[hCount - 1];

        const hKey = H[h].K;
        th.setAttribute('hKey', hKey);
        th.setAttribute('column-index', String(hCount));

        let html = H[h].V;
        const reg = /\[TEXT_[\d]+\]/;
        let ord = 1;
        while (html.match(reg)) {
          const matchText = html.match(reg)![0];
          const typeNum = matchText.substr(1, matchText.length - 2).split('_');
          const type = typeNum[0];
          const num = typeNum[1];
          html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
          ord++;
          th.classList.add('has-etc');
        }
        th.innerHTML = html;

        th.querySelectorAll('span[type][num]').forEach((item) => {
          const type = item.getAttribute('type');
          const num = item.getAttribute('num');
          const ord = item.getAttribute('ord');
          const dataColumn = hKey + '_HETC_' + ord;
          const textInput = document.createElement('input');
          textInput.setAttribute('type', 'text');
          textInput.classList.add('input-etc');
          textInput.style['width'] = `${this.data[type + '_' + num].WIDTH}px`;
          textInput.style['text-align'] = `${this.data[type + '_' + num].ALIGN}`;
          textInput.setAttribute('data-column', dataColumn);
          textInput.setAttribute('hkey', hKey);
          item.appendChild(textInput);
        });
        //값 밑에 숫자 표시
        if (KEY_SHOW === true) {
          const thHtml = th.outerHTML;
          th.innerHTML = '';
          const thKey = H[h].K;

          const headValueParent = document.createElement('div');
          headValueParent.classList.add('head-value');
          headValueParent.style.display = 'table';
          headValueParent.style.width = '100%';
          th.appendChild(headValueParent);

          const headValueChild = document.createElement('div');
          headValueChild.classList.add('head-value');
          headValueChild.style.display = 'table-cell';
          headValueChild.style.verticalAlign = 'middle';
          headValueChild.innerHTML = thHtml;
          headValueParent.appendChild(headValueChild);

          const headNum = document.createElement('div');
          headNum.innerHTML = thKey;
          th.appendChild(headNum);
          const headValueHeight = headValueParent.offsetHeight;

          if (headValueMaxHeight < headValueHeight) {
            headValueMaxHeight = headValueHeight;
          }
          table.querySelectorAll('head-value').forEach((item) => (item.style.height = headValueMaxHeight));
        }
      }
    }

    const hWidth = (100 - Number(vWidth)) / hCount;
    tr.querySelectorAll('th[hKey]').forEach((item) => (item.style.width = `${hWidth}%`));
    //랜덤
    this.answers = this.$common.rnd(V, RANDOM, 'K');

    const tbody = document.createElement('tbody');
    table.appendChild(tbody);
    let showCount = 0;
    let prevTrAnswered = false;
    let td;

    for (let v = 0; v < this.answers.length; v++) {
      const answer = this.answers[v];
      const vKey = answer.K;
      const category = answer.C1 || '';
      showCount++;

      tr = document.createElement('tr');
      tbody.appendChild(tr);
      tr.setAttribute('qname', NAME);
      tr.setAttribute('vKey', vKey);
      tr.setAttribute('category', category);

      //보기 분류가 있는 경우
      if (category != '') {
        //previousElementSibling 함수는 IE9 부터 됨
        const prevCategory = tr.previousElementSibling?.getAttribute('category');
        if (prevCategory == category && category != '[MERGE]') {
          //분류가 같으면 병합해야 한다
          let targetRow = tr.previousElementSibling;
          while (targetRow.querySelectorAll('td.row-head').length === 0) {
            targetRow = targetRow.previousElementSibling;
            if (targetRow.length == 0) break;
          }

          let rowspan = Number(targetRow.querySelector('td.row-head').getAttribute('rowspan') || '1');
          targetRow.querySelector('td.row-head').setAttribute('rowspan', String(++rowspan));
        } else {
          //분류가 같지 않으면 새로 만듦
          td = document.createElement('td');
          td.classList.add('row-head', 'text-center');
          td.innerHTML = category;
          tr.appendChild(td);
          if (MOBILE_UI === true) td.classList.add('mobile-ui-hide');
        }
      }

      //카테고리에 [MERGE]가 입력되면 V와 병합
      if (category === '[MERGE]') {
        td.setAttribute('colspan', '2');
      } else {
        td = document.createElement('td');
        tr.appendChild(td);
      }

      if (BY_CARD === true) {
        td.style.display = 'none';
        td.setAttribute('data-V', answer.V);
        tr.querySelectorAll('.row-head').forEach((item) => (item.style.display = 'none'));
        if (showCount == 1) {
          const cardSpan = cardDiv.querySelector('span');
          cardSpan.innerHTML = answer.V;
          cardSpan.removeAttribute('show-tooltip');
        }
        //툴팁은 생략
        /*
                (data.TOOLTIP || []).forEach(function (tt) {
                    if (tt.K == data.V[v].K) {
                        $(cardDiv).find('span').attr('show-tooltip', Replacer(tt.V));
                    }
                });
                */
      }

      //기타 박스 처리
      let html = answer.V;
      const reg = /\[TEXT_[\d]+\]/;
      let ord = 1;
      while (html.match(reg)) {
        const matchText = html.match(reg)![0];
        const typeNum = matchText.substr(1, matchText.length - 2).split('_');
        const type = typeNum[0];
        const num = typeNum[1];
        html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
        ord++;
      }
      td.innerHTML = html;

      //툴팁 있으면 표시
      /*
            (data.TOOLTIP || []).forEach(function (tt) {
                if (tt.K == data.V[v].K) {
                    $(td).attr('show-tooltip', tt.V);
                }
            });
            */

      if (MOBILE_UI === true) {
        td.classList.add('mobile-ui-block', 'mobile-ui-head');
        td.style.borderColor = 'black !important';
      }

      //기타 값 생성
      td.querySelectorAll('span[type][num]').forEach((item) => {
        const type = item.getAttribute('type');
        const num = item.getAttribute('num');
        const ord = item.getAttribute('ord');
        const dataColumn = vKey + '_ETC_' + ord;
        const textInput = document.createElement('input');
        textInput.setAttribute('type', 'text');
        textInput.classList.add('input-etc');
        textInput.style['width'] = `${this.data[type + '_' + num].WIDTH}px`;
        textInput.style['text-align'] = `${this.data[type + '_' + num].ALIGN}`;

        textInput.setAttribute('data-column', dataColumn);
        //textInput.setAttribute('disabled', 'disabled');
        textInput.setAttribute('qname', NAME);
        textInput.setAttribute('vkey', vKey);
        textInput.setAttribute('parent-name', NAME);
        textInput.setAttribute('type-name', type + '_' + num);
        textInput.setAttribute('data-object', JSON.stringify(this.data[type + '_' + num]));
        item.appendChild(textInput);
      });

      for (let h = 0; h < H.length; h++) {
        const hKey = H[h].K;
        td = document.createElement('td');
        td.classList.add('answer-unit-box', 'text-center', 'td-radio');

        tr.appendChild(td);
        if (MOBILE_UI === true) td.classList.add('mobile-ui-block');

        const label = document.createElement('label');
        label.setAttribute('for', `${NAME}_${vKey}_${hKey}`);
        label.classList.add('pure-radio');
        td.appendChild(label);

        const input = document.createElement('input');
        input.setAttribute('type', `radio`);
        input.setAttribute('id', `${NAME}_${vKey}_${hKey}`);
        input.setAttribute('name', `${NAME}_${vKey}`);
        input.setAttribute('qname', NAME);
        input.setAttribute('vkey', vKey);
        input.setAttribute('hkey', hKey);
        input.setAttribute('qtype', QUESTION.QUESTION_TYPES.RADIOSETS);
        input.setAttribute('data-column', vKey);
        input.value = hKey;
        label.appendChild(input);

        if (MOBILE_UI === true) {
          const sp = document.createElement('span');
          sp.classList.add('mobile-ui-show');
          sp.innerHTML = H[h].V;
          const empty = sp.querySelectorAll('*:empty');
          empty.forEach((item) => item.remove());
          label.appendChild(sp);
          const spanFindElements: NodeListOf<HTMLElement> = sp.querySelectorAll('div, p');
          spanFindElements.forEach((item) => item.replaceWith(item.outerHTML));
          td.classList.add('mobile-ui-td');
          label.classList.add('mobile-ui-flex');
          input.classList.add('mobile-ui-width-0');
          sp.classList.add('mobile-ui-width-1');
        }

        //한줄씩 표시
        if (BY_LINE === true && showCount > 1) {
          tr.style.display = 'none';
          tr.classList.add('invisible');
        }
      }

      //카드 형태가 아니고 기존 응답이 1개라도 있으면 모든 줄을 다 보여주자
      /*
            if (card == false && prevTrAnswered == true) {
                $('tr.invisible').show().removeClass('invisible');
            }
             */

      this.visibleProcess(answerBox);
    }
  }

  async initEvent(): Promise<void> {
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    //td 부분을 클릭해도 radio가 클릭되도록 함
    const tds: NodeListOf<HTMLElement> = answerBox.querySelectorAll('td.td-radio') || [];
    for (const td of tds) {
      td.addEventListener('click', this.radioClick);
    }
    //값 변경시
    const radios: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="radio"]') || [];
    for (const radio of radios) {
      radio.addEventListener('click', this.radioChange);
    }

    const texts: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="text"][data-column]') || [];
    for (const text of texts) {
      text.addEventListener('keyup', this.etcKeyup);
    }
  }

  etcKeyup(evt): void {
    const { target } = evt;
    const wrapper = target.closest('div[question-name]');
    this.visibleProcess(wrapper);
  }

  visibleProcess(wrapper: HTMLElement): void {
    if (wrapper.querySelectorAll('input[data-pass]:checked').length > 0) {
      wrapper.querySelectorAll('tr[vKey]:not(.disabled) input[type=radio][data-column]').forEach((item) => {
        item.removeAttribute('checked');
        item.setAttribute('disabled', 'disabled');
      });
      wrapper.querySelectorAll('input[type=text]').forEach((item) => {
        item.setAttribute('disabled', 'disabled');
      });
      //무응답 체크시 자동으로 특정 키를 선택해야 하는 경우
      const passAutoKey = wrapper.querySelector('input[data-pass]:checked')?.getAttribute('data-pass-key') || '';
      if (passAutoKey != '') {
        wrapper
          .querySelectorAll('tr[vKey]:not(.disabled) input[type=radio][data-column][hkey="' + passAutoKey + '"]')
          .forEach((item) => {
            item.setAttribute('checked', 'checked');
          });
      }
    } else {
      wrapper.querySelectorAll('tr[vKey]:not(.disabled) input[type=radio][data-column]').forEach((item) => {
        item.removeAttribute('disabled');
      });
      wrapper.querySelectorAll('input[type=text]').forEach((item) => {
        item.removeAttribute('disabled');
      });
    }

    //기타에 응답하지 않은 칸은 선택하지 못하게 한다

    wrapper.querySelectorAll('input[type="text"]').forEach((item) => {
      const val = (<HTMLInputElement>item).value || '';
      const vkey = item.getAttribute('vkey') || '';
      const hkey = item.getAttribute('hkey') || '';
      let radios;
      if (vkey != '') {
        radios = wrapper.querySelectorAll('input[type="radio"][hkey][vkey="' + vkey + '"]');
        if (val == '') {
          radios.forEach((radio) => {
            radio.style.visibility = 'hidden';
            radio.classList.add('invisible');
            radio.setAttribute('disabled', 'disabled');
            radio.removeAttribute('checked');
          });
        } else {
          radios.forEach((radio) => {
            radio.style.visibility = 'visible';
            radio.classList.remove('invisible');
            radio.removeAttribute('disabled');
          });
        }
      } else if (hkey != '') {
        radios = wrapper.querySelectorAll('input[type="radio"][hkey="' + hkey + '"][vkey]');
        if (val == '') {
          radios.forEach((radio) => {
            radio.style.visibility = 'hidden';
            radio.classList.add('invisible');
            radio.setAttribute('disabled', 'disabled');
            radio.removeAttribute('checked');
          });
        } else {
          radios.forEach((radio) => {
            radio.style.visibility = 'visible';
            radio.classList.remove('invisible');
            radio.removeAttribute('disabled');
          });
        }
      }
    });
  }

  //값 변경시
  radioChange(evt): void {
    const { target } = evt;
    const { NAME } = this.data;
    const answerBox = this.$refs['s-answer'];
    const wrapper = target.closest('div[question-name]');
    const qname = target.getAttribute('qname');
    const vKey = target.getAttribute('vkey');
    const tr = target.closest('tr');
    const nextTr: HTMLElement | null = answerBox.querySelector('tr[vkey="' + (Number(vKey) + 1) + '"]');
    const cardDiv = answerBox.querySelector('div.card-div[qname="' + NAME + '"]');
    if (cardDiv) {
      if (nextTr) {
        tr.style.display = 'none';
        let span = cardDiv.querySelector('span');
        span?.remove();
        span = document.createElement('span');
        span.innerHTML = nextTr.querySelector('td[data-V]')?.getAttribute('data-V') || '';
        cardDiv.appendChild(span);
        nextTr.style.display = '';
        nextTr.classList.remove('invisible');
      }
    } else {
      //카드 형태가 아닌 경우
      //다음 줄 안보이면 보이게
      if (nextTr != null && nextTr.className.indexOf('invisible') > -1) {
        nextTr.style.display = '';
        nextTr.classList.remove('invisible');
      }
    }
    //선택된 radio의 부모에 선택되었음을 표시
    wrapper
      .querySelector('input[type=radio]:not(:checked)')
      .closest('.answer-unit-box')
      .classList.remove('answer-unit-selected');
    wrapper
      .querySelector('input[type=radio]:checked')
      .closest('.answer-unit-box')
      .classList.remove('answer-unit-selected');
  }

  radioClick(evt): void {
    const { target } = evt;
    const radio = target.querySelector('input[type="radio"]');
    if (radio) {
      radio.click();
    }
  }
}
