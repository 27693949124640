
import {Component, Vue} from "vue-property-decorator";
import CardUi from "@/components/ui/card/CardUi.vue";
import CheckCircle from "@/components/icons/common/CheckCircle.vue";

@Component({
  components: {
    CardUi,
    CheckCircle
  }
})
export default class SignUpSuccess extends Vue {
  private redirectHome() {
    this.$router.replace('/login?success=true');
  }
}
