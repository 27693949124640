
import  {Component, Prop } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import type { IAnswerValue } from '@/interface/survey/answer';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionNumInRow from '@/components/project/make/options/common/OptionNumInRow.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import { QUESTION } from '@/types/question';
import draggable from 'vuedraggable';
import OptionLeftCategory from '@/components/project/make/options/common/OptionLeftCategory.vue';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";
import { Properties, UploadPayload } from "@/interface/make/properties";
import { mixins } from "vue-class-component";
import { MakeMixins, MakeSurveyTemplate } from "@/mixins/project/MakeMixins";

@Component({
  components: {
    QuestionImageUploader,
    draggable,
    OptionDescription,
    OptionSkipNotSkip,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionNumInRow,
    OptionRandom,
    OptionLeftCategory,
    OptionQuestion,
  },
})
export default class OptionRadio extends mixins(MakeMixins) implements MakeSurveyTemplate {
  @Prop({required: true}) readonly data: Required<IQuestionValue>;
  @Prop({ default: [] }) readonly list: IQuestionValue[];

  valueMaxError = false;
  etcFlag = false;
  notFlag = false;
  mouseEvent = 0;

  category: {
    C1: boolean;
    C2: boolean;
    C3: boolean;
  } = {
    C1: false,
    C2: true,
    C3: true
  }

  created() {
    this.findEtc();
  }

  mounted() {
    this.categoryValidation();
  }

  categoryLimitContent(str: string): string {
    this.categoryValidation()
    return str.substring(0, 49);
  }

  categoryValidation(): void {
    const c1Arr: string[] = [];
    const c2Arr: string[] = [];
    this.data.ANSWERS.forEach((answer) => {
      const {C1, C2} = answer;
      if (C1) c1Arr.push(C1)
      if (C2) c2Arr.push(C2);
      this.category.C2 = this.data.ANSWERS.length !== c1Arr.length;
      this.category.C3 = this.data.ANSWERS.length !== c2Arr.length;
    });
    if (this.category.C2) this.data.ANSWERS.forEach((v, idx) => {
      this.data.ANSWERS[idx].C2 = '';
      this.data.ANSWERS[idx].C3 = '';
      this.category.C3 = true;
    });
    if (this.category.C3) this.data.ANSWERS.forEach((v, idx) => {
      this.data.ANSWERS[idx].C3 = '';
    });
  }

  findEtc() {
    const etcIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.ETC_KEY) || -1;
    const notIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
    if (etcIndex > -1) this.etcFlag = true;
    if (notIndex > -1) this.notFlag = true;

    //편집할때 [TEXT_1] 제거
    if (this.data.ANSWERS && this.data.ANSWERS.length > 0) {
      for (let i = 0; i < this.data.ANSWERS.length; i++) {
        const { V, K } = this.data.ANSWERS[i];
        const removeValue = `[TEXT_${K}]`;
        if (V.indexOf(removeValue) > -1) {
          this.data.ANSWERS[i].V = V.replace(` ${removeValue}`, '');
        }
      }
    }
  }

  async imageAppend(data: UploadPayload<string, File>): Promise<void> {
    const {dataUrl, file, index} = data as {dataUrl: string, file: File, index: number};
    const sizeCheck = this.fileSizeCheck(file)
    if (sizeCheck) return;
    await this.singleQuestionImageUpload(data);
    if (this.data.ANSWERS?.length) {
      this.plainText = this.data.ANSWERS[index].V;
      this.data.ANSWERS[index].CREATE = false;
      this.data.ANSWERS[index].V = this.$common.imageTagGenerator(dataUrl) + this.plainText;
      this.data.ANSWERS[index].FILE = {origin: file, dataUrl: dataUrl }
    }
  }

  removeFile(index: number): void {
    if (this.data.ANSWERS) {
      this.plainText = this.data.ANSWERS[index].V;
      delete this.data.ANSWERS[index].FILE;
      this.data.ANSWERS[index].IMG_LOCATION = '';
      this.data.ANSWERS[index].V = this.$common.imageTagGenerator('') + this.plainText;
    }
    this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
  }

  resize(e: Event) {
    const target = e.target as HTMLInputElement;
    target.style.height = '1px';
    target.style.height = `${target.scrollHeight + 1}px`;
  }

  // 처음 생성됐을 때 focus 될 경우 초기값 지우기
  removeContent(aItem: IAnswerValue): void {
    if (aItem?.CREATE) {
      aItem.V = '';
      aItem.CREATE = false;
    }
  }

  getQnumParentQuestion(name: string): string {
    const parentQuestion = this.list.find(question => question.NAME === name);
    return parentQuestion?.QNUM || '';
  }


  set mouseHoverComputed(type: number) {
    this.mouseEvent = type;
  }
  get mouseHoverComputed() {
    return this.mouseEvent;
  }

  mouseHover(type: number) {
    this.mouseEvent = type;
  }

  leftCategoryData(flag: boolean): void {
    this.data.LEFT_CATEGORY = flag;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  numInRowData(data: string): void {
    this.data.NUM_IN_ROW = data;
  }

  randomData(data: string): void {
    this.data.RANDOM = data;
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  /**
   * 기타, 없음 있는지 여부 판단
   * @param key
   */
  etcNotCheck(key: string) {
    return key === QUESTION.ANSWERS.ETC_KEY || key === QUESTION.ANSWERS.NOT_KEY;
  }

  /**
   * 기타 보기 추가 값 변경될때 추가 및 삭제 하는 함수
   */
  async etcChange(): Promise<void> {
    if (this.etcFlag) {
      const etcAnswer = {
        C1: '',
        C2: '',
        C3: '',
        K: QUESTION.ANSWERS.ETC_KEY,
        V: QUESTION.ANSWERS.ETC_TEXT,
        N: '',
        SINGLE: false,
        DIVIDER: false,
      };
      const notIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
      // 없음 보기 앞에다가 넣자
      if (notIndex > -1) this.data.ANSWERS?.splice(notIndex, 0, etcAnswer);
      else this.data.ANSWERS?.push(etcAnswer);
    } else {
      const etcIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.ETC_KEY) || -1;
      this.data.ANSWERS?.splice(etcIndex, 1);
    }
  }

  /**
   * 없음 보기 추가 값 변경될때 추가 및 삭제 하는 함수
   */
  async notChange(): Promise<void> {
    if (this.notFlag) {
      const notAnswer = {
        C1: '',
        C2: '',
        C3: '',
        K: QUESTION.ANSWERS.NOT_KEY,
        V: QUESTION.ANSWERS.NOT_TEXT,
        N: '',
        SINGLE: true,
        DIVIDER: false,
      };
      this.data.ANSWERS?.push(notAnswer);
    } else {
      const notIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
      this.data.ANSWERS?.splice(notIndex, 1);
    }
  }

  /**
   * 보기 삭제 및 추가
   * @param index
   * @param add
   */
  answersControl(index: number, add: boolean): void {
    this.valueMaxError = false;
    if (!add) {
      // 보기 삭제
      this.$question.removeAnswer(this.data.ANSWERS, index);
    } else if (add) {
      if (this.data.ANSWERS && this.data.ANSWERS.length >= this.$data.setsValueMax) {
        this.valueMaxError = true;
        return;
      }
      //보기 추가
      this.$question.addAnswer(this.data.ANSWERS, index);
    }
  }

  existsParent(): boolean {
    return !(this.data.PARENT_NAME === '' || this.data.PARENT_NAME === undefined);
  }

  showCount() {
    const showCount = this.data.SHOW?.length || 0;
    return showCount > 0;
  }

  //보기마다 기타 입력 여부
  valueEtcChange(idx: number) {
    const { ANSWERS = [] } = this.data;
    const answer = ANSWERS[idx];
    answer.ETC = !answer.ETC;
  }

  /**
   * 설문 종료 변경 이벤트
   * @param index
   */
  outChange(index: number) {
    const { ANSWERS = [] } = this.data;
    const answer = ANSWERS[index];
    answer.N = answer.N === 'OUT' ? '' : 'OUT';
  }

  private showManual() {
    const url = require('@/assets/images/options/category.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>[분류]</b>는 각각의 보기를 하나의 분류로 묶어서 제시할 때 사용하는 기능입니다.</h1><br/>
                    <h2 class="option-description--text">추가 옵션의 <b>[분류 위치]</b>기능과 함께 분류의 위치를 자유롭게 수정할 수 있습니다.</h2>
                    <hr />
                    <img alt="필수응답" style='width: 80%; padding-left: 100px;' src="${url}" />
                </div>`,
    };
  }
}
