
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CreateNewProjectModal extends Vue {
  templateId: string = '';
  setPanelList: boolean = true;

  panelModalId = 'bv-modal-panel';
  modalId = 'bv-modal';

  resetModal(): void {
    this.setPanelList = true;
  }

  sendCreateProject(): void {
    const flag: string = this.setPanelList ? '1' : '2'; // 쿼리 추가하면서 type number -> string 으로 변경
    if (flag === '1') {
      this.$bvModal.show(this.panelModalId);
      this.$bvModal.hide(this.modalId);
    } else if (flag === '2') {
      this.makeSurvey(flag)
      this.$bvModal.hide(this.modalId);
    }
  }

  makeSurvey(flag: string): void {
    this.$router.push({ name: 'project-sampling', params: { id: flag } });
    this.closeModal();
  }

  closeModal(): void {
    this.$bvModal.hide(this.panelModalId);
    this.$bvModal.hide(this.modalId);
  }
}
