import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import { ins } from '@/utils/axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import globalMixin from '@/mixins/global.mixin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as VueMoment from 'vue-moment';
import { QuestionClass, CommonClass } from '@/utils/';
import vClickOutside from 'v-click-outside'
import vueCookies from "vue-cookies";
import * as Sentry from "@sentry/vue";
// 디자인 스타일 가이드 CSS
import '@/assets/css/index.css';
import '@/assets/css/index.scss';
import '@/assets/css/sass/bootstrapCustom.scss';
// router hook 추가
import '@/hooks/class-component-hook';

const Navigation = () => import('@/components/layout/Navigation.vue');
const Footer = () => import('@/components/layout/UnisurveyFooter.vue');
const Font = () => import('@/components/common/typography/Typography.vue');
const Button = () => import('@/components/common/Button.vue');

Vue.config.productionTip = false;
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(VueAxios, ins);
Vue.use(Vuex);
Vue.use(CommonClass);
Vue.use(QuestionClass);
Vue.use(VueMoment);
Vue.use(vueCookies);
Vue.use(vClickOutside);
Vue.component('navigation', Navigation);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Footer', Footer);
Vue.component('font', Font);
Vue.component('UniButton', Button);
library.add(fas);
library.add(far);

Vue.mixin(globalMixin);

Vue.$cookies.config("30d");
// @ts-ignore
window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);


Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_ID,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/unisurvey\.co.kr\/api/,  /^https:\/\/stage.unisurvey\.co.kr\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // beforeSend: (event: any, hint: any) => this._sendErrorMessage(event, hint)
});

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
