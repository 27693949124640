
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JoinVerifySuccess extends Vue {
  loading = true;

  async created() {
    await this.login();
  }

  async login() {
    const {data} = await this.axios.get(`/user/integrated-login-check/${this.token}`);
    if (data.result) {
      await this.$store.dispatch('login', { data });
      await this.$router.push('/project/list')
    }
  }

  get token() {
    return this.$route.params.token;
  }
}
