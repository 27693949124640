
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IQuestionTitle } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class InfoTitle extends Vue {
  @Prop() data: IQuestionTitle;
  @Prop() private questionTypeName?: string;

  mounted() {
    this.init();
  }

  init() {
    const title = document.querySelector('.s-title');
    if (title) {
      const rows: NodeListOf<HTMLElement> = title.querySelectorAll('p');
      rows.forEach((item) => (item.style.margin = '0px'));
    }
  }

  get getBackgroundColor(): string {
    return `background-color:${this.data.BG}`;
  }
}
