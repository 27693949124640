
import { Component, Vue } from 'vue-property-decorator';
import RequestAuthNumber from './RequestAuthNumber.vue';
import SetNewPw from '@/components/users/SetNewPw.vue';
import TemplateCard from '@/components/project/template/TemplateCard.vue';
import QuestionWriteModal from '@/components/board/QuestionWriteModal.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    TemplateCard,
    SetNewPw,
    RequestAuthNumber,
    QuestionWriteModal,
  },
})
export default class FindId extends Vue {
  userId: string = '';
  $refs: Vue['$refs'] & {
    findIdAuthNumber: RequestAuthNumber;
  };

  search() {
    const auth = this.$refs.findIdAuthNumber.authNumberCheck();
    if (!auth) {
      this.$common.makeToast(ToastMessage.FIND_ID, ToastVariant.WARNING, this.$bvToast);
      return;
    } else {
      this.userId = this.$refs.findIdAuthNumber.userId;
    }
  }

  reset() {
    this.userId = '';
    this.$refs.findIdAuthNumber.reset();
  }

  questionModalOpen() {
    this.$bvModal.show('question-write-modal');
  }
}
