
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ProgressBoardIcon extends Vue {
  @Prop({default: false}) active: boolean;

  fill() {
    return this.active ? '#0B5CD6': '#999999'
  }
}
