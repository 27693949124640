
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISurveyValue } from '@/interface/survey/question';
import { IKeyValueSetting, IQuotaInnerValue, IQuotaValue } from '@/interface/survey/quota';
import { QUOTA } from '@/types/quota';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {},
})
export default class CreateNewProjectTest extends Vue {
  @Prop() private items: Array<ISurveyValue>;
  @Prop() private viewFlag: string;
  settingAllocate: Array<IKeyValueSetting> = [];
  settingRespondentCount: Array<IKeyValueSetting> = [];
  settingArea: Array<IKeyValueSetting> = [];
  settingQuota: IQuotaInnerValue = {
    GENDER: [],
    AGE_UNIT: [],
    AGE_5: [],
    AGE_10: [],
  };

  settingQuotaData: IQuotaValue = {
    RESPONSE_CNT: 0,
    GENDER: '',
    AGE_UNIT: '',
    AGE_10: [],
    AGE_5: [],
    QUOTA_SET: '',
    QUOTA_CNT: QUOTA.quotaCntInit(),
    AREA: [],
  };
  showing: boolean = false;
  responseCount: number = 0;
  currResClick: number = 0;
  currGenderClick: number = -1;
  currAgeUnitClick: number = -1;
  currAllocateClick: number = -1;
  arrayGender: Array<number> = [];
  arrayAge_5: Array<number> = [];
  arrayAge_10: Array<number> = [];
  arrayArea: Array<number> = [];
  setFlag: string = '1';
  sumAgeArray: Array<number> = [];
  sumGenderArray: Array<number> = [];
  loadingTable: boolean = false;
  ageToggle1: boolean = false;
  ageToggle2: boolean = false;
  allocateToggle: boolean = false;
  totalCount: number = 0;

  constructor() {
    super();
  }

  created(): void {
    if (this.viewFlag) this.setFlag = this.viewFlag;
    this.load();
  }

  mounted(): void {}

  async load(): Promise<void> {
    try {
      this.showing = true;
      const { data } = await this.axios({
        url: '/project/quota-inter',
        method: 'GET',
      });
      const { ALLOCATE, AREA, QUOTA, RESPONDENT_COUNT } = data;
      this.settingAllocate = ALLOCATE;
      this.settingRespondentCount = RESPONDENT_COUNT;
      this.settingRespondentCount.push({ key: 9999, value: '직접 입력' });
      this.settingArea = AREA;
      this.settingQuota = QUOTA;
    } catch (e) {
      console.error(e);
    } finally {
      this.showing = false;
    }
  }

  async getQuotaPart(): Promise<void> {
    this.loadingTable = true;
    const sendData = {
      RESPONSE_CNT: Number(this.responseCount),
      GENDER: this.arrayGender.toString(),
      AGE_UNIT: this.currAgeUnitClick.toString(),
      AGE_10: this.arrayAge_10.map((x) => x.toString()),
      AGE_5: this.arrayAge_5.map((x) => x.toString()),
    };
    try {
      const { data } = await this.axios({
        url: '/project/quota/part',
        method: 'POST',
        data: sendData,
      });
      const { result } = data;
      if (result) {
        const { SUM_AGE, SUM_GENDER, cnt, total } = result;
        if (this.settingQuotaData.QUOTA_CNT) this.settingQuotaData.QUOTA_CNT = cnt;
        this.sumAgeArray = SUM_AGE;
        this.sumGenderArray = SUM_GENDER;
        this.totalCount = total;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingTable = false;
    }
  }

  bindNumber(event): void {
    !isNaN(event) ? (this.responseCount = +event) : (this.responseCount += event);
  }

  responseClick(key: number, value: string | number): void {
    this.currResClick = key;
    let flag = true;
    if (key !== 9999) this.responseCount += +value;

    if (!this.checkResponseCount) flag = false;
    if (!this.checkGenderCount) flag = false;

    if (this.currAgeUnitClick < 1) {
      flag = false;
    } else if (this.currAgeUnitClick !== 0) {
      if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
        flag = false;
      }
    }

    if (flag) this.setAllocateState(this.currAllocateClick);
  }

  ageUnitClick(key: number): void {
    this.currAgeUnitClick = key;
    if (key === 1) {
      this.ageToggle1 = true;
      this.ageToggle2 = false;
      this.arrayAge_5 = [];
    } else if (key === 2) {
      this.ageToggle1 = false;
      this.ageToggle2 = true;
      this.arrayAge_10 = [];
    } else {
      this.arrayAge_5 = [];
      this.arrayAge_10 = [];
      this.currAllocateClick = 0;
      this.ageToggle1 = false;
      this.ageToggle2 = false;
    }
  }

  async setGenderArray(key: number): Promise<void> {
    this.currGenderClick = key;
    this.settingQuota.GENDER.find((ele) => {
      if (key === ele.key) {
        if (!this.arrayGender.includes(key)) {
          this.arrayGender.push(key);
        } else {
          this.arrayGender.splice(this.arrayGender.indexOf(key), 1);
        }
      }
    });
    if (this.arrayGender.length >= 2) {
      if (this.arrayGender.includes(0)) {
        // 전국 포함
        this.arrayGender = [0];
      } else if (this.arrayGender.includes(1) && this.arrayGender.includes(2)) {
        this.arrayGender = [0]; // 전국 제외 모두 선택
      }
    }
    if (this.currAllocateClick > 0) {
      await this.setAllocateState(this.currAllocateClick);
    }
  }

  async setAgeFiveArray(key: number): Promise<void> {
    this.settingQuota.AGE_5.find((ele) => {
      if (key === ele.key) {
        if (!this.arrayAge_5.includes(key)) {
          this.arrayAge_5.push(key);
        } else {
          this.arrayAge_5.splice(this.arrayAge_5.indexOf(key), 1);
        }
      }
    });

    if (this.currAllocateClick > 0) await this.setAllocateState(this.currAllocateClick);
  }

  async setAgeTenArray(key: number): Promise<void> {
    this.settingQuota.AGE_10.find((ele) => {
      if (key === ele.key) {
        if (!this.arrayAge_10.includes(key)) {
          this.arrayAge_10.push(key);
        } else {
          this.arrayAge_10.splice(this.arrayAge_10.indexOf(key), 1);
        }
      }
    });
    if (this.currAllocateClick > 0) await this.setAllocateState(this.currAllocateClick);
  }

  setAreaArray(key: number): void {
    this.settingArea.find((ele) => {
      if (key === ele.key) {
        if (!this.arrayArea.includes(key)) {
          this.arrayArea.push(key);
        } else {
          this.arrayArea.splice(this.arrayArea.indexOf(key), 1);
        }
      }
    });
    if (this.arrayArea.length >= 2) {
      if (this.arrayArea.includes(4)) {
        // 전국 포함
        this.arrayArea = [4];
      } else if (this.arrayArea.includes(1) && this.arrayArea.includes(2) && this.arrayArea.includes(3)) {
        this.arrayArea = [4]; // 전국 제외 모두 선택
      }
    }
  }

  translateArr(array: Array<IKeyValueSetting>, key: number): string {
    let res = '';
    array.filter((item) => {
      if (key === item.key) {
        if (item.value) res = item.value.toString();
      }
    });
    return res;
  }

  sendNextView() {
    if (!this.checkResponseCount) {
      this.$common.makeToast(ToastMessage.SAMPLING_RESPONSE, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.setFlag === '1') {
      if (!this.checkGenderCount) {
        this.$common.makeToast(ToastMessage.SAMPLING_GENDER, ToastVariant.DANGER, this.$bvToast);
        return false;
      }

      if (this.currAgeUnitClick !== 0) {
        if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
          this.$common.makeToast(ToastMessage.SAMPLING_AGE, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
      }

      if (this.currAgeUnitClick !== 0) {
        if (!this.checkQuotaCount()) {
          this.$common.makeToast(ToastMessage.SAMPLING_QUOTA_DETAIL, ToastVariant.DANGER, this.$bvToast);
          return false;
        }
      }

      if (this.currAgeUnitClick !== 0 && this.currAllocateClick === 0) {
        this.$common.makeToast(ToastMessage.SAMPLING_QUOTA_TOTAL, ToastVariant.DANGER, this.$bvToast);
        return false;
      }
    }

    if (this.settingQuotaData) {
      this.settingQuotaData.RESPONSE_CNT = Number(this.responseCount);
      if (this.setFlag === '1') {
        this.settingQuotaData.GENDER = this.arrayGender.toString();
        this.settingQuotaData.AGE_UNIT = this.currAgeUnitClick.toString();
        this.settingQuotaData.AGE_10 = this.arrayAge_10.map((x) => x.toString());
        this.settingQuotaData.AGE_5 = this.arrayAge_5.map((x) => x.toString());
        this.settingQuotaData.QUOTA_SET = this.currAgeUnitClick.toString();
        this.settingQuotaData.AREA = this.arrayArea.map((x) => x.toString());
      }
    }

    this.$emit('next-view', this.settingQuotaData);
  }

  get checkResponseCount(): boolean {
    return this.responseCount !== 0;
  }

  get checkGenderCount(): boolean {
    return this.arrayGender.length !== 0;
  }

  checkQuotaCount(): boolean {
    // console.log(this.getQuotaCount)
    // console.log(this.responseCount)
    return this.getQuotaCount === Number(this.responseCount);
  }

  async setAllocateState(key: number): Promise<Boolean> {
    if (!this.checkResponseCount) {
      this.$common.makeToast(ToastMessage.SAMPLING_RESPONSE, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (!this.checkGenderCount) {
      this.$common.makeToast(ToastMessage.SAMPLING_GENDER, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.currAgeUnitClick < 1) {
      this.$common.makeToast(ToastMessage.SAMPLING_AGE_TYPE, ToastVariant.DANGER, this.$bvToast);
      return false;
    } else if (this.currAgeUnitClick !== 0) {
      if (this.arrayAge_10.length === 0 && this.arrayAge_5.length === 0) {
        this.$common.makeToast(ToastMessage.SAMPLING_AGE_RANGE, ToastVariant.DANGER, this.$bvToast);
        return false;
      }
    }

    this.currAllocateClick = key;
    if (key !== 0) {
      await this.getQuotaPart();
      this.allocateToggle = true;
    } else {
      this.allocateToggle = false;
    }
    return true;
  }

  get returnArray(): Array<number> {
    let currKey = this.currAgeUnitClick;
    let result: Array<number> = [];
    if (currKey === 1) result = this.arrayAge_10.sort();
    else if (currKey === 2) result = this.arrayAge_5.sort();
    return result;
  }

  get sortingGenderArray(): Array<number> {
    let arr: Array<number>;
    if (this.arrayGender.includes(0)) {
      // this.currAllocateClick = 0
      arr = [1, 2];
    } else {
      arr = this.arrayGender.sort();
    }
    console.log(arr);
    return arr;
  }

  get getQuotaCount(): number {
    let resultSum = 0;
    if (this.settingQuotaData?.QUOTA_CNT) {
      resultSum = Object.values(this.settingQuotaData?.QUOTA_CNT).reduce((p, c) => p + parseInt(c), 0);
    }
    return resultSum;
  }

  quotaReset(index: number): void {
    if (index === 0) {
      this.responseCount = 0;
      this.currResClick = -1;
    } else if (index === 1) {
      this.currAgeUnitClick = -1;
      this.arrayAge_5 = [];
      this.arrayAge_10 = [];
      this.ageToggle1 = false;
      this.ageToggle2 = false;
      this.arrayGender = [];
      this.quotaReset(2);
    } else if (index === 2) {
      this.currAgeUnitClick = -1;
      this.currAllocateClick = -1;
      this.sumAgeArray = [];
      this.sumGenderArray = [];
      this.allocateToggle = false;
    } else if (index === 3) {
      this.arrayArea = [];
    }
  }

  get allocateArrayLength(): number {
    return this.returnArray.length + 2;
  }

  allocateEdit(index1: number, index2: number): void {
    // colSum
    let colSum: number = 0;
    this.sumGenderArray.forEach((v, i) => {
      colSum += Number(this.settingQuotaData.QUOTA_CNT[`${i + 1}//${index2 + 2}/`]);
    });
    this.sumAgeArray.splice(index2, 1, colSum);
    const reducer = (previousValue, currentValue) => previousValue + currentValue;

    // rowSum
    let rowSum: number = 0;
    this.sumAgeArray.forEach((v, i) => {
      rowSum += Number(this.settingQuotaData.QUOTA_CNT[`${index1 + 1}//${i + 2}/`]);
    });
    this.sumGenderArray.splice(index1, 1, rowSum);

    const changeData = this.sumAgeArray.reduce(reducer);
    this.responseCount = changeData;

    if (changeData > this.totalCount) {
      this.$common.makeToast(ToastMessage.SAMPLING_RESPONSE_NOT_MATCH, ToastVariant.DANGER, this.$bvToast);
      this.responseCount = this.totalCount;
      this.setAllocateState(2);
      return;
    }
  }
}
