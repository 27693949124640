
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionVWidth extends Vue {
  @Prop({ required: true, default: '40' }) vWidth: string;
  radioValue: string = '';
  label: string = '행 보기 너비';
  options: IArrayOption[] = [
    { text: '10', value: '10' },
    { text: '20', value: '20' },
    { text: '30', value: '30' },
    { text: '40', value: '40' },
    { text: '50', value: '50' },
  ];

  created() {
    this.radioValue = this.vWidth;
  }

  buttonActive(value: string) {
    switch (value) {
      case '10':
        return 0;
      case '20':
        return 1;
      case '30':
        return 2;
      case '40':
        return 3;
      case '50':
        return 4;
    }
  }

  changeButton(value: string) {
    this.radioValue = value;
    this.$emit('set-data', this.radioValue);
  }

  private showManual() {
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'top',
      content: `<div>
                    <h1 class="option-description--text"><b>행 내용의 너비를 설정합니다.</b></h1>
                    <small style="color: #35B6E7FF; font-weight: 500;">( 기본 설정 값은 40 입니다. )</small>
                </div>`,
    };
  }
}
