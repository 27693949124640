import EventBus from '@/utils/EventBus';
import {QUESTION} from '@/types/question';
import app from '@/main';
import type {IQuestionCategoryBoxInfo, IQuestionValue} from '@/interface/survey/question';
import {IAnswerValue} from '@/interface/survey/answer';
import _ from 'lodash';
import {ToastMessage, ToastVariant} from '@/utils/ToastEnum';
import QUESTION_TYPES = QUESTION.QUESTION_TYPES;
import LINK_STATUS = QUESTION.LINK_STATUS;

class QuestionClass {
  private _id = -1; // SNUM
  private questionList: IQuestionValue[] = []; // 설문지 리스트
  private goLink: QUESTION.LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT;
  private linkStatus: QUESTION.LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT;
  private surveyType = 0;
  private previewUrl = '';
  /**
   * 초기화
   * @param id
   */
  async initQuestion(id: number): Promise<void> {
    this.setId = id;
    await this.uniQuestionDataCheck();
    await this.getMetaQuestion();
  }

  async getProjectConfig() {
    try {
      const { data } = await app.axios.get(`/project/config/${this._id}`);
      const { CONFIG } = data;
      const { LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT, SIMPLE_SURVEY_TYPE } = CONFIG;
      this.surveyType = SIMPLE_SURVEY_TYPE;
      this.linkStatus = LINK_STATUS;
      this.goLink = LINK_STATUS === undefined ? QUESTION.LINK_STATUS.DEFAULT : LINK_STATUS;
    } catch (e) {
      console.log(e);
    }
  }

  isPayment(): boolean {
    return this.linkStatus >= LINK_STATUS.FW_START;
  }

  async getMetaQuestion(qnum?: string): Promise<void> {
    try {
      const { data } = await app.axios.get(`/project/make/${this._id}`);
      const { DATA, testUrl } = data;
      await this.getProjectConfig();
      this.questionList = DATA.map((m) => {
        const { NAME } = m;
        const EDIT = NAME === qnum;
        return {
          ...m,
          EDIT,
        };
      });
      this.previewUrl = testUrl;
    } catch (e) {
      console.log(e);
    }
  }

  //PROJECT DATA 가 있는지 없는지 확인 후 없으면 추가
  async uniQuestionDataCheck() {
    try {
      await app.axios.get(`/project/make/uni/data/${this._id}`);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 설문 문항 리스트 다시 조회
   */
  async reLoadQuestion(qnum?: string): Promise<void> {
    try {
      await this.getQuestionList(qnum);
      EventBus.$emit(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD, qnum);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 설문 리스트 불러오기
   */
  async getQuestionList(qnum?: string): Promise<void> {
    try {
      const { data } = await app.axios.get(`/project/make/unisurvey/${this._id}`);
      const { DATA } = data;
      this.questionList = DATA.map((m) => {
        const { NAME } = m;
        const EDIT = NAME === qnum;
        return {
          ...m,
          EDIT,
        };
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 편집이 가능한 경우
   * linkStatus가 1 보다 작을 경우 (링크 확정 전)
   * 설문이 반려당했을 경우 linkStatus 3 (설문을 다시 수정해야함)
   */
  get makeUpdateStatus(): boolean {
    return (
      this.linkStatus <= LINK_STATUS.REJECT &&
      this.linkStatus !== LINK_STATUS.REAL &&
      this.linkStatus !== LINK_STATUS.PAID || this.linkStatus === LINK_STATUS.REFUND
    );
  }

  get sampleProject(): boolean {
    return this.linkStatus === LINK_STATUS.SAMPLE
  }

  get getGoLink(): QUESTION.LINK_STATUS {
    return this.goLink;
  }

  get getSurveyType(): number {
    return this.surveyType;
  }

  get getTestUrl(): string {
    return this.previewUrl;
  }

  /**
   * 수정 가능한 질문리스트
   */
  getMakeList(): IQuestionValue[] {
    return this.questionList.filter(
      (item) => QUESTION.HIDE_QUESTIONS.indexOf(item.NAME) === -1 || QUESTION.FIXED_QUESTIONS.indexOf(item.NAME) > -1
    );
  }

  set setId(index: number) {
    this._id = index;
  }

  get getId(): number {
    return this._id;
  }
  /**
   * 질문타입 아이콘
   * */
  questionTypeIcon(Q: IQuestionValue): string[] {
    const { TYPE, NAME } = Q;
    const fixedIcons: string[] = require('@/assets/images/icons/make/lock.svg');
    if (QUESTION.FIXED_QUESTIONS.indexOf(NAME) > -1) {
      return fixedIcons;
    } else {
      const questionType = QUESTION.QUESTION_TYPES_LIST.find((item) => item.value === TYPE);
      const { icons } = questionType || { icons: [] };
      return icons.length < 1 ? fixedIcons : icons;
    }
  }

  /**
   * 문항 순서 변경
   * @param name
   * @param cursor
   */
  async move(name: string, cursor: string): Promise<boolean> {
    try {
      const sendData = {
        _id: this._id,
        name,
        cursor,
      };
      const { data } = await app.axios.put(`/project/make/move/question`, sendData);
      const { result } = data;
      if (result) {
        app.$common.makeToast(ToastMessage.MOVE, ToastVariant.SUCCESS, app.$bvToast);
        await this.reLoadQuestion(name);
      }
      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  /**
   * 문항 삭제
   */
  async remove(QNUM: string, NAME: string, noToast?: boolean): Promise<void | boolean> {
    try {
      const { data } = await app.axios.delete(`/project/make/unisurvey/${this._id}/${NAME}`);
      const { result } = data;
      if (result) {
        if (!noToast) app.$common.makeToast(`[${QNUM}] ${ToastMessage.QUESTION_DELETE}`, ToastVariant.SUCCESS, app.$bvToast);
        await this.reLoadQuestion();
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }
  /* make 문항 추가 - NEW */
  async addTest(
    qnum: string,
    type: QUESTION.QUESTION_TYPES,
    childInfo?: { target: string; showHide: QUESTION.CHILD_TYPE }
  ): Promise<any> {
    try {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_START);

      const question: IQuestionValue = QUESTION.defaultQuestionData(type);
      question.TYPE = type;
      if (type === QUESTION_TYPES.TITLE) question.QUESTION = QUESTION_TYPES.TITLE;
      else if (type === QUESTION_TYPES.DESC) question.QUESTION = QUESTION_TYPES.DESC;
      else question.QUESTION = `${type}_NEW`;

      question.PAGE_END = true;
      question.ANSWER_CHECK = true;
      question.BTN_HIDE = '';
      question.CREATE = true;
      question.QNUM = qnum;
      question.NAME = qnum;

      if (childInfo) {
        const { target, showHide } = childInfo;
        question.target = target;
        question.showHide = showHide;
      }

      return question;
    } catch (e) {
      console.log(e);
    } finally {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_END);
    }
  }
  /**
   * make 문항 추가
   * @param type
   */
  async add(
    type: QUESTION.QUESTION_TYPES,
    childInfo?: { target: string; showHide: QUESTION.CHILD_TYPE }
  ): Promise<{ result: boolean; qnum: string }> {
    try {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_START);

      const question: IQuestionValue = QUESTION.defaultQuestionData(type);
      question.TYPE = type;
      question.NAME = 'NEW';
      question.QUESTION = `${type}_NEW`;
      question.PAGE_END = true;
      question.ANSWER_CHECK = true;
      question.BTN_HIDE = '';
      question.CREATE = true;

      //보기 복사할 것 있으면 정보 add
      if (childInfo) {
        const { target, showHide } = childInfo;
        question.target = target;
        question.showHide = showHide;
      }

      const sendData = {
        TYPE: type,
        question: {
          [type]: {
            ...question,
          },
        },
      };

      const { data } = await app.axios.post(`/project/make/unisurvey/${this._id}`, sendData);
      const { result, qnum } = data;
      if (result) {
        app.$common.makeToast(`[${type}] ${ToastMessage.QUESTION_ADD}`, ToastVariant.SUCCESS, app.$bvToast);
        await this.reLoadQuestion(qnum);
      }
      return {
        result,
        qnum,
      };
    } catch (e) {
      console.log(e);
      return {
        result: false,
        qnum: '',
      };
    } finally {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_END);
    }
  }

  /**
   * 문항 복사
   * @param question
   * @param target
   * @param move
   */
  async copy(question: IQuestionValue, target: string, move: QUESTION.QUESTION_MOVE_TYPE): Promise<boolean> {
    try {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_START);
      const { TYPE, QUESTION: questionData } = question;
      const prevQuestion = questionData;
      let newQuestion = questionData;
      if (questionData.slice(-6) === '</div>') newQuestion = questionData.slice(0, -6);
      question.QUESTION = `${newQuestion}_COPY`;
      question.CREATE = true;
      // 저장할 경우 처음 생성된 문항이 아님으로
      question.CREATE = false;
      const etcTextObj = {};
      for (const i of Object.keys(question)) {
        if (i.indexOf('TEXT_') > -1) {
          etcTextObj[i] = question[i];
          delete question[i];
        }
      }
      delete question.EDIT;
      const sendData = {
        TYPE,
        TARGET: target,
        MOVE_TYPE: move,
        question: {
          [TYPE]: {
            ...question,
          },
        },
        etcTextObj,
      };
      const { data } = await app.axios.post(`/project/make/copy/unisurvey/${this._id}`, sendData);
      const { result, qnum } = data;
      if (result) {
        //제외한 TEXT_N 다시 추가 FRONT에 다시 반영하기 위해
        for (const i of Object.keys(etcTextObj)) {
          question[i] = etcTextObj[i];
        }
        app.$common.makeToast(ToastMessage.QUESTION_COPY, ToastVariant.SUCCESS, app.$bvToast);
        await this.reLoadQuestion(qnum);
      } else {
        question.QUESTION = prevQuestion;
        question.EDIT = true;
      }
      return result;
    } catch (e) {
      return false;
    }
  }

  private imgCheck(question: IQuestionValue): boolean {
    let result = true;

    const { TYPE, BOTTOM_DESC, DESC, TOP_DESC } = question;
    const DESC_TOP = TOP_DESC?.includes('<img');
    const DESC_MIDDLE = DESC?.includes('<img');
    const DESC_BOTTOM = BOTTOM_DESC?.includes('<img');

    let DESC_CONTENT = '';
    if (TYPE === 'TITLE') {
      const { HTML } = question;
      if (HTML) DESC_CONTENT = HTML;
    }
    const DESC_CONTENTS = DESC_CONTENT?.includes('<img');
    if (DESC_MIDDLE || DESC_TOP || DESC_BOTTOM || DESC_CONTENTS) result = false;
    return result;
  }
  /**
   * 문항 수정
   * @param name
   */
  async save(question: IQuestionValue, type?: string): Promise<boolean> {
    const { TYPE, NAME, QNUM } = question;
    try {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_START);
      const imgCheck = this.imgCheck(question); // 제목 문항 이미지 체크
      if (!imgCheck) {
        app.$common.makeToast(ToastMessage.NOT_IMAGE, ToastVariant.DANGER, app.$bvToast);
        return false;
      }

      const findIndex = this.questionList.findIndex((item) => item.NAME === NAME);
      if (findIndex === -1) {
        app.$common.makeToast(`[${QNUM}] ${ToastMessage.QUESTION_NOT_FIND}`, ToastVariant.DANGER, app.$bvToast);
        return false;
      }

      // 저장할 경우 처음 생성된 문항이 아님으로
      question.CREATE = false;

      const etcTextObj = {};
      for (const i of Object.keys(question)) {
        if (i.indexOf('TEXT_') > -1) {
          etcTextObj[i] = question[i];
          delete question[i];
        }
      }

      delete question['EDIT'];
      const sendData = {
        TYPE,
        question: {
          [TYPE]: { ...question },
        },
        etcTextObj,
      };
      const { data } = await app.axios.put(`/project/make/unisurvey/${this._id}/${NAME}`, sendData);
      const { result } = data;
      if (result) {
        app.$common.makeToast(`[${QNUM}] ${ToastMessage.QUESTION_SAVE}`, ToastVariant.SUCCESS, app.$bvToast);
        await this.reLoadQuestion(NAME);
      }
      return result;
    } catch (e) {
      return false;
    } finally {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_END);
    }
  }

  async setSurveySave() {
    try {
      const { data } = await app.axios.put(`/project/make/real/${this._id}`);
      const { result } = data;
      if (result) {
        app.$common.makeToast('설문을 저장하였습니다.', ToastVariant.SUCCESS, app.$bvToast);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 보기 삭제
   * @param answers
   * @param index
   */
  removeAnswer(answers: IAnswerValue[] = [], index: number): void {
    let limitCount = 1;
    if (answers.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) > -1) limitCount++;
    if (answers.findIndex((item) => item.K === QUESTION.ANSWERS.ETC_KEY) > -1) limitCount++;
    if (answers.findIndex((item) => item.K === QUESTION.ANSWERS.NONE_COMM_KEY) > -1) limitCount++;
    if (answers.length > limitCount) answers.splice(index, 1);
    answers = this.answerKeySort(answers);
  }

  /**
   * 보기추가
   * @param answers
   * @param index
   */
  addAnswer(answers: IAnswerValue[] = [], index: number): void {
    // 처음 생성되었을 때는 focus 될 경우 제목이 지워지도록 하기 위함.
    const answer = _.cloneDeep(answers[index]); // 이전 문항 복사

    delete answer.FILE;
    delete answer.IMG_LOCATION;

    const maxKey = answers.reduce((a, b) => {
      const aKey = a;
      const bKey = Number(b.K);
      return bKey > 9995 ? aKey : Math.max(aKey, bKey);
    }, -1);
    // 보기가 추가 될 경우 CREATE 속성 true
    answer.CREATE = true;
    answer.V = ''; // 추가될 경우 내용 입력란 빈값
    answer.K = String(maxKey + 1);
    answer.N = '';

    answers.splice(index + 1, 0, answer);
    this.answerKeySort(answers);
  }

  /**
   * 보기 Key 정렬
   * @param answers
   */
  answerKeySort(answers: IAnswerValue[] = []): IAnswerValue[] {
    return answers.map((a, index) => {
      if (Number(a.K) < 9995) a.K = String(index + 1);
      return a;
    });
  }

  /**
   * 박스형 분류 만들기
   */
  categoryMakerBox(info: IQuestionCategoryBoxInfo): HTMLElement {
    const { wrapper, wrapperRemoveClass, boxAddClass, qname, leftCategory, unit, units, wrapperAddClass } = info;
    var categoryWrapper; //분류상자
    let maxCategory = 0; //최대 분류 깊이
    let categoryExists = false;
    var box; //반환할 박스

    //---------- 분류가 없으면 wrapper 반환 ----------

    if (units == null || units.length == 0) return wrapper;

    for (let i = 0; i < units.length; i++) {
      const category = units[i][`C${maxCategory + 1}`] || '';
      if (category != '') {
        maxCategory++;
        categoryExists = true;
        i--; //같은 줄의 다음 깊이를 확인하기 위해 i 줄여줌
      }
    }
    //---------- 분류가 없으면 wrapper 반환 ----------
    if (!categoryExists) return wrapper;

    //---------- wrapper에서 추가해야 할 클래스 ----------
    if (wrapperAddClass) {
      wrapper.classList.add(wrapperAddClass);
    }
    //---------- wrapper에서 제거해야 할 클래스 ----------

    //---------- wrapper에서 제거해야 할 클래스 ----------
    if (wrapperRemoveClass) {
      wrapper.classList.remove(wrapperRemoveClass);
    }
    //---------- wrapper에서 제거해야 할 클래스 ----------

    //---------- 왼쪽 rowspan형 분류인 경우 ----------
    if (info.leftCategory) {
      // alert('leftCategory 작업 중...');
      // this.categoryMakerTable(info);
      return this.categoryMakerTable(info);
    }
    //---------- 왼쪽 rowspan형 분류인 경우 ----------

    //---------- 전체를 감싸는 div 없으면 새로 만들기 ----------
    categoryWrapper = wrapper.querySelector('div.categoryWrapper[qname="' + qname + '"]');
    if (!categoryWrapper) {
      categoryWrapper = document.createElement('div');
      categoryWrapper.classList.add('categoryWrapper');
      categoryWrapper.setAttribute('qname', qname);
      wrapper.appendChild(categoryWrapper);
    }
    //---------- 전체를 감싸는 div 없으면 새로 만들기 ----------

    //---------- 분류상자 생성이 필요한지 확인 ----------
    let needNewBox = false;
    let boxSelector = 'div.cb-body';
    for (let i = 1; i <= maxCategory; i++) {
      const c = (info.unit['C' + i] || '').replace(/"/g, '');
      if (c != '') {
        boxSelector += '[data-box-c' + i + '="' + c + '"]';
      } else {
        boxSelector += ':not([data-box-c' + i + '])';
      }
    }

    //const selector = categoryWrapper.querySelectorAll(boxSelector);
    //box= selector[selector.length -1];
    box = categoryWrapper.querySelectorAll(boxSelector);
    if (box.length == 0) {
      var needNewCategoryDepth;
      for (let i = 1; i <= maxCategory; i++) {
        const unitC = info.unit['C' + i] || '';
        const currentC = categoryWrapper.getAttribute('data-current-c' + i);
        if (currentC == null || currentC != unitC) {
          needNewBox = true;
          needNewCategoryDepth = i;
          break;
        }
      }
    }
    //---------- 분류상자 생성이 필요한지 확인 ----------

    //---------- 새로운 분류상자 생성 ----------
    if (needNewBox) {
      MakeBox(needNewCategoryDepth);

      //분류상자 만들기
      function MakeBox(needNewCategoryDepth) {
        let boxWrapper;
        let headerText = info.unit['C' + needNewCategoryDepth];
        if (needNewCategoryDepth == 1) {
          boxWrapper = categoryWrapper;
        } else {
          let boxWrapperSelector = 'div.cb-body';
          for (let i = 1; i < needNewCategoryDepth; i++) {
            const c = (info.unit['C' + i] || '').replace(/"/g, '');
            if (c != '') {
              boxWrapperSelector += '[data-box-c' + i + '="' + c + '"]';
            }
          }
          const categoryDepths = categoryWrapper.querySelectorAll(
            boxWrapperSelector + ':not([data-box-c' + needNewCategoryDepth + '])'
          );
          boxWrapper = categoryDepths[categoryDepths.length - 1];
        }

        //head 생성
        const header = document.createElement('div');
        header.classList.add('cb-header');
        header.innerHTML = headerText;
        header.setAttribute('depth', needNewCategoryDepth);
        boxWrapper.appendChild(header);

        //body 생성
        box = document.createElement('div');
        box.classList.add('cb-body');
        boxWrapper.appendChild(box);
        for (let i = 1; i <= needNewCategoryDepth; i++) {
          const c = (info.unit['C' + i] || '').replace(/"/g, '');
          if (c != '') {
            header.setAttribute('data-box-c' + i, c);
            box.setAttribute('data-box-c' + i, c);
          }
        }

        //마지막에 생성된 분류상자 정보 저장
        for (let i = 1; i <= maxCategory; i++) {
          let c = (info.unit['C' + i] || '').replace(/"/g, '');
          categoryWrapper.setAttribute('data-current-c' + i, c);
        }

        //최대 깊이까지 분류상자를 만들어야 함
        if (needNewCategoryDepth < maxCategory && (info.unit['C' + (needNewCategoryDepth + 1)] || '') != '') {
          MakeBox(needNewCategoryDepth + 1);
        }
      }
    }
    //---------- 새로운 분류상자 생성 ----------

    //---------- 분류상자 안의 보기 박스 준비 ----------
    //var unitsBox= $(box).find('> .units-box');
    if (Number(box.length)) box = box[0];
    const unitsBoxArray = box.querySelectorAll('div.units-box');
    let unitsBox = box.querySelector('.units-box');
    if (unitsBox) {
      return unitsBox;
    }
    unitsBox = document.createElement('div');
    unitsBox.classList.add('units-box');
    box.appendChild(unitsBox);
    //box에 추가해야 할 class
    if ((info.boxAddClass || '') != '') {
      unitsBox.classList.add(info.boxAddClass);
    }
    //---------- 분류상자 안의 보기 박스 준비 ----------

    return unitsBox;
  }

  /**
   * 왼쪽 rowspan형 분류 만들기
   * @param info
   */
  categoryMakerTable(info: IQuestionCategoryBoxInfo): HTMLElement {
    const { wrapper, wrapperRemoveClass, boxAddClass, qname, leftCategory, unit, units, wrapperAddClass } = info;
    const tableClassList = ['categoryTable', 'common', 'pure-table', 'pure-table-bordered', 'w-100'];
    let tbody: Element; //분류상자

    let maxCategory = 0; //최대 분류 깊이

    //---------- 최대 분류 깊이 구하기 ----------
    for (let i = 0; i < units.length; i++) {
      if ((units[i]['C' + (maxCategory + 1)] || '') != '') {
        maxCategory++;
        i--; //같은 줄의 다음 깊이를 확인하기 위해 i 줄여줌
      }
    }
    //---------- 최대 분류 깊이 구하기 ----------

    //---------- table 없으면 새로 만들기 ----------
    const isTable = wrapper.querySelector(`table.categoryTable[qname="${info.qname}"] tbody`);

    if (!isTable) {
      const newTable = document.createElement('table');
      tbody = document.createElement('tbody');

      newTable.classList.add(...tableClassList);
      newTable.setAttribute('qname', info.qname);
      newTable.appendChild(tbody);
      wrapper.appendChild(newTable);
    } else {
      tbody = isTable;
    }
    //---------- table 없으면 새로 만들기 ----------

    if (tbody) {
      // ------ tr 생성이 필요한지 확인 ----------
      let needNewTr = false;
      let lastTr = tbody.querySelector('tr:last-child');

      if (!lastTr) {
        needNewTr = true;
      } else {
        for (let i = 1; i <= maxCategory; i++) {
          let unitC = info.unit['C' + i] || '';
          let currentC = lastTr.getAttribute('data-unit-c' + i) || '';
          if (unitC != currentC) {
            needNewTr = true;
            break;
          }
        }
      }
      //---------- tr 생성이 필요한지 확인 ----------

      //---------- 새로운 tr 생성 ----------
      if (needNewTr) {
        lastTr = document.createElement('tr');
        const leftCategoryWidths = (info.leftCategoryWidths || '').split(',');
        const leftCategoryAligns = (info.leftCategoryAligns || '').split(',');

        tbody.appendChild(lastTr);
        for (let i = 1; i <= maxCategory; i++) {
          const newTd = document.createElement('td');
          let width: string | number = '20%';
          let align = 'center';

          lastTr.appendChild(newTd);
          lastTr.setAttribute('data-unit-c' + i, info.unit['C' + i]);

          newTd.classList.add('category-td');
          newTd.setAttribute('data-c', info.unit['C' + i]);
          newTd.setAttribute('data-category-depth', '' + i);
          newTd.innerHTML = info.unit['C' + i];
          ``;
          if (leftCategoryWidths.length >= i && leftCategoryWidths[i - 1].trim() != '') {
            width = leftCategoryWidths[i - 1];
          }
          if (isNaN(+width)) {
            newTd.style.width = width;
          } else {
            newTd.style.width = width + '%';
          }

          if (leftCategoryAligns.length >= i && leftCategoryAligns[i - 1].trim() != '') {
            align = leftCategoryAligns[i - 1];
          }

          newTd.style.textAlign = align;
        }
        const viewTd = document.createElement('td');
        viewTd.classList.add('answer-td', 'text-left');
        lastTr.append(viewTd);
      }
      //---------- 새로운 tr 생성 ----------

      //---------- 셀병합 ----------
      let tds = {},
        rowSpans = {};
      const trInTable = tbody.querySelectorAll('tr');

      trInTable.forEach(function (item, idx) {
        if (idx === 0) {
          for (let i = 1; i <= maxCategory; i++) {
            tds['C' + i] = item.querySelector('td[data-category-depth="' + i + '"]');
            rowSpans['C' + i] = 1;
          }
          return;
        }

        for (let i = 1; i <= maxCategory; i++) {
          let prevC = tds['C' + i].getAttribute('data-c') || '';
          const curr = item.querySelector('td[data-category-depth="' + i + '"]');
          const currC = item.querySelector('td[data-category-depth="' + i + '"]')?.getAttribute('data-c') || '';
          if (!curr) {
            // 걸러내기 위함
          } else if (prevC === currC) {
            const tdsValue = tds['C' + i];
            if (tdsValue) {
              let rowspan = tdsValue.getAttribute('rowspan') || 1;
              rowspan = +rowspan + 1;
              tdsValue.setAttribute('rowspan', '' + rowspan);
              item.querySelector('td[data-category-depth="' + i + '"]')?.remove();
            }
          } else {
            for (let j = i; j <= maxCategory; j++) {
              tds['C' + j] = item.querySelector('td[data-category-depth="' + j + '"]');
            }
            break;
          }
        }
      });
      //---------- 셀병합 ----------

      //---------- 보기 박스 준비 ----------
      let unitsBox = lastTr?.querySelectorAll('.units-box');
      if (unitsBox?.length == 1) {
        return unitsBox[0] as HTMLElement;
      }
      const unitsBoxDiv = document.createElement('div');
      unitsBoxDiv.classList.add('units-box');

      lastTr?.querySelector('td.answer-td')?.appendChild(unitsBoxDiv);
      if ((info.boxAddClass || '') != '') {
        unitsBoxDiv.classList.add(info.boxAddClass);
      }

      return unitsBoxDiv as HTMLElement;
    } else {
      console.log('table 존재하지 않음 오류.');
    }

    return wrapper;
  }

  makeComponentName(type: string): string {
    let viewComp: string = '';
    switch (type) {
      case QUESTION.QUESTION_TYPES.RADIO:
        viewComp = 'Radio';
        break;
      case QUESTION.QUESTION_TYPES.RADIOSET:
        viewComp = 'RadioSet';
        break;
      case QUESTION.QUESTION_TYPES.RADIOSETS:
        viewComp = 'RadioSets';
        break;
      case QUESTION.QUESTION_TYPES.CHECK:
        viewComp = 'Check';
        break;
      case QUESTION.QUESTION_TYPES.CHECKSETS:
        viewComp = 'CheckSets';
        break;
      case QUESTION.QUESTION_TYPES.GRADE_CLICK:
        viewComp = 'GradeClick';
        break;
      case QUESTION.QUESTION_TYPES.TEXTAREA:
        viewComp = 'TextArea';
        break;
      case QUESTION.QUESTION_TYPES.MULTI_TEXT:
        viewComp = 'MultiText';
        break;
      case QUESTION.QUESTION_TYPES.DESC:
        viewComp = 'InfoDesc';
        break;
      case QUESTION.QUESTION_TYPES.TITLE:
        viewComp = 'InfoTitle';
        break;
    }
    return viewComp;
  }

  makeEtc(
    { html, gridContent, wrapper, numInRow, qo, aKey } //: {html: string, gridContent: HTMLElement, wrapper: HTMLElement, numInRow: string, qo: IQuestionValue, aKey: string}
  ) {
    const output = {
      hasEtc: false,
    };
    const classArray = app.$common.numInRowToGrid(numInRow).split(' ');
    const etcClass = app.$common.numInRowToGrid('1').split(' ');
    if (numInRow) {
      //2021-06-30 기타가 있으면 무조건 한 줄로 표시
      if (output.hasEtc) {
        gridContent.classList.remove(...classArray);
        gridContent.classList.add(...etcClass);
      }
      //2021-07-21 줄당 보기수를 강제로 조정하는 경우
      //보기 내용에 {NIR:1} 을 넣으면 그 보기는 줄 당 1개만 제시함
      /*
            if(html.match(/\{NIR:([1-8])\}/i)!=null){
                var nir=html.match(/\{NIR:([1-8])\}/i);
                $(gridContent).removeClass(numInRowToGrid(etcInfo.numInRow)).addClass(numInRowToGrid(nir[1]));
                html=html.replace(nir[0],'');
            }

             */
    }

    //기타 박스 처리
    const reg = /\[(TEXT|NUMBER)_[\d]+\]/;
    let ord = 1;
    while (html.match(reg)) {
      const matchText = html.match(reg)[0];
      const typeNum = matchText.substr(1, matchText.length - 2).split('_');
      const type = typeNum[0];
      const num = typeNum[1];
      html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
      ord++;
      //2021-06-30 기타가 있으면 무조건 한 줄로 표시
      gridContent.classList.remove(...classArray);
      gridContent.classList.add(...etcClass);
    }
    wrapper.innerHTML = html;
    wrapper.querySelectorAll('span[type]').forEach((item) => {
      const type = item.getAttribute('type');
      const num = item.getAttribute('num');
      const ord = item.getAttribute('ord');
      const dataColumn = aKey + '_ETC_' + ord;
      const textInput = document.createElement('input');
      textInput.setAttribute('type', 'text');
      textInput.classList.add('input-etc');
      textInput.style['width'] = `${qo[type + '_' + num].WIDTH}px`;
      textInput.style['text-align'] = `${qo[type + '_' + num].ALIGN}`;
      textInput.style.maxWidth = '100%';

      textInput.setAttribute('data-column', dataColumn);
      textInput.setAttribute('disabled', 'disabled');
      textInput.setAttribute('akey', aKey);
      textInput.setAttribute('maxlength', qo[`${type}_${num}`].MAX_LENGTH);
      textInput.setAttribute('qname', qo.NAME);
      textInput.setAttribute('data-guide', qo[type + '_' + num].GUIDE);
      textInput.setAttribute('data-type', type);
      textInput.setAttribute('ord', ord);
      textInput.setAttribute('type-name', type + '_' + num);
      textInput.setAttribute('data-required', qo[`${type}_${num}`].REQUIRED);
      //textInput.setAttribute('data-object', JSON.stringify(this.data[type + '_' + num]));

      if (type === 'NUMBER') {
        textInput.setAttribute('data-min', qo[`${type}_${num}`].MIN);
        textInput.setAttribute('data-max', qo[`${type}_${num}`].MAX);
        textInput.classList.add('num-only');
      } else if (type === 'TEXT') {
        textInput.setAttribute('data-min-length', qo[`${type}_${num}`].MIN_LENGTH);
        textInput.setAttribute('data-max-length', qo[`${type}_${num}`].MAX_LENGTH);
      }

      item.appendChild(textInput);
    });
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $question: QuestionClass;
  }
}

export default {
  install(Vue: any) {
    Vue.prototype.$question = new QuestionClass();
  },
};
