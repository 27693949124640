
import { Component, Prop } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionHead from '@/components/project/make/options/common/OptionHead.vue';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import OptionKeyShow from '@/components/project/make/options/common/OptionKeyShow.vue';
import OptionGradation from '@/components/project/make/options/common/OptionGradation.vue';
import OptionMobileUI from '@/components/project/make/options/common/OptionMobileUI.vue';
import OptionTableShow from '@/components/project/make/options/common/OptionTableShow.vue';
import OptionVWidth from '@/components/project/make/options/common/OptionVWidth.vue';
import OptionMinMax from '@/components/project/make/options/common/OptionMinMax.vue';
import draggable from 'vuedraggable';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import { DEFAULT_MAX_COUNT } from '@/types/constant';
import { IAnswerValue } from '@/interface/survey/answer';
import {Properties, UploadPayload} from "@/interface/make/properties";
import {mixins} from "vue-class-component";
import {MakeMixins, MakeSurveyTemplate} from "@/mixins/project/MakeMixins";
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";

@Component({
  components: {
    draggable,
    OptionDescription,
    OptionSkipNotSkip,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionRandom,
    OptionKeyShow,
    OptionGradation,
    OptionHead,
    OptionMobileUI,
    OptionTableShow,
    OptionVWidth,
    OptionMinMax,
    OptionQuestion,
    QuestionImageUploader
  },
})
export default class OptionCheckSets extends mixins(MakeMixins) implements MakeSurveyTemplate{
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  valueMaxError: boolean = false;
  valueHMaxError: boolean = false;
  defaultMaxCount: number = DEFAULT_MAX_COUNT.CHECKSETS;
  mouseEvent: number = 0;

  resize(e: Event) {
    const target = e.target as HTMLInputElement;
    target.style.height = '1px';
    target.style.height = `${target.scrollHeight + 1}px`;
  }

  // 처음 생성됐을 경우 focus 될 경우 초기값 지우기
  removeContent(aItem: IAnswerValue): void {
    if (aItem?.CREATE) {
      aItem.V = '';
      aItem.CREATE = false;
    }
  }

  set mouseHoverComputed(type) {
    this.mouseEvent = type;
  }
  get mouseHoverComputed() {
    return this.mouseEvent;
  }

  mouseHover(type) {
    this.mouseEvent = type;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  randomData(data: string): void {
    this.data.RANDOM = data;
  }

  randomHData(data: string): void {
    this.data.RANDOM_H = data;
  }

  headData(data: string): void {
    this.data.V_HEAD = data;
  }

  minMaxData({ min, max }: { min: string; max: string }): void {
    if (max.length === 0) this.data.MAX_CHECK = (this.data.H!.length * this.data.V!.length).toString();
    else this.data.MAX_CHECK = max;
    this.data.MIN_CHECK = min;
  }

  private async imageAppend(data: UploadPayload<string, File>): Promise<void> {
    const {dataUrl, file, index} = data as {dataUrl: string, file: File, index: number};
    const sizeCheck = this.fileSizeCheck(file)
    if (sizeCheck) return;
    await this.singleQuestionImageUpload(data);
    if (this.data.V?.length) {
      this.plainText = this.data.V[index].V
      this.data.V[index].CREATE = false;
      this.data.V[index].V = this.$common.imageTagGenerator(dataUrl) + this.plainText;
      this.data.V[index].FILE = {origin: file, dataUrl: dataUrl }
    }
  }

  private removeFile(index: number): void {
    if (this.data.V) {
      this.plainText = this.data.V[index].V;
      delete this.data.V[index].FILE;
      this.data.V[index].IMG_LOCATION = '';
      this.data.V[index].V = this.$common.imageTagGenerator('') + this.plainText;
    }
    this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  vWidthData(data: string): void {
    this.data.V_WIDTH = data;
  }

  tableShowData({ byCard }: { byCard: boolean }) {
    this.data.BY_CARD = byCard;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  keyShowData(data: boolean) {
    this.data.KEY_SHOW = data;
  }

  /**
   * 보기 삭제 및 추가
   * @param index
   * @param add
   */
  vControl(index: number, add: boolean): void {
    this.valueMaxError = false;
    if (!add) {
      // 보기 삭제
      this.$question.removeAnswer(this.data.V, index);
    } else if (add) {
      //보기 추가
      if (this.data.V && this.data.V.length >= this.$data.setsValueMax) {
        this.valueMaxError = true;
        return;
      }
      this.$question.addAnswer(this.data.V, index);
    }
  }

  hControl(index: number, add: boolean): void {
    this.valueHMaxError = false;
    if (!add) {
      // 문항 삭제
      this.$question.removeAnswer(this.data.H, index);
    } else if (add) {
      //문항 추가
      if (this.data.H && this.data.H.length >= 10) {
        this.valueHMaxError = true;
        return;
      }
      this.$question.addAnswer(this.data.H, index);
    }
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }
}
