<script>
import { Pie } from 'vue-chartjs';
import { pieOption } from '@/mixins/chartOption';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Pie,
  props: ['chartData', 'viewOption'],
  data: () => ({
    totalArray: {
      dataArray: [],
      labelArray: [],
      colorArray: [],
      cntArray: [],
      keyArray: [],
    },
    options: {},
  }),
  computed: {},
  methods: {},
  created() {
    this.chartData.forEach((chartData) => {
      const { percent, text, color, cnt, key } = chartData;
      // 차트 라벨에 TEXT 항목 삭제
      const removeValue = `[TEXT_${key}]`;
      let removeTextLabel = this.$common.removeImageTag(text);
      if (text.indexOf(removeValue) > -1) {
        removeTextLabel = removeTextLabel.replace(`${removeValue}`, '');
      }
      const label = removeTextLabel + `[${key}]`;

      this.totalArray.labelArray.push(label);
      this.totalArray.dataArray.push(percent);
      this.totalArray.colorArray.push(color);
      this.totalArray.cntArray.push(cnt);
      this.totalArray.keyArray.push(key);
    });
    this.options = pieOption(this.totalArray.cntArray, this.totalArray.keyArray, this.viewOption);
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(
      {
        labels: this.totalArray.labelArray,
        datasets: [
          {
            backgroundColor: this.totalArray.colorArray,
            data: this.totalArray.dataArray,
          },
        ],
      },
      this.options
    );
  },
  watch: {
    dataArray() {
      this.renderChart(
        {
          labels: this.totalArray.labelArray,
          datasets: [
            {
              backgroundColor: this.totalArray.colorArray,
              data: this.totalArray.dataArray,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>
