
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/css/component/layout/user-setting.scss';

@Component({ components: {} })
export default class PaymentInfo extends Vue {
  paymentHistory: {
    projectId: number;
    projectTitle: string;
    price: string;
    purchasedDate: string;
    receiptUrl: string;
    status: number;
  }[] = [];
  userId: string = this.$store.getters.userId;
  page: number = +this.$route.params.page | 1;
  totalPage: number = 1;
  loading: boolean = true;

  created() {
    this.init();
  }

  private async init() {
    await this.getPaymentLists();
  }

  private async getPaymentLists() {
    const { data: result } = await this.axios.get(`/user/payment/lists/${this.userId}/${this.page}`);
    const { totalPage, list } = result;
    this.totalPage = totalPage;
    this.paymentHistory = list.map((value) => {
      const { status, data, projectId } = value;
      const { price, order_name, purchased_at, receipt_url } = data;
      //const convertPrice = (price + ' 원').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      const convertPrice = (price + ' 원').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const dateFormat = this.$moment(new Date(purchased_at)).format('YYYY-MM-DD hh:mm:ss');
      return {
        projectId: projectId,
        projectTitle: order_name,
        price: convertPrice,
        purchasedDate: dateFormat,
        receiptUrl: receipt_url,
        status,
      };
    });
    this.loading = false;
  }

  private showReceipt(url: string): void {
    window.open(url, '', 'height=955, width= 700, top=150, left=150, toolbar=no, resizeable=no');
  }

  // 페이징 처리
  linkGen(page: number) {
    return `/users/profile/${page}`;
  }

  // 결제 상태 CSS
  getPayStatusClass(status: number): string {
    if (status === 1) return 'completePaymentLabel';
    if (status === 20) return 'cancelCompletePaymentLabel';
    return '';
  }
  // 결제 상태 라벨
  payMessage(status: number): string {
    let message = '';
    if (status === 1) message = '결제 완료';
    if (status === 20) message = '결제 취소';
    return message;
  }

  get rows() {
    return this.paymentHistory.length;
  }
}
