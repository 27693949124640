
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class BellIcon extends Vue {
  @Prop({ default: false }) active: boolean;

  color() {}
}


