<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: ['headerArray', 'dataArray', 'options', 'colorArray'],
  data: () => ({
    backgroundColorArray: [],
    borderColorArray: [],
  }),
  computed: {
    chartData() {
      return this.dataArray;
    },
    chartHeader() {
      return this.headerArray;
    },
  },
  methods: {
    getRandomRGB(min, max) {
      if (min >= max) return false;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  created() {
    for (let i = 0; i < this.chartHeader.length; i++) {
      const r = this.getRandomRGB(0, 255),
        g = this.getRandomRGB(0, 255),
        b = this.getRandomRGB(0, 255);
      let backgroundColor = 'rgba';
      let borderColor = 'rgb';

      backgroundColor += `(${r}, ${g}, ${b}, 0.5)`;
      borderColor += `(${r}, ${g}, ${b})`;

      this.backgroundColorArray.push(backgroundColor);
      this.borderColorArray.push(borderColor);
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: this.chartHeader,
        datasets: [
          {
            backgroundColor: this.colorArray,
            borderColor: this.colorArray,
            pointBackgroundColor: 'white',
            borderWidth: 0.3,
            data: this.chartData,
            barPercentage: 0.5,
          },
        ],
      },
      this.options
    );
  },
  watch: {
    dataArray() {
      this.renderChart(
        {
          labels: this.chartHeader,
          datasets: [
            {
              backgroundColor: this.colorArray,
              borderColor: this.colorArray,
              borderWidth: 1,
              pointBackgroundColor: 'white',
              data: this.chartData,
              barPercentage: 0.5,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>
<style></style>
