
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NewMake extends Vue {
  userId: string;
  userPwd: string;

  constructor() {
    super();
    this.userId = '';
    this.userPwd = '';
  }

  async created() {
    await this.tokenDecode();
    await this.login();
  }

  async tokenDecode() {
    try {
      const { data } = await this.axios.get(`/user/join-verify-success/${this.token}`);
      const { result, userId, userPwd } = data;
      if (result) {
        this.userId = userId;
        this.userPwd = userPwd;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async login() {
    try {
      const { data } = await this.axios({
        url: `/user/sha-login`,
        method: 'POST',
        data: {
          userId: this.userId,
          userPwd: this.userPwd,
        },
      });
      const { result, err } = data;

      if (result) {
        await this.$store.dispatch('login', { data });
        await this.$router.push(`/project/make/${this.sNum}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  get token() {
    return this.$route.params.token;
  }

  get sNum() {
    return this.$route.query.sNum;
  }
}
