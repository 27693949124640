
import { Component, Emit, Vue } from 'vue-property-decorator';
import { ISurveyValue } from '@/interface/survey/question';
import EventBus from '@/utils/EventBus';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import { QUESTION } from '@/types/question';
import LINK_STATUS = QUESTION.LINK_STATUS;

interface ISurveyContent extends ISurveyValue {
  titleEdit?: boolean;
}
interface UpdateModalData {
  survey: ISurveyValue;
  type: string;
}
@Component
export default class MySurveyUpdateModal extends Vue {
  isLoading = false;
  modalTitle: string = '';
  modalDescription: string = '';
  modalType: string = '';
  localList: any = {};
  snum: number = 0;
  $refs: Vue['$refs'] & {
    title: HTMLElement;
    localList: Array<ISurveyContent>;
  };
  errorMsg: string = '';
  title: string = '';
  LINK_STATUS: number = -1;
  TYPE: number = 1;

  init() {
    this.isLoading = false;
  }

  mounted() {
    this.eventBusListener();
  }

  eventBusListener() {
    EventBus.$on('surveyData', this.initData);
  }

  initData(data: UpdateModalData) {
    const { survey, type } = data;
    const { TITLE, SNUM, CONFIG } = survey;
    const { LINK_STATUS, SIMPLE_SURVEY_TYPE } = CONFIG;
    this.TYPE = SIMPLE_SURVEY_TYPE || 1;
    this.LINK_STATUS = LINK_STATUS || -1;
    this.title = TITLE;
    this.snum = SNUM;
    this.localList = survey;
    this.modalTypeComputed = type;
    if (this.modalTypeComputed === 'copy') this.setCopyTitle();
    else if (this.modalTypeComputed === 'update') this.setUpdateTitle();
  }

  get questionEdit(): boolean {
    return (
      this.LINK_STATUS <= LINK_STATUS.REJECT &&
      this.LINK_STATUS !== LINK_STATUS.REAL &&
      this.LINK_STATUS !== LINK_STATUS.PAID
    );
  }

  get modalTypeCopy(): boolean {
    return this.modalType === 'copy';
  }

  async save(modalType: string) {
    this.isLoading = true;

    switch (modalType) {
      case 'copy':
        await this.copy();
        break;
      case 'update':
        await this.update();
        break;
    }
  }

  setUpdateTitle() {
    this.modalTitle = '설문 제목 변경';
    this.modalDescription = '변경할 설문 제목을 입력해 주세요.';
  }

  setCopyTitle() {
    this.modalTitle = '설문 복사';
    this.modalDescription = '설문 제목을 입력해 주세요.';
    this.title = `${this.title} 복사 본`;
  }

  async update(): Promise<void> {
    try {
      if (this.title.length < 3) {
        this.$common.makeToast(ToastMessage.EMPTY_COPY_TITLE, ToastVariant.DANGER, this.$bvToast);
        this.isLoading = false;
        return;
      }
      const { data } = await this.axios.put(`/project/title/${this.snum}`, { TITLE: this.title });
      const { result } = data;

      if (result) {
        this.localList.TITLE = this.title;
        this.$common.makeToast(ToastMessage.CHANGE_TITLE, ToastVariant.SUCCESS, this.$bvToast);
        EventBus.$emit('reloadRecentEditSurvey', true);
        this.$bvModal.hide('question-copy-modal');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async copy(): Promise<void> {
    try {
      if (this.title.length < 3) {
        this.$common.makeToast(ToastMessage.EMPTY_COPY_TITLE, ToastVariant.DANGER, this.$bvToast);
        this.isLoading = false;
        return;
      }
      const sendData = {
        TITLE: this.title,
      };
      const { data } = await this.axios.post(`/project/dup/${this.snum}`, sendData);
      const { result } = data;
      if (result) {
        this.$bvModal.hide('question-copy-modal');
        const message = `${this.title} 설문이 생성되었습니다.`;
        this.$common.makeToast(message, ToastVariant.SUCCESS, this.$bvToast);
        this.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  set modalTypeComputed(type: string) {
    this.modalType = type;
  }
  get modalTypeComputed() {
    return this.modalType;
  }

  @Emit('searchSurvey')
  reload() {
    return true;
  }
}
