
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { QUESTION } from "@/types/question";

@Component({})
export default class AlertModal extends Vue {
  @Prop({ default: '' }) modalId: string;
  @Prop() title?: string;
  @Prop() description?: string;

  @Emit('confirm')
  confirm() {
    this.$bvModal.hide(this.modalId);
    return true;
  }

  @Emit('cancel')
  cancel() {
    this.$bvModal.hide(this.modalId);
    return false;
  }
}
