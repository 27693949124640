
import { Component, Vue } from 'vue-property-decorator';
import { IBoardQuestion, BOARD } from '@/types/board';
import SearchIcon from "@/components/icons/common/SearchIcon.vue";

@Component({
  components: {SearchIcon}
})
export default class helpList extends Vue {
  BOARD: Object = BOARD;
  loading: boolean = true;
  helpList: BOARD.TQuestionList = [];
  page: number = 1;
  totalRow: number = 0;
  limitPage: number = 10;
  search: string = '';
  searchButtonHover: boolean = false;

  created() {
    this.load();
  }

  async load() {
    this.loading = true;
    try {
      const sendData: { PAGE: number; SEARCH_WORD: string } = {
        PAGE: this.page,
        SEARCH_WORD: this.search,
      };
      const { data } = await this.axios.get('/board/QuestionList/GetList', {
        params: sendData,
      });
      const { result, list, count } = data;
      if (result === true) {
        this.helpList = list;
        this.totalRow = count;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  statusColor(status: string): string {
    if (status === BOARD.QUESTION_STATUS.CHECKING) return 'warning';
    if (status === BOARD.QUESTION_STATUS.COMPLETE) return 'success';
    else return 'info';
  }

  questionView(index: number) {
    const list: IBoardQuestion = this.helpList[index];
    const { _id } = list;
    this.$router.push(`/board/question-read/${_id}`);
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }
}
