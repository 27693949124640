import {Module} from "vuex";
import {Store} from "@/interface/store";
import {io} from "socket.io-client";

const URL = process.env.VUE_APP_PRODUCTION === 'production' ?
  'https://unisurvey.co.kr/unisurvey' : 'https://unisurvey.co.kr/unisurvey';
const testUrl = 'http://localhost:3036/unisurvey'
const socketStore: Module<Store.Socket.Root, any> = {
  namespaced: true,
  state: {
    socket: null,
    questionInfo: [],
    userId: '',
    history: '',
    messageBox: [],
  },
  mutations: {
    setUserId: (state, payload) => {
      state.userId = payload;
    },
  },
  actions: {
    addSocketEvent(context, payload) {
      try {
        const {state} = context;
        const {userId, token} = payload;

        context.commit('setUserId', userId);

        state.socket = io(URL, {
          transports: ['websocket'],
          reconnection: true,
          reconnectionDelay: 5000,
          auth: {
            authorization: `Bearer ${token}`
          },
          query: {
            userId
          }
        });
        // 소켓 룸 연결
        state.socket.emit('join-room', { roomId: `unisurvey` });

        //프로젝튼 진행상황 알림
        state.socket.on('linkStatusCheckAll', (res) => {
          const {data, userId} = res as {data: {messageBox: Array<{message: string, SNUM: number}>, alertCount: number}, userId: string};
          if (state.userId.toUpperCase() === userId) {
            state.messageBox = data.messageBox;
          }
        });

        state.socket.on('loginHistoryCheck',async (res) => {
          const loginId = context.getters['getUserId'];
          const {ID} = res;
          if (ID === loginId) {
            alert('다른 브라우저에서 접속되어 로그아웃 됩니다.');
            window.location.replace('/logout');
          }

        })
      } catch (e) {
        console.log(e)
      }
    },
    loginCheck(context, payload) {
      const { TOKEN, userId } = payload;
      const { state } = context;
      state.socket?.emit('loginHistoryCheck', {
        userId,
        TOKEN
      })
    },
    linkStatusCheck(context, payload) {
      const {state} = context;
      const { userId } = payload;

      state.userId = userId;
      state.socket?.emit('linkStatusCheckAll', {
        userId,
      })
    },
    unisurveyAlertUpdate({state}, payload) {
      const {SNUM, userId, ALERT_CHECK} = payload;
      state.socket?.emit('unisurveyAlertUpdate', {
        SNUM,
        userId,
        ALERT_CHECK
      })
    },
    readMessageAll({state}, payload) {
      state.socket?.emit('readMessageAll', {
        userId: payload,
      })
    },
    // 회원가입 성공 알람 (어드민 쪽에 요청)
    joinSuccess({state}) {
      state.socket?.emit('adminNewUserCount', {adminId: 'SIMPLE-SURVEY'});
    },
    // 문의글 등록 알람 (어드민 쪽에 요청)
    publishPost({state}) {
      state.socket?.emit('admin-no-reply-question');
    },
    waitProject({state}) {
      state.socket?.emit('waitProjectCheck');
    },
    loginHistoryUpdate({state}, payload) {
      state.socket?.emit('loginHistoryUpdate', payload)
    },
    socketDestroy(context): void {
      const {state} = context;
      state.socket?.close();
    }
  },
  getters: {
    questionNotification: (state: Store.Socket.Root) => state.messageBox,
    getHistory: (state: Store.Socket.Root) => state.history,
    getUserId: (state: Store.Socket.Root) => state.userId,
    getSocket: (state: Store.Socket.Root) => state.socket,
  },
  modules: {}
}

export default socketStore;