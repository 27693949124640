import { render, staticRenderFns } from "./OptionCheck.vue?vue&type=template&id=13a6ad26&scoped=true"
import script from "./OptionCheck.vue?vue&type=script&lang=ts"
export * from "./OptionCheck.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a6ad26",
  null
  
)

export default component.exports