
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionGradation extends Vue {
  @Prop({ required: true, default: '#dddddd,#dddddd' }) backgroundColor: string;

  radioValue: string = '';
  label: string = '보기 색상 추가';
  startColor: string = '';
  endColor: string = '';
  colorDisable = true;
  buttonIndex: number = 0;

  options: IArrayOption[] = [
    { text: '미적용', value: '#dddddd,#dddddd' },
    { text: '파란', value: '#F5FAFE,#ABCCF3' },
    { text: '보라', value: '#8f94fb,#4e54c8' },
    { text: '주황', value: '#f7b733,#fc4a1a' },
    { text: '핑크', value: '#FFEFD5,#FFC0CB' },
    { text: '초록', value: '#34e89e,#0f3443' },
    { text: '임의', value: '-' },
  ];

  created() {
    this.radioValue = this.backgroundColor;
    this.buttonCheck();
    this.gradationChange();
    const findIndex = this.options.findIndex((item) => item.value === this.radioValue);
    if (findIndex === -1) {
      const colors = String(this.radioValue).split(',');
      this.startColor = colors[0] || '';
      this.endColor = colors[1] || '';
      this.radioValue = '';
    }
  }

  buttonCheck() {
    this.buttonIndex = this.options.findIndex((option) => option.value === this.backgroundColor);
  }

  onClickButtonHandler(type: string) {
    this.radioValue = type;
    this.gradationChange();
    switch (type) {
      case '#dddddd,#dddddd':
        this.buttonIndex = 0;
        return 0;
      case '#F5FAFE,#ABCCF3':
        this.buttonIndex = 1;
        return 1;
      case '#8f94fb,#4e54c8':
        this.buttonIndex = 2;
        return 2;
      case '#f7b733,#fc4a1a':
        this.buttonIndex = 3;
        return 3;
      case '#FFEFD5,#FFC0CB':
        this.buttonIndex = 4;
        return 4;
      case '#34e89e,#0f3443':
        this.buttonIndex = 5;
        return 5;
      case '-':
        this.buttonIndex = 6;
        return 6;
    }
  }

  gradationChange() {
    this.colorDisable = true;
    if (this.radioValue === '-') {
      this.colorDisable = false;
    } else {
      const colors = String(this.radioValue).split(',');
      this.startColor = colors[0] || '#dddddd';
      this.endColor = colors[1] || '#dddddd';
      this.$emit('set-data', this.radioValue);
    }
  }

  setColorData() {
    this.$emit('set-data', `${this.startColor},${this.endColor}`);
  }

  private showManual() {
    const use = require('@/assets/images/options/use-color.png');
    const none = require('@/assets/images/options/none-color.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>보기에 원하는 색상 조합을 추가할 수 있습니다.</b></h1><br />
                    <h2 class="option-description--text">제공되는 색상 조합 중 한가지를 활용하거나 [임의]를 선택하여 원하는 색상을 선택할 수 있습니다.</h2>
                    <hr />
                    <b class="mt-2 option-description--text-blue">[ 미사용 ]</b>
                    <img alt="단독제시" style='width: 100%; height: 70%;' src="${none}" />
                    <hr />
                    <b class="mt-2 option-description--text-blue">[ 색상 적용 ]</b>
                    <img alt="단독제시" style='width: 100%; height: 70%;' src="${use}" />
                </div>`,
    };
  }
}
