import { render, staticRenderFns } from "./IdIcon.vue?vue&type=template&id=6fb0827d&scoped=true"
import script from "./IdIcon.vue?vue&type=script&lang=ts"
export * from "./IdIcon.vue?vue&type=script&lang=ts"
import style0 from "./IdIcon.vue?vue&type=style&index=0&id=6fb0827d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb0827d",
  null
  
)

export default component.exports