
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import { BOARD, IBoardQuestion } from '@/types/board';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/common/ImageUpload.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class QuestionWriteContainer extends Vue {
  @Prop({ default: true }) userFlag: boolean;
  @Ref() boardContents: any;

  BOARD: Object = BOARD;
  isLoading: boolean = false;
  boardQuestion: IBoardQuestion = BOARD.boardQuestionInit();
  boardQuestionList: IBoardQuestion[] = [];
  boardQuestionLast: IBoardQuestion = BOARD.boardQuestionInit();
  boardQuestionSend: IBoardQuestion = BOARD.boardQuestionInit();
  listId: string = '';

  userId: string = '';
  customToolbar: any[] = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['image', 'code-block'],
  ];
  uploadImages: File[] = [];
  fileName = '';
  // dataUrlList: string[] = [];
  path = location.origin + '/uploads/Question/';
  file = [];

  $refs: Vue['$refs'] & {
    formFile: HTMLFormElement;
  };

  constructor() {
    super();
  }

  created() {
    this.userId = this.userFlag ? this.$store.getters.userId : '';
    this.boardQuestion.TYPE = this.userFlag ? BOARD.QUESTION_TYPE.SURVEY : BOARD.QUESTION_TYPE.USER;
    const { listId } = this.$route.params as { listId: string };
    this.listId = listId;
    if(listId){
      this.load();
    }
  }

  reset() {
    this.boardQuestion = BOARD.boardQuestionInit();
    this.userId = this.userFlag ? this.$store.getters.userId : '';
    this.boardQuestion.TYPE = this.userFlag ? BOARD.QUESTION_TYPE.SURVEY : BOARD.QUESTION_TYPE.USER;
    this.file = [];
    this.uploadImages = [];
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.boardQuestionLast = question[this.boardQuestionList.length-1];
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  async save(): Promise<void> {
    const isAdmin = this.$store.getters.isAdmin;
    const isEmailReg = isAdmin ? true : this.$common.emailCheck(this.userId);

    try {
      const { TITLE, TYPE } = this.boardQuestion;
      const { CONTENTS } = this.listId?this.boardQuestionSend:this.boardQuestion;

      if (TITLE.length < 2) {
        this.$common.makeToast(ToastMessage.EMPTY_TITLE, ToastVariant.DANGER, this.$bvToast);
        return;
      } else if (!isEmailReg) {
        this.$common.makeToast(ToastMessage.EMAIL_NOT_MATCH, ToastVariant.DANGER, this.$bvToast);
        return;
      } else if (CONTENTS.length === 0) {
        this.$common.makeToast(ToastMessage.EMPTY_CONTENT, ToastVariant.DANGER, this.$bvToast);
        return;
      }

      if (CONTENTS.length > 0) {
        const quill = this.boardContents.quill;
        const size = quill.getLength();
        if (size - 1 < 5) {
          this.$common.makeToast('내용을 5자 이상 입력해주세요.', ToastVariant.DANGER, this.$bvToast);
          return;
        }
      }

      this.isLoading = true;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append('fileList', file);
      });

      const sendData = {
        CONTENTS,
        TITLE,
        TYPE,
        userId: this.userId,
        ROOT_ID: this.listId || '',
        LEVEL: this.listId ? this.boardQuestionLast.LEVEL+1 : 0,
      };

      const { data } = await this.axios.post(`/board/QuestionWrite/Write`, sendData);

      if (this.uploadImages.length > 0) {
        const { inserted } = data;
        await this.uploadFile(inserted._id, formData);
      }
      const { result } = data;
      this.isLoading = false;
      if (result) {
        await this.$store.dispatch('socketStore/publishPost');

        if (this.userFlag) {
          await this.$common.makeToast(ToastMessage.ASK_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
          setTimeout(async () => {
            await this.$router.push('/board/question-list');
          }, 300);
        }

      }
      this.saveResult(result);
    } catch (e) {
      console.log(e);
    }
  }

  directHelpHome(): void {
    this.$router.push({
      path: '/board/question-list',
    });
  }

  async uploadFile(_id: string, formData: FormData) {
    await this.axios.post(`/board/QuestionWrite/Write/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  @Emit('save-result')
  saveResult(result: boolean) {
    return result;
  }

  handleImageRemove() {
    this.uploadImages.splice(0, 1);
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const sendData = {
        file,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ['jpg', 'jpeg', 'png'];

    const [image, imageType] = type.split('/');
    if (image !== 'image' || !typeList.includes(imageType)) {
      this.$common.makeToast(ToastMessage.IMAGE_TYPE, ToastVariant.WARNING, this.$bvToast);
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }

  private imageAppend(data: { file: File }) {
    this.uploadImages.push(data.file);
    this.fileName = data.file.name;
  }
}
