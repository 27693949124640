
import {Component, Vue} from "vue-property-decorator";
import LoginComponent from "@/components/users/sign-in/LoginComponent.vue";
import UnisurveyTutorial from "@/components/users/sign-in/UnisurveyTutorial.vue";
import UnisurveyFooter from "@/components/layout/UnisurveyFooter.vue";

@Component({
  components: {
    UnisurveyTutorial,
    LoginComponent,
    UnisurveyFooter
  }
})
export default class SignInView extends Vue {

}
