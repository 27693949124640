
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IQuestionValue } from '@/interface/survey/question';
import type { IArrayOption } from '@/interface/common';
import EventBus from '@/utils/EventBus';
import { QUESTION } from '@/types/question';

@Component
export default class QuestionModalCopy extends Vue {
  @Prop({ default: '' }) modalId: string;
  @Prop({ default: '' }) question: IQuestionValue;
  cursor: string = '';
  pointer: QUESTION.QUESTION_MOVE_TYPE = QUESTION.QUESTION_MOVE_TYPE.APPEND;
  makeList: IQuestionValue[] = [];
  makeListOptions: IArrayOption[] = [];
  pointerOption: IArrayOption[] = QUESTION.QUESTION_MOVE_OPTION;
  isLoading: boolean = false;

  created() {
    EventBus.$on(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD, this.updateMakeList);
  }

  mounted() {
    this.updateMakeList();
  }

  open() {
    this.updateMakeList();
    this.cursor = '';
    this.$bvModal.show(this.modalId);
  }

  close() {
    this.$bvModal.hide(this.modalId);
    this.$emit('update', true);
  }

  init() {
    this.isLoading = false;
    this.updateMakeList();
  }

  updateMakeList() {
    const makeList = this.$question.getMakeList();
    const { FIXED_QUESTIONS, QUESTION_MOVE_TYPE } = QUESTION;
    const fixedFirstIndex = makeList.findIndex((item) => item.NAME === FIXED_QUESTIONS[FIXED_QUESTIONS.length - 1]);
    this.makeList = makeList.filter((item, idx) => {
      return fixedFirstIndex < idx;
    });
    if (this.makeList.length > 0) {
      this.makeListOptions = this.makeList.map((data) => {
        return { text: `${data.QNUM}`, value: data.NAME };
      });
      this.cursor = this.makeListOptions[0].value as string;
      this.pointer = QUESTION_MOVE_TYPE.APPEND;
    }
  }

  async copy() {
    this.isLoading = true
    await this.$question.copy(this.question, this.cursor, this.pointer);
    this.isLoading = false;
    this.close();
  }
}
