
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { QUESTION } from '@/types/question';
import { IQuestionValue } from '@/interface/survey/question';
import Radio from '@/components/project/make/join/Radio.vue';
import RadioSet from '@/components/project/make/join/RadioSet.vue';
import RadioSets from '@/components/project/make/join/RadioSets.vue';
import Check from '@/components/project/make/join/Check.vue';
import CheckSets from '@/components/project/make/join/CheckSets.vue';
import MultiText from '@/components/project/make/join/MultiText.vue';
import TextArea from '@/components/project/make/join/TextArea.vue';
import InfoDesc from '@/components/project/make/join/InfoDesc.vue';
import GradeClick from '@/components/project/make/join/GradeClick.vue';
import InfoTitle from '@/components/project/make/join/InfoTitle.vue';
import {IChecklist} from "@/interface/admin/checklist";

@Component({
  components: {
    Radio,
    RadioSet,
    MultiText,
    InfoDesc,
    GradeClick,
    InfoTitle,
    TextArea,
    RadioSets,
    Check,
    CheckSets,
  },
})
export default class Preview extends Vue {
  @Prop({ required: true }) private readonly question: IQuestionValue[];
  @Prop() private readonly checklist: IChecklist;
  @Prop({default: false}) isAdmin: boolean

  makeList: IQuestionValue[] = [];
  isPrivate: string[] = [];
  isSensitive: string[] = [];
  isPolitical: string[] = [];

  constructor() {
    super();
  }

  created() {
    this.makeList = this.question.filter(
      (item) => QUESTION.HIDE_QUESTIONS.indexOf(item.NAME) === -1 || QUESTION.FIXED_QUESTIONS.indexOf(item.NAME) > -1
    );

    if (!this.checklist) return;

    const { isPrivate, isSensitive, isPolitical } = this.checklist;
    this.isPrivate = isPrivate.length ? isPrivate: [];
    this.isSensitive = isSensitive.length ? isSensitive: [];
    this.isPolitical = isPolitical.length ? isPolitical: [];
  }

  questionTypeName(item: IQuestionValue): string {
    const type = QUESTION.QUESTION_TYPES_LIST.find((question) => question.value === item.TYPE);
    const { text } = type || { text: '' };
    return text;
  }

  private pushCheckList(qNum: string, key: string){
    const list = this[key as keyof Preview];
    if(list.includes(qNum)){
      const filtered = list.filter((p)=> p !== qNum);
      this[key as keyof Preview].splice(0);
      filtered.forEach((item)=>{
        this[key as keyof Preview].push(item);
      })
    }else{
      this[key as keyof Preview].push(qNum);
    }

    this.update();
  }

  private async update(){
    const sendData = {
      _id : this.$route.params.sNum,
      isPrivate : this.isPrivate,
      isSensitive : this.isSensitive,
      isPolitical : this.isPolitical,
    }
    const result = await this.axios.post(`/admin/project/checkListDetailSave`, sendData);
  }
}
