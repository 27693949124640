
import { Component, Prop } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionNumInRow from '@/components/project/make/options/common/OptionNumInRow.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import OptionLeftCategory from '@/components/project/make/options/common/OptionLeftCategory.vue';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionMinMax from '@/components/project/make/options/common/OptionMinMax.vue';
import OptionTextPass from '@/components/project/make/options/common/OptionTextPass.vue';
import OptionRegex from '@/components/project/make/options/common/OptionRegex.vue';
import { ITextDenyValue } from '@/interface/survey/question';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import { DEFAULT_MAX_COUNT } from '@/types/constant';
import { Properties } from "@/interface/make/properties";
import { mixins } from "vue-class-component";
import { MakeMixins, MakeSurveyTemplate } from "@/mixins/project/MakeMixins";

@Component({
  components: {
    OptionDescription,
    OptionSkipNotSkip,
    OptionMinMax,
    OptionRegex,
    OptionTextPass,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionNumInRow,
    OptionRandom,
    OptionLeftCategory,
    OptionQuestion,
  },
})
export default class OptionMultiText extends mixins(MakeMixins) implements MakeSurveyTemplate{
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  defaultMaxCount: number = DEFAULT_MAX_COUNT.TEXT;

  passData(pass: string): void {
    this.data.PASS = pass;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  minMaxCountData({ min, max }: { min: string; max: string }): void {
    this.data.MIN_COUNT = min;
    this.data.MAX_COUNT = max;
  }

  minMaxLengthData({ min, max }: { min: string; max: string }): void {
    this.data.MIN_LENGTH = min;
    this.data.MAX_LENGTH = max;
  }

  textDenyData(textDeny: ITextDenyValue[]): void {
    this.data.TEXT_DENY = textDeny;
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }

  private showEnteredWidthManual() {
    const width200 = require('@/assets/images/options/width_200.png');
    const width400 = require('@/assets/images/options/width_400.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text">입력란의 너비를 설정합니다. ( 기본 설정 값은 200 입니다. )</h1>
                    <hr />
                    <img alt="200" style='width: 100%;' src="${width200}" />
                    <img alt="400" style='width: 100%;' src="${width400}" />
                </div>`,
    };
  }
}
