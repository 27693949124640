
import {Component, Prop} from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import draggable from 'vuedraggable';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionColor from '@/components/project/make/options/common/OptionColor.vue';
import { IArrayOption } from '@/interface/common';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import {DESCRIPTION_MAX_SIZE} from "@/constants";
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";
import {UploadPayload} from "@/interface/make/properties";
import {mixins} from "vue-class-component";
import {MakeMixins} from "@/mixins/project/MakeMixins";

@Component({
  components: {
    draggable,
    OptionDescription,
    OptionSkipNotSkip,
    OptionPageEnd,
    OptionBtnHide,
    OptionColor,
    QuestionImageUploader
  },
})
export default class OptionInfoDesc extends mixins(MakeMixins) {
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  alignOptions: IArrayOption[] = [
    { text: '좌측', value: 'left' },
    { text: '중앙', value: 'center' },
    { text: '우측', value: 'right' },
  ];

  borderPxOptions: IArrayOption[] = [
    { text: '사용안함', value: '' },
    { text: '1px', value: '1px' },
    { text: '2px', value: '2px' },
    { text: '3px', value: '3px' },
    { text: '4px', value: '4px' },
    { text: '5px', value: '5px' },
    { text: '6px', value: '6px' },
    { text: '7px', value: '7px' },
    { text: '8px', value: '8px' },
    { text: '9px', value: '9px' },
    { text: '10px', value: '10px' },
  ];

  borderTypeOptions: IArrayOption[] = [
    { text: '사용안함', value: '' },
    { value: 'dotted', text: '점선' },
    { value: 'dashed', text: '대쉬' },
    { value: 'solid', text: '직선' },
    { value: 'double', text: '이중 직선' },
    { value: 'groove', text: '파임' },
    { value: 'ridge', text: '튀어나옴' },
    { value: 'inset', text: '음각' },
    { value: 'outset', text: '양각' },
  ];

  borderColor = '#000000';
  borderPx = '';
  borderType = '';
  mouseEvent = -1;

  mounted() {
    this.borderInit();
  }

  private async imageAppend(data: UploadPayload<string, File>): Promise<void> {
    const {dataUrl, file, index} = data as {dataUrl: string, file: File, index: number};
    const sizeCheck = this.fileSizeCheck(file)
    if (sizeCheck) return;
    await this.singleQuestionImageUpload(data);
    if (this.data.DATA && this.data.DATA?.length) {
      this.plainText = this.data.DATA[index].HTML  || '';
      this.data.DATA[index].HTML = this.$common.imageTagGeneratorInDesc(dataUrl) + this.data.DATA[index].HTML;
      this.data.DATA[index].FILE = {origin: file, dataUrl: dataUrl }
    }
  }

  private removeFile(index: number): void {
    if (this.data.DATA) {
      this.plainText = this.data.DATA[index].HTML || '';
      delete this.data.DATA[index].FILE;
      this.data.DATA[index].IMG_LOCATION = '';
      this.data.DATA[index].HTML = this.$common.imageTagGeneratorInDesc('') + this.data.DATA[index].HTML;
    }
    this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
  }

  set mouseHoverComputed(type) {
    this.mouseEvent = type;
  }
  get mouseHoverComputed() {
    return this.mouseEvent;
  }

  borderInit() {
    const { BORDER } = this.data;
    const borderData = BORDER?.split(' ') || [];
    if (borderData.length > 1) {
      this.borderColor = borderData[0];
      this.borderPx = borderData[1];
      this.borderType = borderData[2];
    }
  }

  resize(e: Event) {
    const target = e.target as HTMLInputElement;
    target.style.height = '1px';
    target.style.height = `${target.scrollHeight + 1}px`;
  }

  descriptionLengthCheck(contents: string): string{
    const text = this.$common.removeImageTag(contents);
    if (text.length > 0) {
      if (text.length > DESCRIPTION_MAX_SIZE) {
        this.$common.makeToast(`안내문은 ${ToastMessage.MAX_TEXT_LENGTH}`, ToastVariant.DANGER, this.$bvToast);
      }
    }
    return contents.substring(0, DESCRIPTION_MAX_SIZE)
  }

  mouseHover(type) {
    this.mouseEvent = type;
  }


  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  /**
   * 보기 삭제 및 추가
   * @param index
   * @param add
   */
  answersControl(index: number, add: boolean): void {
    if (!add) {
      // 보기 삭제
      if (index > 0) {
        this.data.DATA?.splice(index, 1);
      }
    } else if (add) {
      //보기 추가
      const data = { HTML: '', ALIGN: 'left', CSS: '' };
      // 안내문 제한
      if (this.data.DATA!.length >= 5) {
        return;
      }
      this.data.DATA?.splice(index + 1, 0, data);
    }
  }

  backgroundData(data: string): void {
    this.data.BG = data;
  }

  borderColorData(data: string): void {
    this.borderColor = data;
    this.borderData();
  }

  borderData(): void {
    this.$common.makeToast(ToastMessage.CHOICE, ToastVariant.SECONDARY, this.$bvToast);
    this.data.BORDER = `${this.borderColor} ${this.borderPx} ${this.borderType}`;
  }

  private showManual(type) {
    let content = '';
    switch (type) {
      case 'border':
        content = `<div>
                    <h1 class="option-description--text">안내문 테두리의 굵기를 지정합니다.</h1>
                   </div>`;
        break;
      case 'type':
        content = `<div>
                    <h1 class="option-description--text">안내문 테두리의 유형을 지정합니다. (점선, 실선, 등)</h1>
                   </div>`;
        break;
    }

    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'top',
      content: content,
    };
  }
}
