
import { Component, Vue } from 'vue-property-decorator';
import UpdateModal from '@/components/users/profile/update/UpdateModal.vue';
import { STYLE } from '@/interface/style';
import '@/assets/css/component/layout/user-setting.scss';

export type ModalType = 'nickname' | 'password' | '';

@Component({ components: { UpdateModal } })
export default class ProfileInfo extends Vue {
  private marketReceive: boolean = false;
  private modalType: ModalType = '';
  private userId: string = this.$store.getters.userId;
  private loading: boolean = false;
  marketingCheck = false;
  check_box = STYLE.CHECK_BOX;
  uncheck_box = STYLE.UNCHECK_BOX;

  created() {
    this.load();
  }

  /**
   * @description: 마켓팅 동의
   * @private
   */
  private async agreeMarketing(): Promise<void> {
    this.marketingCheck = true;
    try {
      const sendData = {
        userId: this.userId,
        marketingAuth: !this.marketReceive,
      };
      await this.axios.patch('/user/marketing', sendData);
      setTimeout(() => {
        this.marketingCheck = false;
      }, 1000)
    } catch (e) {
      console.log(e);
    }

  }

  private changeInformation(modalType: ModalType) {
    this.ChangeModalComputed = modalType;
    this.$bvModal.show('user-info-update-modal');
  }
  private set ChangeModalComputed(modalType: ModalType) {
    this.modalType = modalType;
  }
  private get ChangeModalComputed(): ModalType {
    return this.modalType;
  }

  async load() {
    await this.receiveCheck();
  }

  // 마케팅 수신 여부 확인
  async receiveCheck() {
    this.loading = true;
    const { data } = await this.axios.get(`/user/verify-wait/${this.userId}`);
    const { marketingAuth } = data;
    this.marketReceive = marketingAuth;
    this.loading = false;
  }

  get loginType(): string {
    return this.$store.getters.loginType;
  }

  get userNickname(): string {
    const userNickname: string = this.$store.getters.userNickname;
    if (!userNickname) return '유니서베이';
    return userNickname;
  }
}
