export default {
  data: () => ({
    env: {
      templateName: `UNI SURVEY`,
    },
    example: {
      question: '추가 / 삭제',
      key: '번호',
      value: '보기 내용 입력',
      items: '추가 옵션',
      placeholder_row: '보기 내용을 입력하세요.',
      placeholder_column: '질문 내용을 입력하세요.',
    },
    setsValueMax: 15, // sets 문항 보기 최대 15개
  }),
  methods: {},
};
