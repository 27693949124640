
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import { VueEditor, Quill } from 'vue2-editor';
import htmlEditButton from 'quill-html-edit-button';
import {DESCRIPTION_MAX_SIZE} from "@/constants";
Quill.register('modules/htmlEditButton', htmlEditButton);


@Component({
  components: {
    VueEditor,
  },
})
export default class OptionDescription extends Vue {
  @Prop({ required: false }) desc: string;
  @Prop({ required: true }) topDesc: string;
  @Prop({ required: true }) bottomDesc: string;
  @Prop({ required: false, default: true }) descShow: string;
  @Ref() descriptionTop: any;
  @Ref() descriptionBody: any;
  @Ref() descriptionBottom: any;
  tempDesc: string = '';
  tempTopDesc: string = '';
  tempBottomDesc: string = '';

  customToolbar: any[] = [['italic', 'underline'], [{ color: [] }], ['clean']];
  descriptionMessage: {
    top: boolean,
    body: boolean,
    bottom: boolean,
  } = {
    top: false,
    body: false,
    bottom: false,
  }
  descInfo: { desc: string; topDesc: string; bottomDesc: string } = {
    desc: '',
    topDesc: '',
    bottomDesc: '',
  };

  created() {
    this.tempDesc = this.desc || '';
    this.tempTopDesc = this.topDesc;
    this.tempBottomDesc = this.bottomDesc;
  }

  mounted() {
    const editor: NodeListOf<HTMLElement> = document.querySelectorAll('.desc-editor');
    editor.forEach((item) => {
      const div: HTMLElement = item.querySelector('div.ql-editor') || document.createElement('div');
      div.style.minHeight = '0px';
    });
    const toolbarButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.ql-toolbar button');
    toolbarButtons.forEach((item) => {
      item.style.width = '30px';
    });
  }

  setData() {
    if (this.tempTopDesc.length > 0) {
      const quill = this.descriptionTop.quill;
      const size = quill.getLength()
      if (size > DESCRIPTION_MAX_SIZE) {
        this.descriptionMessage.top = true;
        quill.deleteText(DESCRIPTION_MAX_SIZE, size);
      } else {
        this.descriptionMessage.top = false;
      }
      this.descInfo.topDesc = this.tempTopDesc;
    } else {
      this.descriptionMessage.top = false;
      this.descInfo.topDesc = this.tempTopDesc;
    }

    if (this.tempDesc.length > 0) {
      const quill = this.descriptionBody.quill;
      const size = quill.getLength()
      if (size > DESCRIPTION_MAX_SIZE) {
        this.descriptionMessage.body = true;
        quill.deleteText(DESCRIPTION_MAX_SIZE, size);
      } else
        this.descriptionMessage.body = false;
      this.descInfo.desc = this.tempDesc
    } else {
      this.descriptionMessage.body = false;
      this.descInfo.desc = this.tempDesc;
    }

    if (this.tempBottomDesc.length > 0) {
      const quill = this.descriptionBottom.quill;
      const size = quill.getLength()
      if (size > DESCRIPTION_MAX_SIZE) {
        this.descriptionMessage.bottom = true;
        quill.deleteText(DESCRIPTION_MAX_SIZE, size);
      } else {
        this.descriptionMessage.bottom = false;
      }
      this.descInfo.bottomDesc = this.tempBottomDesc
    } else {
      this.descriptionMessage.bottom = false;
      this.descInfo.bottomDesc = this.tempBottomDesc;
    }
    this.$emit('set-data', this.descInfo);
  }

  private showManual() {
    const url = require('@/assets/images/options/description.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text">부가적인 설명이 필요할 경우, <b>[설명문]</b> 기능을 활용해보세요.</h1><br/>
                     <hr />
                    <h2 class="option-description--text"><b>[상단 설명문]</b>은 질문 위에 설명문을 표시합니다. </h2><br/>
                    <h2 class="option-description--text"><b>[설명문]</b>은 질문과 보기 사이에 설명문을 표시합니다.</h2><br/>
                    <h2 class="option-description--text"><b>[하단 설명문]</b>은 보기 아래에 설명문을 표시합니다.</h2>
                    <img alt="문항제시1" style='width: 77%; padding-top: 10px; padding-bottom: 10px; padding-left: 150px;' src="${url}" />
                </div>`,
    };
  }
}
