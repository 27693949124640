import { NavigationGuardNext, Route } from 'vue-router';
import { ins } from '@/utils/axios';
import store from '@/store';

export const resultRedirectGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  try {
    const snum = to.params.id;
    const { token } = store.getters;
    const { data } = await ins.get(`/router/result-page/${snum}?token=${token}`);
    const { redirectUrl } = data;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      next();
    }
  } catch (e) {
    console.error('잘못된 요청입니다.');
  }
};
