
import { Component, Vue } from 'vue-property-decorator';
import TemplateSideList from '@/components/main/sideList/TemplateSideList.vue';
import SurveySideList from '@/components/main/sideList/SurveySideList.vue';
import SurveyContent from '@/components/main/content/SurveyContent.vue';
import TemplateContent from '@/components/main/content/TemplateContent.vue';

@Component({
  components: {
    SurveySideList,
    SurveyContent,
    TemplateSideList,
    TemplateContent,
  },
})
export default class ProjectList extends Vue {
  loading: boolean = false;
  disabled: boolean = false;

  private refNames = {
    TEMPLATE_SIDE: 'template-side',
    TEMPLATE_CONTENT: 'template-content',
  };

  $refs: {
    'template-side': TemplateSideList;
    'template-content': TemplateContent;
  };

  templateUpdate() {
    this.$refs['template-side'].load();
  }
}
