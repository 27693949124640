
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {IProjectValue} from "@/interface/survey/question";
import {QUESTION} from "@/types/question";
import {UserIcon, ProgressBoardIcon, PaperIcon, ChartIcon, CreditCardIcon} from "@/components/icons/common";
import {ROUTE_PATH} from "@/types/constant";

@Component({
  components: {UserIcon, ProgressBoardIcon, PaperIcon, ChartIcon, CreditCardIcon}
})
export default class MenuTabs extends Vue {
  @Prop({default: QUESTION.defaultSurveyInfo}) surveyInfo: IProjectValue;
  @Prop() projectId: number;

  currentTab: number = -1;

  created(): void {
    this.routePathCheck();
  }

  @Watch('route')
  routePathCheck() {
    const { name } = this.$route;
    if (name === ROUTE_PATH.SAMPLING) this.currentTab = 0;
    if (name === ROUTE_PATH.MAKE) this.currentTab = 1;
    else if (name === ROUTE_PATH.PAYMENT) this.currentTab = 2;
    else if (name === ROUTE_PATH.QUOTA) this.currentTab = 3;
    else if (name === ROUTE_PATH.RESULT) this.currentTab = 4;
  }

  tabIndexRouter(index: number) {
    if (index === this.currentTab) return;
    // 리스트 조사는 결제 화면이 없으므로 현황페이지로 이동
    if (this.surveyInfo.CONFIG.SIMPLE_SURVEY_TYPE === 2 && this.currentTab === 2) this.currentTab = 3;

    if (index === 0) this.$router.push(`/project/sampling/${this.projectId}`);
    if (index === 1) this.$router.push(`/project/make/${this.projectId}`);
    if (index === 2) this.$router.push(`/project/payment/${this.projectId}`);
    if (index === 3) this.$router.push(`/project/quota/${this.projectId}`);
    if (index === 4) this.$router.push(`/project/result/${this.projectId}`);
  }

  active(index: number): boolean {
    return index === this.currentTab;
  }
}
