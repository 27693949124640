
import { Component, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import QuestionWriteContainer from '@/components/board/QuestionWriteContainer.vue';

@Component({
  components: {
    QuestionWriteContainer,
  },
})
export default class QuestionWriteModal extends Vue {
  $refs: Vue['$refs'] & {
    'question-write-modal': BModal;
    QuestionWriteContainer: QuestionWriteContainer;
  };

  async open() {
    await this.$bvModal.show('question-write-modal');
    this.$refs.QuestionWriteContainer.reset();
  }

  saveResult(result: boolean) {
    if (result) this.$bvModal.hide('question-write-modal');
  }
}
