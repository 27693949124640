var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"question-list",style:(_vm.componentType === 'result' ? 'top: 200px;' : '')},[_vm._m(0),_c('b-overlay',{staticClass:"scroll scroll-type scroll-container",attrs:{"id":"listNavi","show":_vm.loading,"opacity":"0.5","rounded":"","spinner-variant":"primary","wrap-tag":"section"}},[(_vm.$route.path.includes('/project/make'))?_c('draggable',_vm._b({on:{"change":_vm.navigationSort},model:{value:(_vm.tempList),callback:function ($$v) {_vm.tempList=$$v},expression:"tempList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{class:_vm.dragClass,attrs:{"name":"navigation"}},_vm._l((_vm.tempList),function(qItem,qIndex){return _c('div',{key:`question-${qIndex}`,staticClass:"question-list-group pointer",class:[
            `field-item pr-2 pl-2 ${qItem.NAME === _vm.currentDataName && 'active'}`,
            qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'navigation-active' : '',
            _vm.fixedCheck(qItem.QNUM) ? 'fixed-active' : 'list-items',
          ],attrs:{"id":`make-${qItem.NAME}`,"data-name":qItem.NAME},on:{"mouseenter":function($event){return _vm.questionHover(qItem.QNUM)},"mouseleave":function($event){_vm.questionHoverComputed = ''}}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center question-list-title",on:{"click":function($event){return _vm.redirectQuestion(qItem.NAME)}}},[_c('img',{staticClass:"custom-pr-10",attrs:{"src":_vm.$question.questionTypeIcon(qItem),"alt":"아이콘","loading":"lazy"}}),_c('span',{staticClass:"d-flex align-items-center"},[_c('font',{staticClass:"custom-mr-6 make-qnum",class:_vm.fixedCheck(qItem.QNUM) && 'fixed-qnum',attrs:{"color":"pointColor","size":"10","weight":"semiBold"},domProps:{"innerHTML":_vm._s(_vm.convertFixedQuestion(qItem))}}),_c('font',{staticClass:"make-question-navigation-title",class:qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'title-active' : '',attrs:{"color":"textBody","size":"13","weight":"medium"},domProps:{"innerHTML":_vm._s(_vm.removeImageAndSpaceTag(qItem.QUESTION).length > 0 ? _vm.removeImageAndSpaceTag(qItem.QUESTION) : _vm.placeholder)}})],1)]),_c('div',{style:((qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM) &&
              _vm.$question.makeUpdateStatus &&
              _vm.$route.path.includes('/project/make') &&
              !_vm.fixedCheck(qItem.QNUM)
                ? 'display: block'
                : 'display: none')},[_c('b-dropdown',{staticClass:"drop-down-menu pt-2",attrs:{"dropleft":"","no-caret":"","size":"sm","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('menu-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionMove(qItem.NAME)}}},[_vm._v("이동")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionCopy(qItem.NAME)}}},[_vm._v("복사")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionDelete(qItem.NAME)}}},[_vm._v("삭제")])],1)],1)])}),0)],1):_c('section',_vm._l((_vm.tempList),function(qItem,qIndex){return _c('a',{key:`question-${qIndex}`,staticClass:"list-items question-list-group-result",class:[
          `field-item p-2 ${qItem.QNUM === _vm.currentDataName ? 'active' : ''}`,
         _vm.currentDataName === qItem.QNUM ? 'navigation-active' : '',
          _vm.fixedCheck(qItem.QNUM) ? 'fixed-active' : 'list-items',
        ],attrs:{"id":`make-${qItem.QNUM}`,"data-name":qItem.QNUM},on:{"click":function($event){return _vm.redirectQuestion(qItem.QNUM)},"mouseenter":function($event){return _vm.questionHover(qItem.QNUM)},"mouseleave":function($event){_vm.questionHoverComputed = ''}}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center question-list-title"},[_c('img',{staticClass:"custom-pr-10",attrs:{"src":_vm.$question.questionTypeIcon(qItem),"alt":"아이콘","loading":"eager"}}),_c('span',{staticClass:"d-flex align-items-center pointer"},[_c('font',{staticClass:"custom-mr-6 make-qnum",class:_vm.fixedCheck(qItem.QNUM) ? 'fixed-qnum' : '',attrs:{"color":"pointColor","size":"10","weight":"semiBold"},domProps:{"innerHTML":_vm._s(qItem.QNUM || qItem.NAME)}}),_c('font',{staticClass:"make-question-navigation-title",class:qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'title-active' : '',attrs:{"color":"textBody","size":"13","weight":"medium"},domProps:{"innerHTML":_vm._s(_vm.removeImageAndSpaceTag(qItem.QUESTION).length > 0 ? _vm.removeImageAndSpaceTag(qItem.QUESTION) : _vm.placeholder)}})],1)])])}),0)],1),_c('alert-modal',{ref:`navigation-alert-modal`,attrs:{"description":'[문항 저장]을 하지 않으면 변경사항이 반영되지 않습니다. <br/> 변경사항을 저장했는지 확인해주세요.',"modal-id":`navigation-alert-modal`,"title":'문항 편집을 종료하시겠습니까?'},on:{"cancel":_vm.cancel,"confirm":_vm.alertConfirm}}),_c('alert-modal',{ref:`navigation-update-alert-modal`,attrs:{"description":'[문항 저장]을 하지 않으면 변경사항이 반영되지 않습니다. <br/> 변경사항을 저장했는지 확인해주세요.',"modal-id":`navigation-update-alert-modal`,"title":'문항 편집을 종료하시겠습니까?'},on:{"cancel":_vm.propListUpdate,"confirm":_vm.updateAlertConfirm}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"question-group-title"},[_c('span',[_vm._v("질문 리스트")])])
}]

export { render, staticRenderFns }