
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class questionUnit extends Vue {
  @Prop() private questions: {
    text: string;
    value: number;
    recode?: number;
    valueText: string;
    type: string;
    show: boolean;
    sub: boolean;
    cursor: boolean;
    parent?: boolean;
  }[];
  @Prop() private questionsTotal: number;

  questionsClick(question) {
    const { cursor, type } = question;
    if (!cursor) return;
    if (question.parent === undefined) {
      question.parent = true;
    } else {
      question.parent = !question.parent;
    }

    const subArray = this.questions.filter((f) => f.type === type && f.sub);
    for (const i of subArray) {
      const index = this.questions.findIndex((f) => f.type === i.type && f.recode === i.recode);
      this.questions[index].show = question.parent;
    }
  }
}
