
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from '@/router';
import { ToastVariant } from '@/utils/ToastEnum';

@Component
export default class SetNewPw extends Vue {
  @Prop({ required: true }) userId!: string;
  newPwd: string;
  rePwd: string;

  constructor() {
    super();
    this.newPwd = '';
    this.rePwd = '';
  }

  async setPassword() {
    if (!this.newPasswordLength || !this.newPasswordLength) {
      return;
    }

    try {
      const sendData = {
        userId: this.userId,
        userPwd: this.newPwd,
      };

      const { data } = await this.axios.post('/user/set-new-password', sendData);
      const { result, message } = data;

      if (result) {
        this.$common.makeToast(message, ToastVariant.SUCCESS, this.$bvToast);
        setTimeout(() => {
          router.replace({ name: 'login' });
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  }

  get newPasswordLength() {
    return this.newPwd.length >= 8;
  }

  get rePassword() {
    return this.newPasswordLength && this.newPwd === this.rePwd;
  }
}
