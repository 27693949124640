
import {Component, Vue} from "vue-property-decorator";
import CardUi from "@/components/ui/card/CardUi.vue";
import CheckCircle from "@/components/icons/common/CheckCircle.vue";

@Component({
  components: {
    CardUi,
    CheckCircle
  }
})
export default class PaymentSuccess extends Vue {

  private redirectEvent() {
    this.$router.replace(`/`);
  }
}
