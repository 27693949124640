
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ILoadDto } from '@/interface/common';
import { SURVEY } from '@/types/survey';
import EventBus from '@/utils/EventBus';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import { IProjectTemplate } from '@/interface/template/template';
import FixedIcon from "@/components/icons/project/FixedIcon.vue";

@Component({
  components: {FixedIcon},
})
export default class SurveySideList extends Vue {
  @Prop({ default: 0 }) readonly height: number;

  isAdmin: string = this.$store.getters.isAdmin;
  loading: boolean = true;
  items: SURVEY.ProjectList = [];
  pageNum: number = 1;
  maxCount: number = 0;
  routerGuardCount: number = 0;

  async created() {
    await this.load();
    this.eventBusController();
  }

  eventBusController() {
    EventBus.$on('reloadRecentEditSurvey', (payload: boolean) => {
      if (payload) this.load();
    });
  }

  async load(): Promise<void> {
    try {
      this.loading = true;
      const sendData = this.createSendData();
      const { data } = await this.axios({
        url: '/project/survey-fix',
        method: 'GET',
        params: sendData,
      });
      const { result, maxCount } = data;
      this.maxCount = maxCount;
      this.items = result;
      this.loading = false;
    } catch (e) {
      console.error(e);
    }
  }

  async getSurveyListCount() {
    return this.maxCount < 3;
  }

  async updateSurveyFix(question: IProjectTemplate): Promise<void> {
    this.loading = true;
    if (!question.surveyFix) {
      const surveyFixed = await this.getSurveyListCount();
      if (!surveyFixed) {
        this.$common.makeToast(ToastMessage.LIMIT_FIXED_SURVEY, ToastVariant.DANGER, this.$bvToast);
        this.loading = false;
        return;
      }
    }

    question.surveyFix = !question.surveyFix;
    const { SNUM, surveyFix } = question;
    try {
      const sendData = {
        TITLE: question.TITLE || question.PLACEHOLDER,
        SNUM,
        surveyFix,
      };

      const { data } = await this.axios({
        url: '/project/survey-fix',
        method: 'PUT',
        data: sendData,
      });
      const { result, maxCount } = data;

      if (result.length > 0) {
        let variant = '';
        const status = question.surveyFix ? '즐겨찾기 등록' : '즐겨찾기 해제';
        question.surveyFix ? (variant = ToastVariant.SUCCESS) : (variant = ToastVariant.WARNING);
        const message = `${question.TITLE} 설문이 ${status}되었습니다`;
        this.$common.makeToast(message, variant, this.$bvToast);
        this.items = result;
        this.maxCount = maxCount;
        this.loading = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  pageNumInit() {
    this.pageNum = 1;
  }

  openProject(data: IProjectTemplate): void {
    const { SNUM } = data;
    const path = `/project/make/${SNUM}`;
    if (this.routerGuardCount < 1) this.$router.push(path);
    this.routerGuardCount++;
  }
  createSendData(): ILoadDto {
    return {
      pageNum: this.pageNum,
    };
  }
}
