
import { Component, Vue } from 'vue-property-decorator';
import FindAccount from '@/components/users/FindAccount.vue';

@Component({
  components: {
    FindAccount,
  },
})
export default class FindInfo extends Vue {
  userId: string = '';
  secureCode: string = '';

  goLogin() {
    this.$router.push('/login');
  }
}
