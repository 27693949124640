
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class QuotaLinkModal extends Vue {
  @Prop({ default: true }) linkType: boolean;

  close() {
    this.$bvModal.hide('quota-link-modal');
  }

  createLink() {
    this.$bvModal.hide('quota-link-modal');
    this.$emit('createLink');
  }
}
