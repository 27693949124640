import {Module} from "vuex";
import socketStore from "@/store/socket";
import {Store} from "@/interface/store";

const downloadEventHandler: Module<Store.Socket.Download, any> = {
  namespaced: true,
  state: {
    downloadState: false
  },
  actions: {
    downloadEventListener: async (context, payload): Promise<void> => {
      const socket = context.getters['socketStore/getSocket'];
      // QUEUE CHECK
      socket.on('downloadCompleted', async (res) => {
        const { result, DOWNLOAD_QUEUE, server } = res;
        if (server === socket.id) {
          const payload = {DOWNLOAD_QUEUE}
          if (result) await context.dispatch('downloadProcess', payload);
        }
      });

      socket.on('xlsxDownload', async res => {
        const {SNUM, result, STATUS, server } = res;
        const nextStep = (socket.id === server) && result && ['MAKING', 'WAIT'].includes(STATUS);
        if (nextStep) {
          setTimeout(async () => await context.dispatch('getDownloadCheck', SNUM), 3000);
        }
      })
    },
    /*
    * =================
    *     다운로드
    * =================
    * */
    // 엑셀 다운로드 이벤트 실행
    xlsxDownload: (context, payload): void => {
      try {
        const socket = context.getters["socketStore/getSocket"];
        context.commit('setDownloadState', true);
        socket.emit('xlsxDownload', {SNUM: payload});
      } catch (e) {
        throw e;
      }
    },
    getDownloadCheck: (context, SNUM: number): void => {
      try {
        const socket = context.getters['socketStore/getSocket'];
        socket.emit('downloadQueueCheck', {SNUM});
      } catch (e) {
        throw e;
      }
    },
    downloadProcess: async (context, payload): Promise<void> => {
      const {DOWNLOAD_QUEUE} = payload;
      if (DOWNLOAD_QUEUE.length == 0) return;
      DOWNLOAD_QUEUE.forEach((queue) => {
        const {SNUM, PATH} = queue;
        if (queue.TYPE === 'COMPLETE') {
          //생성중이거나 대기중
          if (['MAKING', 'WAIT'].includes(queue.STATUS)) {
            setTimeout(() => context.dispatch('getDownloadCheck', SNUM), 3000);
          } else if (['CREATED'].includes(queue.STATUS)) {
            setTimeout(() => {
              const anchor = document.createElement('a');
              anchor.href = `/api/project/data/download?SNUM=${SNUM}&PATH=${PATH}`;
              anchor.download = `test`;
              anchor.target = '_self';
              anchor.click();
              context.commit('setDownloadState', false);
            }, 1000)
          }
        }
      });
    }
  },
  mutations: {
    setDownloadState: (state: Store.Socket.Download, payload: boolean): void => {
      state.downloadState = payload;
    }
  },
  getters: {
    getDownloadState: (state: Store.Socket.Download): boolean => state.downloadState,
  },
  modules: {
    socketStore,
  },
}

export default downloadEventHandler;