
import { Component, Vue } from 'vue-property-decorator';
import { BOARD, IBoardQuestion } from '@/types/board';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/common/ImageUpload.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class QuestionReply extends Vue {
  listId: string = '';
  BOARD: Object = BOARD;
  loading: boolean = true;
  boardQuestion: IBoardQuestion = BOARD.boardQuestionInit();
  boardQuestionList: IBoardQuestion[] = [];
  LAST_LEVEL: number = 0;
  ROOT_ID:string = '';
  customToolbar: any[] = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];
  uploadImages: File[] = [];
  fileName = '';
  // dataUrlList: string[] = [];
  path = location.origin + '/uploads/Question/';
  file = [];

  onClickHandler(type: string, options?: string) {
    switch (type) {
      case 'list':
        this.$router.push({ path: '/board/question-list' });
        break;
      case 'read':
        this.$router.push({ path: `/board/question-read/${options}` });
        break;
    }
  }

  created() {
    const { listId } = this.$route.params as { listId: string };
    this.listId = listId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.LAST_LEVEL = question.length-1;
      this.ROOT_ID = question[0].ROOT_ID;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async reply(): Promise<void> {
    try {
      this.loading = true;
      const { STATUS, ANSWER } = this.boardQuestion;
      if (ANSWER.length < 5) {
        this.$common.makeToast(ToastMessage.EMPTY_ANSWER, ToastVariant.DANGER, this.$bvToast);
        return;
      }

      const sendData = {
        STATUS,
        ANSWER,
        LEVEL: this.LAST_LEVEL,
        ROOT_ID: this.ROOT_ID,
      };

      const { data } = await this.axios.put(`/board/QuestionReply/Reply/${this.listId}`, sendData);
      const { result } = data;

      this.loading = false;
      if (result) {
        this.$common.makeToast(ToastMessage.ANSWER_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.push(`/board/question-read/${this.listId}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async upload() {
    let formData = new FormData();
    const { FILES } = this.boardQuestion;
    formData['FILES'] = FILES;
  }

  private imageAppend(data: { file: File }) {
    this.uploadImages.push(data.file);
    this.fileName = data.file.name
  }

  handleImageRemove() {
    this.uploadImages.splice(0, 1);
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const sendData = {
        file,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ['jpg', 'jpeg', 'png'];

    const [image, imageType] = type.split('/');
    if (image !== 'image' || !typeList.includes(imageType)) {
      this.$common.makeToast(ToastMessage.IMAGE_TYPE, ToastVariant.DANGER, this.$bvToast);
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }
}
