
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ResultChartContent extends Vue {
  @Prop({ default: '' }) readonly row: { text: string; key: string; idx: number };

  get questionTitle(): string {
    return this.$common.removeImageTag(this.row.text)
  }
}
