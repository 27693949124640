
import { Component, Vue } from 'vue-property-decorator';
import { BOARD, IBoardQuestion } from '@/types/board';
import BoardDeleteModal from '@/components/modal/board/BoardDeleteModal.vue';

@Component({
  components: {
    BoardDeleteModal,
  },
})
export default class QuestionRead extends Vue {
  listId: string = '';
  loading: boolean = true;
  boardQuestion: IBoardQuestion = BOARD.boardQuestionInit();
  boardQuestionList: IBoardQuestion[] = [];
  boardQuestionLast: IBoardQuestion = BOARD.boardQuestionInit();

  created() {
    const { listId } = this.$route.params as { listId: string };
    this.listId = listId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.boardQuestionLast = question[this.boardQuestionList.length-1];
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  statusColor(status: string): string {
    if (status === BOARD.QUESTION_STATUS.CHECKING) return 'warning';
    if (status === BOARD.QUESTION_STATUS.COMPLETE) return 'success';
    else return '';
  }

  onClickHandler(type: string, options?: string) {
    switch (type) {
      case 'list':
        this.$router.push({ path: '/board/question-list' });
        break;
      case 'reply':
        this.$router.push({ path: `/board/question-reply/${options}` });
        break;
      case 'add':
        this.$router.push({ path: `/board/question-write/${this.listId}`});
        break;
      case 'edit':
        this.$router.push(({ path: `/board/question-edit/${this.listId}/${this.boardQuestionLast.LEVEL}`}))
        break;
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
}
