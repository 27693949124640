
import {Component, Vue} from "vue-property-decorator";
import UnisurveyFooter from "@/components/layout/UnisurveyFooter.vue";

@Component({
  components: {
    UnisurveyFooter
  }
})
export default class UnisurveyIndexView extends Vue {

}
