
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionHead extends Vue {
  @Prop({ required: true }) head: string;
  value: string = '';
  label: string = '머릿말';

  created() {
    this.value = this.head;
  }

  private showManual() {
    const url = require('@/assets/images/options/header-title.png');

    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <span class="option-description--text">표의 좌측 상단에 원하는 문구를 삽입할 수 있습니다.</span>
                    <hr />
                    <img alt="머릿말" style='width: 100%;' src="${url}" />
                </div>`,
    };
  }
}
