
import { Component, Prop, Vue } from 'vue-property-decorator';
import Radio from '@/components/project/make/join/Radio.vue';
import RadioSet from '@/components/project/make/join/RadioSet.vue';
import RadioSets from '@/components/project/make/join/RadioSets.vue';
import Check from '@/components/project/make/join/Check.vue';
import CheckSets from '@/components/project/make/join/CheckSets.vue';
import MultiText from '@/components/project/make/join/MultiText.vue';
import TextArea from '@/components/project/make/join/TextArea.vue';
import InfoDesc from '@/components/project/make/join/InfoDesc.vue';
import GradeClick from '@/components/project/make/join/GradeClick.vue';
import InfoTitle from '@/components/project/make/join/InfoTitle.vue';
import QuestionModalMove from '@/components/project/make/question/QuestionModalMove.vue';
import QuestionModalCopy from '@/components/project/make/question/QuestionModalCopy.vue';
import QuestionEdit from '@/components/project/make/question/QuestionEdit.vue';
import '@/assets/css/getdata/common.css';
import '@/assets/css/getdata/getdata.css';
import '@/assets/css/getdata/join.css';
import '@/assets/css/getdata/pure-min.css';
import '@/assets/css/skin/PMI2/index.css';
import type { IQuotaValue } from '@/interface/survey/quota';
import type { ITemplate } from '@/interface/template/template';
import { QUESTION } from '@/types/question';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    Radio,
    RadioSet,
    MultiText,
    InfoDesc,
    GradeClick,
    InfoTitle,
    TextArea,
    RadioSets,
    Check,
    CheckSets,
    QuestionModalMove,
    QuestionModalCopy,
    QuestionEdit,
    TextBoxModal,
  },
})
export default class TemplatePreview extends Vue {
  @Prop() private templateInfo: ITemplate;
  @Prop() private showMain: boolean;
  @Prop() private quotaData: IQuotaValue;
  showing: boolean = false;
  modalTitle: string = '';
  modalDescription: string = '';

  listStyle: Object = {
    position: 'relative',
    'overflow-y': 'scroll',
  };

  questionTypeName(questionType: string): string {
    const type = QUESTION.QUESTION_TYPES_LIST.find((question) => question.value === questionType);
    const { text } = type || { text: '' };
    return text;
  }
  async templateCopy() {
    try {
      const createType = Number(this.$route.params.id); // 1: pmi 패널 대상 2:리스트 대상(알아서)
      const { _id, CATEGORY, TITLE } = this.templateInfo;

      const sendData = {
        QUOTA: {
          RESPONSE_CNT: this.quotaData?.RESPONSE_CNT,
          GENDER: this.quotaData?.GENDER,
          AGE_UNIT: this.quotaData?.AGE_UNIT,
          AGE_10: this.quotaData?.AGE_10,
          AGE_5: this.quotaData?.AGE_5,
          QUOTA_SET: this.quotaData?.QUOTA_SET,
          QUOTA_CNT: this.quotaData?.QUOTA_CNT,
          AREA: this.quotaData?.AREA,
        },
        createType,
      };
      const { data } = await this.axios.post(`/project/template-copy/${_id}`, sendData);
      const { result, sNum } = data;
      if (result === true) {
        this.$common.makeToast(`[${CATEGORY}] ${TITLE} ${ToastMessage.COPY}.`, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.replace(`/project/make/${sNum}`);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async openModal() {
    const { CATEGORY, TITLE } = this.templateInfo;
    this.modalTitle = '템플릿을 사용하시겠습니까?';
    this.modalDescription = `[${CATEGORY}] - ${TITLE}`;
    this.$bvModal.show('text-modal-box');
  }
}
