
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import { QUESTION } from '@/types/question';
import { ITextDenyValue } from '@/interface/survey/question';

interface IRegArrayOption extends IArrayOption {
  tooltip?: boolean;
  imageName?: string;
  exampleText?: string;
}

@Component
export default class OptionRegex extends Vue {
  @Prop({ required: true, default: [] }) textDeny: ITextDenyValue[];
  checkValue: string[] = [];
  label: string = '입력허용';

  options: IRegArrayOption[] = [
    // { text: '없음', value: '-' },
    { text: '한글', value: QUESTION.TEXT_REG.KOR, tooltip: true, imageName: 'kor', exampleText: '[가-힣]' },
    {
      text: '자모음',
      value: QUESTION.TEXT_REG.KOR_CONSONANT,
      tooltip: true,
      imageName: 'kor_consonant',
      exampleText: '[ㄱㄴㄷㄹ]',
    },
    { text: '영문', value: QUESTION.TEXT_REG.ENG, tooltip: true, imageName: 'eng', exampleText: '[a-z, A-Z]' },
    { text: '숫자', value: QUESTION.TEXT_REG.NUM, tooltip: true, imageName: 'num', exampleText: '[0-9]' },
    {
      text: '공백',
      value: QUESTION.TEXT_REG.BLANK,
      tooltip: true,
      imageName: 'blank',
      exampleText: '[\\s, 스페이스키]',
    },
    {
      text: '특수문자',
      value: QUESTION.TEXT_REG.SPECIAL_CHAR,
      tooltip: true,
      imageName: 'special_char',
      exampleText: '[@\\#$%&=]',
    },
  ];

  created() {
    if (this.textDeny.length === 0) {
      this.checkValue = ['-'];
    } else {
      this.checkValue = this.textDeny.map((item) => item.REG);
    }
  }
  /**
   * 입력제한 예시 팝업
   * @param index
   */
  popoverPreviewReg(index: number) {
    const item: IRegArrayOption = this.options[index];
    const { tooltip = false, imageName = '', exampleText = '', text } = item;

    return !tooltip
      ? { disabled: true }
      : {
          html: true,
          delay: {
            show: 100,
          },
          placement: 'top',
          title: () => {
            return `<font>[${text}]입력제한 예시</font>`;
          },
          content: `<font>${exampleText}</font>`,
        };
  }

  messageReg(): string {
    const message = this.options
      .filter((item) => this.checkValue.indexOf(String(item.value)) > -1)
      .map((item) => item.text)
      .join(', ');
    return `응답자는 본 문항에서 “${message}“ 입력이 제한됩니다.`;
  }

  invalidReg(): boolean {
    return this.checkValue.indexOf('-') !== -1;
  }

  active(value): boolean {
    return !this.checkValue.includes(value)
  }


  regCheck(change): void {
    const choiceValue = change[change.length - 1];
    //미사용을 풀 수 없도록
    if (choiceValue === undefined) {
      this.checkValue = ['-'];
    } else {
      const notIndex = this.checkValue.indexOf('-');
      if (notIndex > 0) {
        this.checkValue = ['-'];
      } else if (notIndex > -1 && this.checkValue.length > 1) {
        //미사용 선택하고 다른 것 선택하면 미사용은 체크 풀어줌
        this.checkValue.splice(notIndex, 1);
      }
    }

    const textDeny: ITextDenyValue[] =
      this.checkValue.indexOf('-') > -1
        ? []
        : this.checkValue.map((value) => {
            const option: IArrayOption = this.options.find((item) => item.value === value) || { text: '', value: '' };
            const { text } = option;
            return {
              REG: value,
              MSG: `${text}은/는 응답 할 수 없습니다`,
              FLAG: '',
            };
          });
    this.$emit('set-data', textDeny);
  }

  private showManual() {
    const url = require('@/assets/images/options/entered_limit.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>입력 형식을 제한할 수 있습니다.</b></h1>
                    <br/>
                    <small class="option-description--text">( 입력 제한에서 "한글" 선택 시 "한글" 입력이 제한됩니다. )</small>
                    <img style='width: 100%;' src="${url}" alt="입력제한" />
                </div>`,
    };
  }
}
