
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import SurveyOption from '@/components/modal/make/option/SurveyOption.vue';
import ThemeOption from '@/components/modal/make/option/ThemeOption.vue';
import type { IProjectConfig, IProjectValue, IQuestion } from '@/interface/survey/question';
import HelpPopup from '@/components/board/HelpPopup.vue';
import { QUESTION } from '@/types/question';
import _ from 'lodash';
import CrossTableDownloadModal from '@/components/modal/result/CrossTableDownloadModal.vue';
import EventBus from '@/utils/EventBus';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import MenuTabs from "@/components/project/MenuTabs.vue";

@Component({
  components: {
    MenuTabs,
    SurveyOption,
    ThemeOption,
    CrossTableDownloadModal,
    HelpPopup,
  },
})
export default class ProjectTopMenu extends Vue {
  @Prop() singleView: boolean;
  @Prop({ required: true, default: -1 }) projectId: number;
  @Prop() edit: boolean;
  @Prop({default: false}) titleCheckProp?: boolean;
  @Prop({default: false}) downloadButtonHide: boolean;

  @Ref() questionTitle;
  inputWarning = false;
  showTime: boolean = false;
  propSingleView: boolean = true;
  $refs: Vue['$refs'] & {
    helpPopup: HelpPopup;
    themeOption: ThemeOption;
    surveyOption: SurveyOption;
  };
  surveyInfo: IProjectValue = QUESTION.defaultSurveyInfo;
  originInfo: IProjectValue = QUESTION.defaultSurveyInfo;
  previewUrl: string = '';
  titleClick: boolean = false;

  surveyLists: IQuestion[] = [];

  linkConfirmation: number = 0;
  isNoResult = false;
  loading: boolean = true;
  download = false;

  created(): void {
    this.load();
    this.propSingleView = false;
  }


  @Watch('singleView')
  watchSingleView() {
    this.propSingleView = this.singleView;
  }

  /**
   * @description: 제목이 비어있는지 확인
   * MAKE 에서도 제목이 비어있는지 확인을 해야하기 때문에 prop을 감시
   */
  @Watch('titleCheckProp')
  check() {
    if (this.titleCheckProp) {
      const emptyCheck = this.surveyInfo.TITLE!.length <= 0;
      const lengthCheck = (this.surveyInfo.TITLE!.length < 3 && this.surveyInfo.TITLE!.length > 0);

      this.inputWarningMsg = emptyCheck || lengthCheck;
      if (emptyCheck) {
        this.$nextTick(() => this.questionTitle.focus())
        this.$common.makeToast(ToastMessage.EMPTY_SURVEY_TITLE, ToastVariant.DANGER, this.$bvToast);
      }
      if (lengthCheck) {
        this.$nextTick(() => this.questionTitle.focus())
        this.$common.makeToast(ToastMessage.SHORT_SURVEY_TITLE, ToastVariant.DANGER, this.$bvToast);
      }
      return this.$emit('empty-check', this.inputWarningMsg);
    }
  }

  changeSingleView() {
    this.$emit('changeSingleView', this.propSingleView);
  }

  openPreview(): void {
    const win = window.open(this.previewUrl);
    win?.localStorage.clear();
    win?.location.reload();
  }

  loadingFalse() {
    this.loading = false;
  }

  async load(): Promise<void> {
    try {
      this.loading = true;
      const { data } = await this.axios.get(`/project/info/${this.projectId}`);
      const { info, testUrl } = data;
      const { DATA, CONFIG, LAST_MSG, TITLE, PLACEHOLDER } = info;
      this.surveyLists = DATA;
      if (this.surveyLists.length) {
        this.surveyLists = this.surveyLists.filter(
          (item) =>
            QUESTION.HIDE_QUESTIONS.indexOf(item.NAME) === -1 ||
            item.NAME === 'AGE' ||
            QUESTION.FIXED_QUESTIONS.indexOf(item.NAME) > -1
        );
      }

      this.previewUrl = testUrl + '&grpid=TEST';
      this.surveyInfo.CONFIG = CONFIG;
      this.surveyInfo.LAST_MSG = LAST_MSG;
      this.surveyInfo.TITLE = TITLE;
      this.surveyInfo.PLACEHOLDER = PLACEHOLDER;
      this.linkConfirmation = this.surveyInfo.CONFIG.LINK_STATUS!;

      if (LAST_MSG === undefined) {
        this.surveyInfo.LAST_MSG = {
          TXT_COMPLETE: '',
          TXT_OVER: '',
          TXT_OUT: '',
          TXT_BAD: '',
        };
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.originInfo = _.cloneDeep(this.surveyInfo); // 원본 복사
      this.loading = false;
    }
  }

  get inputWarningMsg() {
    return this.inputWarning
  }

  set inputWarningMsg (payload: boolean) {
    this.inputWarning = payload;
  }

  async saveTitle(): Promise<void> {
    if (!this.edit) {
      this.$common.makeToast(ToastMessage.LINK_CONFIRMATION, ToastVariant.DANGER, this.$bvToast);
      return;
    }

    if (this.originInfo.TITLE !== this.surveyInfo.TITLE) {
      if (this.surveyInfo.TITLE!.length <= 0) {
        this.inputWarningMsg = true;
      } else {
        this.showTime = true;
        this.inputWarningMsg = false;
        await this.saveOption();
      }
    }
    this.titleClick = false;
  }

  async saveOption(config?: IProjectConfig): Promise<boolean> {
    try {
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_START);

      // 링크를 확정했을 경우 옵션 사용
      if (!this.edit) {
        this.$common.makeToast(ToastMessage.LINK_CONFIRMATION, ToastVariant.DANGER, this.$bvToast);
        this.$refs.themeOption.close();
        this.$refs.surveyOption.close();
        return false;
      }

      if (config) this.surveyInfo.CONFIG = config;
      const { data } = await this.axios({
        url: `/project/info/${this.projectId}`,
        method: 'PUT',
        data: { ...this.surveyInfo },
      });

      const { result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.UPDATE, ToastVariant.SUCCESS, this.$bvToast);
        this.$refs.themeOption.close();
        this.$refs.surveyOption.close();
      }
      return result;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      await this.load();
      EventBus.$emit(QUESTION.EVENT_FUNCTION.LOADING_END);
    }
  }

  surveySave(info: IProjectValue) {
    this.surveyInfo = info;
    this.saveOption();
  }
}
