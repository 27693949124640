import { render, staticRenderFns } from "./VerifyFail.vue?vue&type=template&id=4a89dab3&scoped=true"
import script from "./VerifyFail.vue?vue&type=script&lang=ts"
export * from "./VerifyFail.vue?vue&type=script&lang=ts"
import style0 from "./VerifyFail.vue?vue&type=style&index=0&id=4a89dab3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a89dab3",
  null
  
)

export default component.exports