
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import EventBus from '@/utils/EventBus';

@Component
export default class OptionMinMax extends Vue {
  @Prop({ required: true, default: '' }) min: string;
  @Prop({ required: true, default: '' }) max: string;
  @Prop({ required: false, default: '최대 : ' }) maxLabel: string;
  @Prop({ required: false, default: '최소 : ' }) minLabel: string;
  @Prop({ required: true, default: 0 }) typeFlag: number;
  @Prop() defaultMaxCount?: number;

  @Ref() readonly maxLength!: HTMLElement;

  minValue: string = '';
  maxValue: string = '';
  maxCount: number = 3;

  created() {
    this.minValue = this.min;
    this.maxValue = this.max;
  }

  setData() {
    const min = this.$common.replaceOnlyNumber(this.minValue);
    const max = this.$common.replaceOnlyNumber(this.maxValue);
    this.$emit('set-data', { min, max });
  }

  private showResponseManual() {
    const min = require('@/assets/images/options/min-response.png');
    const max = require('@/assets/images/options/max-response.png');
    const grade = require('@/assets/images/options/min-max-grade.png');

    return {
      html: true,
      delay: {
        show: 400,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>[최소 응답]</b> 사용자가 설정한 개수 이상의 응답을 해야만 다음 문항으로 진행 가능하도록 제한합니다.</h1>
                    <br />
                    <small class="option-description--text">(예: 최소 응답 개수에 3 입력시 보기를 3개 이상 선택해야만 다음으로 진행 가능)</small>
                    <hr />
                    <img alt="최소응답" style='width: 100%;' src="${min}" />
                    <hr />
                     <img alt="순위형 최소 응답" style='width: 100%;' src="${grade}" />
                    <h1 class="option-description--text"><b>[최대 응답]</b> 사용자가 설정한 개수만 응답을 할 수 있도록 제한합니다.</h1>
                    <br />
                    <small class="option-description--text">(예: 최소 응답 개수에 3 입력시 보기를 3개까지만 선택 가능)</small>
                    <img alt="최대 응답" style='width: 100%;' src="${max}" />
                </div>`,
    };
  }

  private showTextManual() {
    const min = require('@/assets/images/options/min-text.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>[최소 글자 수]</b> 사용자가 설정한 글자 수 이상의 응답을 입력해야만 다음 문항으로 진행 가능하도록 제한합니다.</h1>
                     <br />
                    <small class="option-description--text">(예: 최소 글자 수에 30 입력 시 응답을 30자 이상 입력해야만 다음으로 진행 가능)</small>
                    <hr />
                    <img alt="최소 글자" style='width: 100%;' src="${min}" />
                    <hr />
                    <h1 class="option-description--text"><b>[최대 글자 수]</b> 사용자가 설정한 글자 수 만큼만 응답할 수 있도록 제한합니다.</h1>
                     <br />
                    <small class="option-description--text">(예: 최대 글자 수에 30 입력 시 응답은 30자까지만 작성 가능하며 설정한 글자 수 까지만 저장됩니다. )</small>
                </div>`,
    };
  }
}
