
import {Component, Prop, Vue} from "vue-property-decorator";

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonVariant =
  'primary' |
  "primary-outline" |
  'secondary' | 'secondary-outline' | 'secondary-rounded' |
  'black' |
  'gray' |
  'red' |
  'icon-button' |
  'option'
  ;

@Component
export default class Button extends Vue {
  @Prop({default: 'button'}) type?: ButtonType;
  @Prop({default: 'primary'}) variant?: ButtonVariant;
  @Prop({default: '244'}) width?: string;
  @Prop({default: '0'}) height?: string;
  @Prop({default: false}) disabled?: boolean;
  @Prop({
    type: Function,
    default: () => {return;}
  }) click: () => void

  /**
   * @description: Button Click Event
   */
  clickEvent() {
    this.click();
  }

  get ButtonWidth() {
    return `width-${this.width}`;
  }

  get ButtonHeight() {
    return `height-${this.height}`;
  }

  get ButtonDisabled() {
    return this.disabled;
  }

  get ButtonType() {
    return this.type;
  }

  get ButtonVariant() {
    return this.variant;
  }
}
