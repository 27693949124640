import { Module } from 'vuex';
import { Store } from '@/interface/store';

const makeStore: Module<Store.Make, any> = {
  namespaced: true,
  state: {
    fileLists: [],
    questionFile: [],
  },
  mutations: {},
  actions: {
    addFile(context, payload) {
      const {state} = context;
      state.fileLists.push(payload)
    },
    addQuestionFile(context, payload) {
      const {state} = context;
      state.questionFile = payload;
    },
    fileStateReset(context) {
      const {state} = context;
      state.fileLists = [];
      state.questionFile = [];
    }
  },
  getters: {
    getFileLists: (state: Store.Make) => state.fileLists,
    getQuestionFileLists: (state: Store.Make) => state.questionFile,
  },
};

export default makeStore;