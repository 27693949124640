
import { Component, Prop } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import type { IAnswerValue } from '@/interface/survey/answer';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionKeyShow from '@/components/project/make/options/common/OptionKeyShow.vue';
import OptionGradation from '@/components/project/make/options/common/OptionGradation.vue';
import { QUESTION } from '@/types/question';
import draggable from 'vuedraggable';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";
import {Properties, UploadPayload} from "@/interface/make/properties";
import {MakeMixins, MakeSurveyTemplate} from "@/mixins/project/MakeMixins";
import {mixins} from "vue-class-component";

@Component({
  components: {
    draggable,
    OptionDescription,
    OptionSkipNotSkip,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionKeyShow,
    OptionGradation,
    OptionQuestion,
    QuestionImageUploader,
  },
})
export default class OptionRadioSet extends mixins(MakeMixins) implements MakeSurveyTemplate {
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  noneCommFlag: boolean = false;
  mouseEvent: number = 0;
  valueMaxError: boolean = false;

  /**파일 관련 변수*/

  created() {
    const noneCommIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.NONE_COMM_KEY) || -1;
    if (noneCommIndex > -1) this.noneCommFlag = true;
    if (!this.data.BTN_HIDE) this.data.BTN_HIDE = '';
  }

  resize(e: Event) {
    const target = e.target as HTMLInputElement;
    target.style.height = '1px';
    target.style.height = `${target.scrollHeight + 1}px`;
  }

  private async imageAppend(data:UploadPayload<string, File>): Promise<void> {
    const {dataUrl, file, index} = data as {dataUrl: string, file: File, index: number};
    const sizeCheck = this.fileSizeCheck(file)
    if (sizeCheck) return;
    await this.singleQuestionImageUpload(data);
    if (this.data.ANSWERS?.length) {
      this.plainText = this.data.ANSWERS[index].V;
      this.data.ANSWERS[index].CREATE = false;
      this.data.ANSWERS[index].V = this.$common.imageTagGenerator(dataUrl) + this.plainText;
      this.data.ANSWERS[index].FILE = {origin: file, dataUrl: dataUrl }
    }
  }

  private removeFile(index: number): void {
    if (this.data.ANSWERS) {
      this.plainText = this.data.ANSWERS[index].V;
      delete this.data.ANSWERS[index].FILE;
      this.data.ANSWERS[index].IMG_LOCATION = '';
      this.data.ANSWERS[index].V = this.$common.imageTagGenerator('') + this.plainText;
    }
    this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
  }

  // 처음 생성됐을 경우 focus 될 경우 초기값 지우기
  removeContent(aItem: IAnswerValue): void {
    if (aItem?.CREATE) {
      aItem.V = '';
      aItem.CREATE = false;
    }
  }

  set mouseHoverComputed(type) {
    this.mouseEvent = type;
  }
  get mouseHoverComputed() {
    return this.mouseEvent;
  }

  mouseHover(type) {
    this.mouseEvent = type;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  keyShowData(data: boolean) {
    this.data.KEY_SHOW = data;
  }

  backgroundData(data: string) {
    this.data.BACKGROUND = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  /**
   * 기타, 없음 있는지 여부 판단
   * @param key
   */
  etcNotCheck(key: string) {
    return key === QUESTION.ANSWERS.NONE_COMM_KEY;
  }

  /**
   * 의견 없음음 보기 추가 값 변경될때 추가 및 삭제 하는 함수
   */
  async notChange(): Promise<void> {
    const notAnswer = {
      K: QUESTION.ANSWERS.NONE_COMM_KEY,
      V: QUESTION.ANSWERS.NONE_COMM_TEXT,
      N: '',
      SINGLE: false,
      DIVIDER: false,
    };
    if (this.noneCommFlag) {
      this.data.ANSWERS?.push(notAnswer);
    } else {
      const notIndex = this.data.ANSWERS?.findIndex((item) => item.K === QUESTION.ANSWERS.NONE_COMM_KEY) || -1;
      this.data.ANSWERS?.splice(notIndex, 1);
    }
  }

  /**
   * 보기 삭제 및 추가
   * @param index
   * @param add
   */
  answersControl(index: number, add: boolean): void {
    this.valueMaxError = false;
    if (!add) {
      // 보기 삭제
      this.$question.removeAnswer(this.data.ANSWERS, index);
    } else if (add) {
      //보기 추가
      if (this.data.ANSWERS && this.data.ANSWERS.length >= this.$data.setsValueMax) {
        this.valueMaxError = true;
        return;
      }
      this.$question.addAnswer(this.data.ANSWERS, index);
    }
  }

  /**
   * 설문 종료 변경 이벤트
   * @param index
   */
  outChange(index: number) {
    const { ANSWERS = [] } = this.data;
    const answer = ANSWERS[index];
    answer.N = answer.N === 'OUT' ? '' : 'OUT';
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }
}
