
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionPageEnd extends Vue {
  @Prop({ required: true, default: true }) pageEnd: boolean;
  radioValue: boolean = false;
  label: string = '다음 문항과 함께 표시';
  options: IArrayOption[] = [
    { text: '단독', value: true },
    { text: '함께', value: false },
  ];

  created() {
    this.radioValue = this.pageEnd;
  }

  answerCheckChange() {
    this.radioValue = !this.radioValue;
    this.$emit('set-data', this.radioValue);
  }

  private showManual(radioValue) {
    const singleUrl = require('@/assets/images/options/present-single.png');
    const togetherUrl = require('@/assets/images/options/present-together.png');
    let content = '';
    if (radioValue) {
      content = `<div>
                     <h1 class="option-description--text"><b>[함께]</b> 선택 시, 다음 문항과 현재 편집중인 문항이 한 페이지에 함께 제시됩니다.</h1>
                     <br/>
                     <h6 class="option-description--text">한 페이지에 한 개의 문항만 제시하고 싶으면 <b>[단독]</b>을 선택해 주세요.</h6>
                     <hr />
                     <b class="option-description--text-blue mt-2">선택 옵션 : [ 단독 ]</b>
                     <img alt="단독제시" style='width: 100%; height: 70%;' src="${singleUrl}" />
                 </div>`;
    } else {
      content = `<div>
                     <h1 class="option-description--text"><b>[함께]</b> 선택 시, 다음 문항과 현재 편집중인 문항이 한페이지에 함께 제시됩니다.</h1>
                     <br/>
                     <h6 class="option-description--text">한 페이지에 한 개의 문항만 제시하고 싶으면 <b>[단독]</b>을 선택해 주세요.</h6>
                     <hr />
                     <b class="option-description--text-blue mt-2">선택 옵션 : [ 함께 ]</b>
                     <img alt="함께제시" style='width: 100%;' src="${togetherUrl}" />
                </div>`;
    }
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: content,
    };
  }
}
