import { Module } from 'vuex';
import {Store} from '@/interface/store';
import {expiredTime} from "@/constants";

const utilStore: Module<Store.Utils, any> = {
  namespaced: true,
  state: {
    timer: null,
    time: 0,
  },
  mutations: {},
  actions: {
    // 이벤트 실행 안함 (GS 인증용)
    addTimerEvent({state}) {
      state.timer = setInterval(() => {
        state.time += 1;
        if (state.time === expiredTime) {
          alert('세션이 만료되었습니다.');
          window.location.replace('/logout')
        }
      }, 1000);
      document.addEventListener('mousemove', () => (state.time = 0));
      document.addEventListener('keypress', () => (state.time = 0));
    },
    // 이벤트 실행 안함 (GS 인증용)
    removeTimerEvent({ state }) {
      clearInterval(state.timer);
      state.time = 0;
      document.removeEventListener('keypress', () => (state.time = 0));
      document.removeEventListener('mousemove', () => (state.time = 0));
    },
  },
  getters: {
    getTimer: (state: Store.Utils) => state.timer,
  },
};

export default utilStore;