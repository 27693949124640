
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ITemplate } from '@/interface/template/template';
import { TEMPLATE } from '@/types/template';
import Preview from '@/components/project/template/Preview.vue';
import { IQuestionValue } from '@/interface/survey/question';
import EventBus from '@/utils/EventBus';

@Component({
  components: {
    Preview,
  },
})
export default class PreviewTemplateModal extends Vue {
  @Prop() private templateInfo: ITemplate;
  @Prop() private modalFlag: string;
  private templateInfoData: TEMPLATE.Preview = {};
  question: IQuestionValue[] = [];

  async mounted() {
    try {
      const templateId = this.templateInfo._id;
      const requestUrl = `/template/detail/${templateId}`;
      const { data } = (await this.axios.get(requestUrl)) as { data: { result: boolean; getData: ITemplate } };
      this.templateInfoData = data.getData;
      this.question = this.templateInfoData.DATA || [];
    } catch (e) {
      console.log(e);
    }
  }

  changeModal() {
    this.closeModal();
    const id = this.templateInfo._id;
    this.$bvModal.show('bv-modal');
    EventBus.$emit('sendTemplateId', id);
  }

  closeModal(): void {
    this.$bvModal.hide(`preview-template-modal-${this.modalFlag}-${this.getTemplateId}`);
  }

  get getTemplateId(): number {
    return this.templateInfo._id;
  }
}
