
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChangeNickname from '@/components/users/profile/update/ChangeNickname.vue';
import ChangePassword from '@/components/users/profile/update/ChangePassword.vue';

@Component({
  components: {
    ChangeNickname,
    ChangePassword,
  },
})
export default class UpdateModal extends Vue {
  @Prop({ default: '' }) modalType: string;
  type: string = '';
  // 모달 생성시 컴포넌트 변경
  created() {}

  @Watch('modalType')
  changeModalType() {
    this.modalTypeComputed = this.modalType;
  }
  //모달 타입 변경
  private set modalTypeComputed(modalType: string) {
    this.type = modalType;
  }
  private get modalTypeComputed() {
    return this.type;
  }

  private get dynamicComponent() {
    switch (this.modalTypeComputed) {
      case 'nickname': {
        return ChangeNickname;
      }
      case 'password': {
        return ChangePassword;
      }
      default: {
        break;
      }
    }
  }
}
