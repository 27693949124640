
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionNumInRow from '@/components/project/make/options/common/OptionNumInRow.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import OptionLeftCategory from '@/components/project/make/options/common/OptionLeftCategory.vue';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionColor from '@/components/project/make/options/common/OptionColor.vue';
import { VueEditor } from 'vue2-editor';
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

@Component({
  components: {
    OptionDescription,
    OptionSkipNotSkip,
    OptionColor,
    OptionPageEnd,
    OptionBtnHide,
    OptionNumInRow,
    OptionRandom,
    OptionLeftCategory,
    VueEditor,
  },
})
export default class OptionTitle extends Vue {
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;
  @Ref() titleEditor: any;
  titleLengthOver = false;
  customToolbar: any[] = [['italic', 'underline'], [{ color: [] }], ['clean']];

  mounted() {
    this.editorInit();
  }

  editorInit() {
    const editor: NodeListOf<HTMLElement> = document.querySelectorAll('.desc-editor');
    editor.forEach((item) => {
      const div: HTMLElement = item.querySelector('div.ql-editor') || document.createElement('div');
      div.style.minHeight = '0px';
    });
  }
  optionData() {
    const replaceText = this.data.HTML?.replace(/<[^>]*>?/g, '')
    if (replaceText && replaceText.length > 0){
      const quill = this.titleEditor.quill;
      const size = quill.getLength()

      if (size > 3000) {
        this.titleLengthOver = true;
        quill.deleteText(3000, size);
      } else {
        this.titleLengthOver = false;
      }
    }
  }

  replaceStrongTag() {
    this.data.HTML = (this.data.HTML || '').replace(/<strong/gi, '<b').replace(/strong>/gi, 'b>');
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  backgroundData(data: string) {
    this.data.BG = data;
  }
}
