import { render, staticRenderFns } from "./OptionVWidth.vue?vue&type=template&id=cf7df598&scoped=true"
import script from "./OptionVWidth.vue?vue&type=script&lang=ts"
export * from "./OptionVWidth.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf7df598",
  null
  
)

export default component.exports