
import { Component, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class WithdrawalReason extends Vue {
  isLoading = false;
  reason: string = '';
  reasonText: string = '';

  userId = this.$store.getters.userId;
  userName = this.$store.getters.userName;
  userNickname = this.$store.getters.userNickname;

  // 최종 회원 탈퇴 시 안내문구 보여지기기
  private async messageBoxMaker(): Promise<any> {
    const h = this.$createElement;
    const message = h('p', {
      domProps: {
        innerHTML:
          '<h6><strong>' +
          this.userNickname +
          '</strong>님의 회원 탈퇴 처리가 완료 되었습니다.</h6> <br />' +
          '<h6>유니서베이와 함께 해주셔서 감사합니다.</h6> <br />' +
          '<h6>다시 한 번 만날 수 있도록 준비하겠습니다.</h6>',
      },
    });
    return await this.$bvModal.msgBoxOk([message], {
      title: '',
      buttonSize: 'sm',
      okVariant: 'primary',
      okTitle: '확인',
      cancelTitle: '취소',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    });
  }

  reasonTextReset() {
    setTimeout(() => {
      if (this.reason !== '97') this.reasonText = '';
    }, 1);
  }

  private emptyCheck(): boolean {
    if (!this.reason.length) {
      this.$common.makeToast(ToastMessage.NOT_CHOICE_REASON, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.reason === '97' && !this.reasonText.length) {
      this.$common.makeToast(ToastMessage.NOT_WRITE_REASON, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  private withdrawProgress() {
    this.isLoading = true;

    if (this.emptyCheck()) {
      this.$root.$emit('bv::hide::modal', 'bv-modal');
      setTimeout(async () => {
        await this.messageBoxMaker();
        await this.$store.dispatch('logOut', { historyId: this.getHistoryId });
        await this.withdraw();
        await this.$router.push('/login');
      }, 300);
    } else {
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  //회원 탈퇴 - 사유 저장
  private async withdraw() {
    try {
      const sendData = {
        userId: this.userId,
        reason: this.reason,
        reasonText: this.reasonText,
      };
      await this.axios.post('/user/withdraw', sendData);
    } catch (e) {
      console.log(e)
    }
  }

  get getHistoryId() {
    return this.$store.getters["historyId"];
  }
}
