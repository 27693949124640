
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionKeyShow extends Vue {
  @Prop({ required: true }) keyShow: boolean;
  radioValue: boolean = false;
  label: string = '보기 번호와 함께 제시';
  options: IArrayOption[] = [
    { text: '미제시', value: false },
    { text: '제시', value: true },
  ];

  created() {
    this.radioValue = this.keyShow;
  }

  answerCheckChange() {
    this.radioValue = !this.radioValue;
    this.$emit('set-data', this.radioValue);
  }

  private showManual(radioValue) {
    const hide = require('@/assets/images/options/none-option-number.png');
    const show = require('@/assets/images/options/show-option-number.png');
    let content = '';

    if (!radioValue) {
      content = `<div>
                    <h1 class="option-description--text">보기 내용과 번호를 함께 표시합니다.</h1>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 미제시 ]</b>
                    <img alt="미제시" style='width: 100%;' src="${hide}" />
                </div>`;
    } else {
      content = `<div>
                    <h1 class="option-description--text">보기 내용과 번호를 함께 표시합니다.</h1>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 제시 ]</b>
                    <img alt="제시" style='width: 100%;' src="${show}" />
                </div>`;
    }
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: content,
    };
  }
}
