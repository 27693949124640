
import { ISurveyContent } from '@/interface/survey/question';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class ProjectDeleteModal extends Vue {
  @Prop() projectData!: ISurveyContent;
  isLoading = false;

  init() {
    this.isLoading = false;
  }

  async removeProject() {
    try {
      this.isLoading = true;
      const { SNUM } = this.projectData;
      const { data } = await this.axios.delete(`/project/${SNUM}`);
      const { result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.DELETE_MESSAGE, ToastVariant.SUCCESS, this.$bvToast);
        this.closeDeleteModal();
        this.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }

  closeDeleteModal() {
    this.$bvModal.hide('project-delete-modal');
  }

  @Emit('removeProject')
  reload() {
    return true;
  }
}
