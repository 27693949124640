
import { Component, Vue } from 'vue-property-decorator';
import HelpList from '@/components/board/HelpList.vue';

@Component({
  components: {
    HelpList,
  },
})
export default class QuestionList extends Vue {
  showState: number = 1;
  showing: boolean = false;

  changeState(flag) {
    this.showing = true;
    this.showState = flag;
    this.showing = false;
  }
}
