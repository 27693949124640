export enum STYLE {
  ID_FOCUS = 'id_focus',
  PW_FOCUS = 'password_focus',
  ID_FOCUS_INPUT = 'id_focus_input',
  PW_FOCUS_INPUT = 'password_focus_input',
  LOGIN_SVG_ICON = 'svg-active',
  CHECK_BOX = 'check_box',
  UNCHECK_BOX = 'uncheck_box',
  NEXT_HOVER = 'next_hover',
  NEXT_UNHOVER = 'next_unhover',
  BACKGROUND_DISABLED = 'background_disabled',
  INPUT_DISABLED = 'input-disabled',
  INPUT_ENABLED = '',

  QUESTION_CATEGORY = 'custom-list-item',
}
