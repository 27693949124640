import { render, staticRenderFns } from "./SurveyOption.vue?vue&type=template&id=263e8f92&scoped=true"
import script from "./SurveyOption.vue?vue&type=script&lang=ts"
export * from "./SurveyOption.vue?vue&type=script&lang=ts"
import style0 from "./SurveyOption.vue?vue&type=style&index=0&id=263e8f92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263e8f92",
  null
  
)

export default component.exports