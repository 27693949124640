
import {Component, Vue} from "vue-property-decorator";
import UnisurveyLogo from "@/components/icons/common/UnisurveyLogo.vue";
import PolicyModal from "@/components/modal/PolicyModal.vue";

@Component({
  components: {
    UnisurveyLogo,
    PolicyModal
  }
})
export default class UnisurveyFooter extends Vue {
  showPolicy(type: string) {
    this.$nextTick(() => {
      this.$bvModal.show(type);
    });
  }
}
