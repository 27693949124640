
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionCheck } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';
import { IAnswerValue } from '@/interface/survey/answer';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class Check extends Vue {
  @Prop() private data: IQuestionCheck;
  @Prop() private questionTypeName?: string;

  answers: IAnswerValue[] = [];
  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      ANSWERS = [],
      RANDOM,
      TEXT_DENY,
      NAME,
      LEFT_CATEGORY = false,
      LEFT_CATEGORY_WIDTHS = '',
      LEFT_CATEGORY_ALIGNS = '',
      NUM_IN_ROW = '1',
    } = this.data;

    const answerBox = this.$refs['s-answer'];
    let showCount = 0;
    this.answers = this.$common.rnd(ANSWERS, RANDOM, 'K');
    for (let a = 0; a < this.answers.length; a++) {
      const { K, N, V } = this.answers[a];
      const aKey = K;
      const answer = this.answers[a];
      const rowClass = this.$common.numInRowToGrid('1').split(' ');
      showCount++;

      const unitBox: HTMLElement = this.$question.categoryMakerBox({
        wrapper: answerBox,
        wrapperAddClass: 'no-border',
        wrapperRemoveClass: 'pure-g',
        boxAddClass: 'pure-g',
        qname: NAME,
        leftCategory: LEFT_CATEGORY,
        leftCategoryWidths: LEFT_CATEGORY_WIDTHS,
        leftCategoryAligns: LEFT_CATEGORY_ALIGNS,
        unit: this.answers[a],
        units: this.answers,
      });

      const gridContent = document.createElement('div');
      gridContent.classList.add('answer-unit-box');
      unitBox.appendChild(gridContent);

      const numInRow = NUM_IN_ROW;
      const classArray = this.$common.numInRowToGrid(numInRow).split(' ');

      if (numInRow === '99') {
        answerBox.classList.remove('pure-g');
        gridContent.style.display = 'inline-block';
      } else {
        gridContent.classList.add(...classArray);
      }

      const div = document.createElement('div');
      div.classList.add('s-check');
      gridContent.appendChild(div);

      const label = document.createElement('label');
      label.setAttribute('for', `${NAME}_${K}`);
      label.classList.add('pure-checkbox');
      div.appendChild(label);

      const input = document.createElement('input');
      input.setAttribute('type', `checkbox`);
      input.setAttribute('id', `${NAME}_${K}`);
      input.setAttribute('name', `${NAME}_${K}`);
      input.setAttribute('qname', NAME);
      input.setAttribute('akey', K);
      input.setAttribute('data-column', K);
      input.setAttribute('single', String(answer.SINGLE || ''));
      input.setAttribute('data-y-next', String(answer.Y_GO || ''));
      input.setAttribute('data-n-next', String(answer.N_GO || ''));
      input.value = K;
      label.appendChild(input);

      //2021-06-30 단독선택 무조건 한 줄로 표시
      /*
            if(answer.SINGLE === true){
                gridContent.classList.remove(...classArray);
                gridContent.classList.add(...rowClass);
            }
             */

      //기타 박스 처리
      let html = answer.V;
      const reg = /\[(TEXT|NUMBER)_[\d]+\]/;
      let ord = 1;
      while (html.match(reg)) {
        const matchText = html.match(reg)![0];
        const typeNum = matchText.substr(1, matchText.length - 2).split('_');
        const type = typeNum[0];
        const num = typeNum[1];
        html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
        ord++;
        //2021-06-30 기타가 있으면 무조건 한 줄로 표시
        const etcClass = this.$common.numInRowToGrid('1').split(' ');
        gridContent.classList.remove(...classArray);
        gridContent.classList.add(...etcClass);
      }

      const span = document.createElement('span');
      span.innerHTML = html;
      span.classList.add('check-value');
      label.appendChild(span);

      //기타 값 생성
      span.querySelectorAll('span[type]').forEach((item) => {
        const type = item.getAttribute('type');
        const num = item.getAttribute('num');
        const ord = item.getAttribute('ord');
        const dataColumn = K + '_ETC_' + ord;
        const textInput = document.createElement('input');
        textInput.setAttribute('type', 'text');
        textInput.classList.add('input-etc');
        textInput.style['width'] = `${this.data[type + '_' + num].WIDTH}px`;
        textInput.style['text-align'] = `${this.data[type + '_' + num].ALIGN}`;
        textInput.setAttribute('data-column', dataColumn);
        textInput.setAttribute('disabled', 'disabled');
        textInput.setAttribute('qname', NAME);
        textInput.setAttribute('akey', aKey);
        textInput.setAttribute('parent-name', `${NAME}_${aKey}`);
        textInput.setAttribute('type-name', type + '_' + num);
        textInput.setAttribute('data-object', JSON.stringify(this.data[type + '_' + num]));
        //최대 글자수 제한
        if ((this.data[type + '_' + num].MAX_LENGTH || '') != '') {
          textInput.setAttribute('maxlength', this.data[type + '_' + num].MAX_LENGTH);
        }

        item.appendChild(textInput);
      });
    }
  }

  async initEvent(): Promise<void> {
    //radio 값 변경시 etc 처리
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const checkboxs: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="checkbox"]') || [];
    for (const checkbox of checkboxs) {
      checkbox.addEventListener('click', this.checkboxChangeEtcEvent);
    }
    const etcList: NodeListOf<HTMLElement> = answerBox.querySelectorAll('span[type]') || [];
    for (const etc of etcList) {
      etc.addEventListener('click', this.etcClickCheckboxEvent);
    }
  }

  etcClickCheckboxEvent(evt) {
    const { target } = evt;
    const disabled = target.getAttribute('disabled');
    //console.log(target, disabled);
    if (disabled === 'disabled') {
      const checkbox = target.closest('label').querySelector('input[type="checkbox"]');
      checkbox.setAttribute('checked', 'checked');
      checkbox.click();
    }
  }

  checkboxChangeEtcEvent(evt) {
    const { target } = evt;
    const akey = target.value;
    const name = target.getAttribute('name');
    const checked = target.checked;
    const line = target.closest('.s-check');
    const wrapper = target.closest('div[question-name]');
    const single = target.getAttribute('single');
    const etc = line.querySelectorAll('input.input-etc[parent-name="' + name + '"]');
    etc.forEach((item, idx) => {
      if (target.checked === false) {
        item.value = '';
        item.setAttribute('disabled', 'disabled');
      } else {
        if (item.getAttribute('disabled') === 'disabled') {
          item.removeAttribute('disabled');
          item.focus();
        } else {
          item.removeAttribute('disabled');
        }
      }
    });

    //단독선택 처리
    if (checked === true) {
      //단독보기를 고른 경우 나머지를 체크해제
      if (single === 'true') {
        wrapper.querySelectorAll('input[type=checkbox]:not([akey="' + akey + '"])').forEach((item) => {
          if (item.checked === true) {
            item.checked = false;
            item.dispatchEvent(new Event('click'));
          }
        });
      } else {
        //단독이 아닌 보기를 고른 경우 단독을 체크해제
        wrapper.querySelectorAll('input[type=checkbox]').forEach((item) => {
          const forced = item.getAttribute('forced');
          const single = item.getAttribute('single');
          if (forced == 'true') return;
          if (single === 'true' && item.checked === true) {
            item.checked = false;
            item.dispatchEvent(new Event('click'));
          }
        });
      }
    }

    const singleChecked = wrapper.querySelector('input[single=true]:checked');
    if (singleChecked) {
      wrapper.querySelectorAll('input:not(:checked)').forEach((item) => {
        item.closest('label').querySelector('span').style.opacity = '0.3';
      });
    } else {
      wrapper.querySelectorAll('input[type=checkbox]').forEach((item) => {
        item.closest('label').querySelector('span').style.opacity = '';
      });
    }

    //단독선택이 되어 있다면 나머지 보기는 흐리게
    this.maxCheck(wrapper);
    //wrapper.querySelector('input[type=checkbox]:not(:checked)').closest('.answer-unit-box').classList.remove('answer-unit-selected');
    //wrapper.querySelector('input[type=checkbox]:checked').closest('.answer-unit-box').classList.remove('answer-unit-selected');
  }

  maxCheck(wrapper: HTMLElement): void {
    const maxCheck = Number(this.data.MAX_CHECK || 9999);
    const currentCheck = wrapper.querySelectorAll('input[type=checkbox][data-column]:checked').length;
    if (currentCheck >= maxCheck) {
      //최대 선택개수 이상 선택했으면 선택안한 것들의 체크 불가능하게
      wrapper.querySelectorAll('input[type=checkbox][data-column]:not(:checked)').forEach(function (item) {
        item.setAttribute('disabled', 'disabled');
        const label = item.closest('label');
        if (label) label.style.opacity = '0.3';
      });
    } else {
      //최대 개수만큼 선택하지 않은 경우
      wrapper.querySelectorAll('input[type=checkbox][data-column][disabled]').forEach(function (item) {
        item.removeAttribute('disabled');
        const label = item.closest('label');
        if (label) label.style.opacity = '';
      });
    }
  }
}
