
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionNumInRow extends Vue {
  @Prop({ required: true, default: '1' }) numInRow: string;
  radioValue: string = '1';
  label: string = '줄당 보기 수';
  options: IArrayOption[] = [
    { text: '1', value: '1' },
    { text: '2', value: '2' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
    { text: '6', value: '6' },
  ];

  created() {
    this.radioValue = this.numInRow;
  }

  changeButton(value: string) {
    this.radioValue = value;
    this.$emit('set-data', this.radioValue);
  }

  private showManual() {
    const line1 = require('@/assets/images/options/line_1.png');
    const line2 = require('@/assets/images/options/line_2.png');
    const line3 = require('@/assets/images/options/line_3.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text">한 줄에서 제시될 보기의 수를 설정합니다. ( 기본 설정 값은 1개 입니다. )</h1>
                    <br />
                    <h2 style="color: #35B6E7FF; font-weight: 500; font-size: 13px;">직접 입력을 체크했을 경우 적용되지 않습니다.</h2>
                    <hr />
                    <h2 class="option-description--text-red mt-2">[ 한 줄 당  1개 ]</h2>
                    <img alt="필수응답" style='width: 100%;' src="${line1}" />
                    <hr />
                    <h2 class="option-description--text-blue mt-2">[ 한 줄 당  2개 ]</h2>
                    <img alt="필수응답" style='width: 100%;' src="${line2}" />
                    <hr />
                    <h2 class="option-description--text-blue mt-2">[ 한 줄 당 4개 ]</h2>
                    <img alt="필수응답" style='width: 100%;' src="${line3}" />
                </div>`,
    };
  }
}
