
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { IQuestionValue } from '@/interface/survey/question';
import type { IArrayOption } from '@/interface/common';
import EventBus from '@/utils/EventBus';
import { QUESTION } from '@/types/question';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class QuestionModalMove extends Vue {
  @Prop({ default: '' }) modalId: string;
  @Prop({ default: '' }) question: IQuestionValue;

  cursor: string = '';
  pointer: QUESTION.QUESTION_MOVE_TYPE = QUESTION.QUESTION_MOVE_TYPE.APPEND;
  makeList: IQuestionValue[] = [];
  makeListOptions: IArrayOption[] = [];
  pointerOption: IArrayOption[] = QUESTION.QUESTION_MOVE_OPTION;
  isLoading: boolean = false;

  created() {
    EventBus.$on(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD, this.updateMakeList);
  }

  mounted() {
    this.updateMakeList();
  }

  init() {
    this.isLoading = false;
    this.updateMakeList();
  }

  async close() {
    await this.$bvModal.hide(this.modalId);
    await EventBus.$emit(QUESTION.EVENT_FUNCTION.CHANGE_EDIT_MODE, true);
    await this.$emit('update', true);
  }

  updateMakeList() {
    const makeList = this.$question.getMakeList();
    const { NAME } = this.question;
    const { FIXED_QUESTIONS, QUESTION_MOVE_TYPE } = QUESTION;
    const fixedFirstIndex = makeList.findIndex((item) => item.NAME === FIXED_QUESTIONS[FIXED_QUESTIONS.length - 1]);
    this.makeList = makeList.filter((item, idx) => {
      return item.NAME !== NAME && fixedFirstIndex < idx;
    });

    if (this.makeList.length > 0) {
      this.makeListOptions = this.makeList.map((data) => {
        return { text: `${data.QNUM}`, value: data.NAME };
      });
      this.cursor = this.makeListOptions[0].value as string;
      this.pointer = QUESTION_MOVE_TYPE.APPEND;
    }
  }

  async move() {
    this.isLoading = true;
    let cursor = this.cursor;
    //문항 앞으로인 경우 있는 경우 cursor 문항 앞에걸로 바꾸자
    if (this.pointer === QUESTION.QUESTION_MOVE_TYPE.PREV) {
      const findIndex = this.makeListOptions.findIndex((item) => item.value === this.cursor);
      if (findIndex < 1) {
        const makeList = this.$question.getMakeList();
        const makeIndex = makeList.findIndex((item) => item.NAME === this.cursor);
        cursor = makeList[makeIndex - 1].NAME;
      } else {
        cursor = this.makeListOptions[findIndex - 1].value as string;
      }
    }
    const { NAME } = this.question;
    await this.$question.move(NAME, cursor);
    this.isLoading = false;
    await this.close();
  }
}
