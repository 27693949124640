import {FileUploadPayload, ImageUrlData, Properties, UploadPayload} from "@/interface/make/properties";
import {Component, Vue} from "vue-property-decorator";
import { QUESTION } from '@/types/question';
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

export interface MakeSurveyTemplate {
  setQuestionTitle: (payload: Properties.QuestionTitlePayload) => Promise<void>;
}

@Component
export class MakeMixins extends Vue  {
  protected QUESTION = QUESTION;
  protected plainText = '';
  protected dataUrlList: ImageUrlData[] = [];

  category: {
    C1?: boolean;
    C2?: boolean;
    C3?: boolean;
  } = {
    C1: false,
    C2: true,
    C3: true
  }

  protected dragOptions: Object = {
    animation: 200,
    group: 'radio-answers',
    disabled: false,
    ghostClass: 'ghost',
  };

  private questionFile: Required<FileUploadPayload[]> = [];

  protected initState() {
    this.questionFile = [];
  }

  /**
   * @description: 문항 제목 저장
   * @protected
  */
  protected async questionTitle(payload: Properties.QuestionTitlePayload): Promise<string> {
    const { question, file, dataUrl } = payload;
    this.initState();

    if (file.length > 0) {
      const payload = {
        file,
        dataUrl: dataUrl
      }
      this.questionFile.push(payload);
    }
    await this.$store.dispatch('makeStore/addQuestionFile', this.questionFile);
    return question;
  }

  protected fileSizeCheck(file: File): boolean {
    const {size} = file;
    const maxSize = (1024 ** 2) * 10;
    // 파일 크기 제한 10mb 이하
    if (size > maxSize) this.$common.makeToast(ToastMessage.IMAGE_MAX_SIZE_OVER, ToastVariant.DANGER, this.$bvToast);
    return size > maxSize;
  }

  /**
   * @description: 단수형 문항 이미지 업로드
   * @protected
   */
  protected async singleQuestionImageUpload(data: UploadPayload<string, File>): Promise<void> {
    const {dataUrl, index} = data as {dataUrl: string, file: File, index: number};
    if (this.dataUrlList.length > 0) {
      this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
    }

    const imageData: ImageUrlData = {
      index: index,
      dataUrl: dataUrl
    }
    this.dataUrlList.push(imageData);
  }

  /**
   * @description: 제작 화면 내의 이미지 미리보기 태그
   * @protected
   */
  protected imageTagOptions() {
    return {
      alt: 'question-options-image',
      style: 'object-fit: contain; max-width:500px; max-height: 320px; width: fit-content; height: auto;',
    }
  }


}