
import { Component, Vue } from 'vue-property-decorator';
import { BOARD, IHelp } from '@/types/board';
import HelpDeleteModal from '@/components/modal/board/HelpDeleteModal.vue';

@Component({
  components: {
    HelpDeleteModal,
  },
})
export default class AdminHelpRead extends Vue {
  helpId: string = '';
  tabIndex = 0;
  BOARD: Object = BOARD;
  loading: boolean = false;
  help: IHelp = BOARD.helpInit();
  tag: string = '';

  created() {
    this.helpId = this.$route.params.helpId;
    this.tabIndex = Number(this.$route.query.tabIndex);
    console.log(typeof this.tabIndex, typeof this.$route.query.tabIndex);
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/admin-select-help/${this.helpId}`);
      const { result, help } = data;
      if (result) {
        this.help = help;
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
}
