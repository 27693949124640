
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionMobileUI extends Vue {
  @Prop({ required: true }) mobileUI: boolean;
  radioValue: boolean = false;
  label: string = '모바일 UI 사용 : ';
  created() {
    this.radioValue = this.mobileUI;
  }
  options: IArrayOption[] = [
    { text: '사용', value: true },
    { text: '미사용', value: false },
  ];
}
