import { IArrayOption } from '@/interface/common';
export namespace BOARD {
  export type TQuestionList = IBoardQuestion[];

  export const enum QUESTION_TYPE {
    USER = '가입/회원정보 문의',
    SURVEY = '설문 진행 문의',
    TEMPLATE = '템플릿 문의',
    PAYMENT = '결제 문의',
    ETC_ERROR = '기타/오류',
  }
  export const enum QUESTION_STATUS {
    WAIT = '답변대기중',
    COMPLETE = '답변완료',
    CHECKING = '확인중',
  }
  export const QUESTION_TYPE_OPTION: IArrayOption[] = [
    { text: QUESTION_TYPE.USER, value: QUESTION_TYPE.USER },
    { text: QUESTION_TYPE.SURVEY, value: QUESTION_TYPE.SURVEY },
    { text: QUESTION_TYPE.TEMPLATE, value: QUESTION_TYPE.TEMPLATE },
    { text: QUESTION_TYPE.PAYMENT, value: QUESTION_TYPE.PAYMENT },
    { text: QUESTION_TYPE.ETC_ERROR, value: QUESTION_TYPE.ETC_ERROR },
  ];
  export const QUESTION_STATUS_OPTION: IArrayOption[] = [
    { text: QUESTION_STATUS.WAIT, value: QUESTION_STATUS.WAIT },
    { text: QUESTION_STATUS.COMPLETE, value: QUESTION_STATUS.COMPLETE },
    { text: QUESTION_STATUS.CHECKING, value: QUESTION_STATUS.CHECKING },
  ];

  export const enum HELP_CATEGORY {
    NOTICE = '공지사항',
    SURVEY = '문항유형',
    USE = '사용법',
  }

  export const HELP_CATEGORY_OPTION: IArrayOption[] = [
    { text: HELP_CATEGORY.NOTICE, value: HELP_CATEGORY.NOTICE },
    { text: HELP_CATEGORY.SURVEY, value: HELP_CATEGORY.SURVEY },
    { text: HELP_CATEGORY.USE, value: HELP_CATEGORY.USE },
  ];

  export function boardQuestionInit(): IBoardQuestion {
    return {
      _id: '',
      TITLE: '',
      CONTENTS: '',
      STATUS: BOARD.QUESTION_STATUS.WAIT,
      TAG: [],
      FILES: [],
      REGIST_ID: '',
      REGIST_NAME: '',
      ANSWER: '',
      DT: '',
      UPDATE_NAME: '',
      UPDATE_ID: '',
      CATEGORY: '',
      TYPE: BOARD.QUESTION_TYPE.SURVEY,
      USER_ID: '',
      LEVEL: 0,
      ROOT_ID: '',
    };
  }

  export function helpInit(tabIndex?: number): IHelp {
    return {
      _id: '',
      TITLE: '',
      CONTENTS: '',
      TAG: [],
      FILES: [],
      REGIST_ID: '',
      REGIST_NAME: '',
      DT: '',
      CATEGORY: tabIndex && tabIndex > 0 ? BOARD.HELP_CATEGORY.SURVEY: BOARD.HELP_CATEGORY.NOTICE,
      READ_COUNT: 0,
    };
  }
}

/**
 * 1:1 문의 interface
 */

type FILES = {
  _id: string,
  name: string,
  Location: string
}

export interface IBoardQuestion {
  _id: string;
  TITLE: string;
  CONTENTS: string;
  STATUS: BOARD.QUESTION_STATUS;
  TAG: string[];
  FILES: FILES[];
  REGIST_ID: string;
  REGIST_NAME: string;
  REG_DATE?: string;
  DT: Date | string;
  UPDATE_DT?: Date;
  UPDATE_ID: string;
  UPDATE_NAME: string;
  CATEGORY?: string;
  ANSWER: string;
  TYPE: BOARD.QUESTION_TYPE;
  USER_ID: string;
  LEVEL: number;
  ROOT_ID: string;
}

/**
 * 도움말 인터페이스
 */
export interface IHelp {
  _id: string;
  TAG: string[];
  CATEGORY: BOARD.HELP_CATEGORY;
  FILES: FILES[];
  READ_COUNT: number;
  DT: Date | string;
  REGIST_ID: string;
  REGIST_NAME: string;
  TITLE: string;
  CONTENTS: string;
}
