
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Logout extends Vue {

  get getHistoryId() {
    return this.$store.getters["historyId"];
  }

  async mounted() {
    await Promise.all([
      this.$store.dispatch('logOut', {historyId: this.getHistoryId}),
      this.$store.commit('socketStore/setUserId', ''),
    ]).then(() => {
      this.$router.replace({path: '/', replace: true}).then(() => {
        window.location.reload()
      });
    })
  }
}
