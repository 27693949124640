
import { Component, Emit, Ref, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class WithdrawalInformation extends Vue {
  @Ref() readonly refPassword!: HTMLElement;

  withdrawCheck: boolean = true;
  check1: boolean = false;
  check2: boolean = false;
  password: string = '';
  agree: boolean = true;
  projectCount: number = 0;
  userId: string = this.$store.getters.userId;
  userName: string = this.$store.getters.userName;
  userNickname: string = this.$store.getters.userNickname;

  async created() {
    await this.getProjectListCount();
  }

  // 프로젝트 리스트 합계 구하기
  private async getProjectListCount() {
    const { data } = await this.axios.get(`/user/project/count/${this.userId}`);
    this.projectCount = data;
  }

  canNotAccess() {
    this.check1 = true;
    this.passwordInputPossible = !(this.check1 && this.check2);
    if (this.passwordInputPossible && this.normalUser) this.focusPasswordInput();
  }
  canNotRestore() {
    this.check2 = true;
    this.passwordInputPossible = !(this.check1 && this.check2);
    if (this.passwordInputPossible && this.normalUser) this.focusPasswordInput();
  }
  focusPasswordInput() {
    return setTimeout(() => {
      this.$nextTick(() => this.refPassword.focus());
    }, 600);
  }

  // 패스워드가 입력됐을 경우에만 다음 버튼 활설화
  private nextButtonActivate() {
    this.passwordInputCheck = this.password.length <= 7;
  }

  // 위 사항을 확인했고 비밀번호가 정보와 일치하다면 통과
  private async nextCheck() {
    if (!this.password.length && this.normalUser) {
      this.$common.makeToast(ToastMessage.CHECK_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      return;
    }
    if ((this.check1 && this.check2) || !this.normalUser) {
      if (!this.normalUser) this.nextProgress();
      else {
        const result = await this.userInfoCheck();
        if (result) this.nextProgress();
        else this.password = '';
      }
    } else {
      this.$common.makeToast(ToastMessage.FIGURES_CHECK, ToastVariant.DANGER, this.$bvToast);
    }
  }

  private async userInfoCheck(): Promise<boolean> {
    const sendData = {
      userId: this.$common.rsaEncrypt(this.userId),
      userPwd: this.$common.rsaEncrypt(this.password),
    };
    const { data } = await this.axios.post('/user/login', sendData);
    const { result } = data;
    return result;
  }

  //다음 화면으로 전환
  @Emit('nextProgress')
  private nextProgress() {
    return 'reason';
  }
  // 비밀번호 입력시 다음 버튼 활성화
  private get passwordInputCheck() {
    return this.agree;
  }
  private set passwordInputCheck(agree: boolean) {
    this.agree = agree;
  }
  // 위 조건 확인시 비밀번호 입력칸 활설화
  private get passwordInputPossible() {
    return this.withdrawCheck;
  }
  private set passwordInputPossible(type: boolean) {
    this.withdrawCheck = type;
  }

  get normalUser() {
    return this.$store.getters.loginType === 'NORMAL';
  }
}
