
import { Bootpay } from '@bootpay/client-js';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IKeyValueSetting, IQuotaInterValue, IQuotaValue } from '@/interface/survey/quota';
import { QUOTA } from '@/types/quota';
import SkeletonTable from '@/components/main/create/sample/skeletonTable.vue';
import { QUESTION } from '@/types/question';
import LINK_STATUS = QUESTION.LINK_STATUS;
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import ProgressStatus from '@/components/project/payment/ProgressStatus.vue';

@Component({
  components: {
    ProgressStatus,
    TextBoxModal,
    SkeletonTable,
  },
})
export default class estimate extends Vue {
  @Prop() private projectId: number;
  @Prop() private title: string;
  @Prop() private settingQuota: IQuotaValue | null;
  @Prop() private questionsTotal: number;
  @Prop() private linkStatus: LINK_STATUS;
  @Prop() private price: number;
  @Prop() private resultPrice: number;
  @Prop() private priceError: boolean;
  @Prop() private priceErrorMessage: string;
  @Prop({ default: '' }) private comment: string;
  isLoading = false;

  private payMethod = '';
  settingData: IQuotaInterValue = QUOTA.settingDataInit();
  loadingTable: boolean = false;
  sumAgeArray: Array<number> = [];
  sumGenderArray: Array<number> = [];
  userName: string = '';
  userId: string = '';

  modalTitle: string = '';
  modalDescription: string = '';

  // 쿼터 설정 나이 전체
  arrayAgeTotal: Array<number> = [2];

  constructor() {
    super();
  }

  async created() {
    this.userName = this.$store.getters.userName;
    this.userId = this.$store.getters.userId;
    await this.load();
  }

  mounted() {
    this.getQuotaPart();
  }

  async load(): Promise<void> {
    try {
      const { data } = await this.axios({
        url: '/project/quota-inter',
        method: 'GET',
      });
      this.settingData = data;
      this.settingData?.RESPONDENT_COUNT?.push({ key: 9999, value: '직접 입력' });
    } catch (e) {
      console.error(e);
    }
  }

  async getQuotaPart(): Promise<void> {
    if (this.settingQuota === null) return;
    this.loadingTable = true;
    const sendData = {
      RESPONSE_CNT: Number(this.settingQuota.RESPONSE_CNT),
      GENDER: [this.settingQuota.GENDER].toString(),
      AGE_UNIT: this.settingQuota.AGE_UNIT.toString(),
      AGE_10: this.settingQuota.AGE_10 ? this.settingQuota.AGE_10.map((x) => x.toString()) : '',
      AGE_5: this.settingQuota.AGE_5 ? this.settingQuota.AGE_5.map((x) => x.toString()) : '',
    };

    try {
      const { data } = await this.axios({
        url: '/project/quota/part',
        method: 'POST',
        data: sendData,
      });
      const { result } = data;
      if (result) {
        if (this.settingQuota?.QUOTA_CNT) this.settingQuota.QUOTA_CNT = result.cnt;
        this.sumAgeArray = result.SUM_AGE;
        this.sumGenderArray = result.SUM_GENDER;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingTable = false;
    }
  }

  translateArr(array: Array<IKeyValueSetting>, key: number): string {
    let res = '';
    array.filter((item) => {
      if (key === item.key) {
        if (item.value) res = item.value.toString();
      }
    });
    return res;
  }

  setSelectPayMethod(method) {
    this.payMethod = method;
  }

  async paymentSave() {
    if (this.payMethod === '')
      return this.$common.makeToast(ToastMessage.PAYMENT_NOT_CHOICE, ToastVariant.DANGER, this.$bvToast);
    if (this.questionsTotal < 1)
      return this.$common.makeToast(ToastMessage.PAYMENT_EMPTY_QUESTION, ToastVariant.DANGER, this.$bvToast);
    if (this.resultPrice < 1)
      return this.$common.makeToast(ToastMessage.PAYMENT_EMPTY_PRICE, ToastVariant.DANGER, this.$bvToast);
    try {
      const response = await Bootpay.requestPayment({
        application_id: process.env.VUE_APP_BOOTPAY_APPLICATION_ID,
        price: this.resultPrice,
        order_name: `[유니서베이] ${this.title}`,
        order_id: 'merchant_' + new Date().getTime(),
        pg: process.env.VUE_APP_PAYMENT,
        method: this.payMethod,
        user: {
          id: this.userId,
          username: this.userName,
          email: this.userId,
        },
        extra: {
          separately_confirmed: true,
        },
      });

      switch (response.event) {
        case 'done':
          break;
        case 'confirm':
          const data = await this.paymentConfirm(response);
          if (data) {
            await this.$common.makeToast(ToastMessage.PAYMENT_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
            await this.$store.dispatch('socketStore/linkStatusCheck', this.userId);
            await this.$store.dispatch('socketStore/waitProject');

            setTimeout(() => {
              location.href = `https://unisurvey.co.kr/project/payment/success/${this.projectId}`;
            }, 500);
          }
          Bootpay.destroy();
          break;
      }
    } catch (e: any) {
      switch (e.event) {
        case 'cancel':
          break;
        case 'error':
          this.$common.makeToast(ToastMessage.PAYMENT_FAILED, ToastVariant.DANGER, this.$bvToast);
          break;
      }
    }
  }

  async paymentConfirm(response) {
    try {
      const { data } = await this.axios({
        url: `/boot-pay/payment/confirm/${this.projectId}`,
        method: 'POST',
        data: {
          ...response,
          userId: this.userId,
        },
      });
      const { result } = data;
      return result;
    } catch (e) {
      return false;
    }
  }

  updateQuestion(type: string) {
    this.isLoading = true;
    switch (type) {
      case 'question':
        this.$router.push(`/project/make/${this.projectId}`)
        break;
      case 'sampling':
        this.$router.push(`/project/sampling/${this.projectId}`)
        break;
    }
  }

  get tooltipShow(): boolean {
    if (this.paymentComplete) return false;
    if (this.priceError) return true;
    return false;
  }

  get paymentComplete(): boolean {
    return this.linkStatus > 1 && this.linkStatus !== 4;
  }

  get edit() {
    return this.linkStatus > 0 && this.linkStatus !== 3 && this.linkStatus !== 4;
  }

  // 반려당한 설문 검수 요청
  inspection() {
    this.modalTitle = '검수 요청을 하시겠습니까?';
    this.modalDescription = '다시 한번 수정할 문항을 확인해주세요.';
    this.$bvModal.show('inspection-modal');
  }

  async inspectionRequest(payload: boolean) {
    try {
      if (payload) {
        const { data } = await this.axios.put(`/project/status/inspection/${this.projectId}`);
        if (data) {
          await this.$store.dispatch('socketStore/waitProject');
          await this.$router.go(0);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  get sampleCount(): number {
    if (this.settingQuota) return this.settingQuota.RESPONSE_CNT;
    return 0;
  }

  get quotaTotal(): number {
    if (this.settingQuota === null) return 0;
    const values = Object.values(this.settingQuota.QUOTA_CNT);
    let total = 0;
    for (let i of values) {
      total += +i;
    }
    return total;
  }

  get returnArray(): Array<number> {
    let result: Array<number> = [];
    if (this.settingQuota) {
      let currKey = +this.settingQuota.AGE_UNIT;
      if (currKey === 0) result = this.arrayAgeTotal;
      if (currKey === 1 && this.settingQuota.AGE_10)
        result = this.settingQuota.AGE_10.map((m) => +m).sort((a, b) => (a > b ? 1 : -1));
      else if (currKey === 2 && this.settingQuota.AGE_5)
        result = this.settingQuota.AGE_5.map((m) => +m).sort((a, b) => (a > b ? 1 : -1));
    }
    return result;
  }

  get sortingGenderArray(): Array<number> {
    let arr: Array<number> = [];
    if (this.settingQuota) {
      if (this.settingQuota.GENDER === '0') {
        // this.currAllocateClick = 0
        arr = [1, 2];
      } else {
        arr = [+this.settingQuota.GENDER];
      }
    }
    return arr;
  }

  get totalCnt(): number[] {
    if (this.settingQuota === null) return [];
    const allGender = this.settingQuota.GENDER === '0';
    const returnArray: number[] = [];
    const total = Object.values(this.settingQuota.QUOTA_CNT);

    if (allGender) {
      const sliceNumber = total.length / 2;

      const man = total.slice(0, sliceNumber);
      const woman = total.slice(sliceNumber);

      let manSum = 0;
      let womanSum = 0;

      for (let i = 0; i < sliceNumber; i++) {
        manSum += Number(man[i]);
        womanSum += Number(woman[i]);
        const value = Number(man[i]) + Number(woman[i]);
        returnArray.push(value);
      }
      this.sumGenderArray = [manSum, womanSum];

      return returnArray;
    } else {
      let totalSum = 0;
      for (let i of total) {
        totalSum += Number(i);
        returnArray.push(+i);
      }

      this.sumGenderArray = [totalSum];
      return returnArray;
    }
  }
}
