
import { Component, Prop, Watch } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionNumInRow from '@/components/project/make/options/common/OptionNumInRow.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import OptionMinMax from '@/components/project/make/options/common/OptionMinMax.vue';
import { QUESTION } from '@/types/question';
import draggable from 'vuedraggable';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import { IAnswerValue } from '@/interface/survey/answer';
import {Properties, UploadPayload} from "@/interface/make/properties";
import {mixins} from "vue-class-component";
import {MakeMixins, MakeSurveyTemplate} from "@/mixins/project/MakeMixins";
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";

@Component({
  components: {
    draggable,
    OptionDescription,
    OptionSkipNotSkip,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionNumInRow,
    OptionRandom,
    OptionMinMax,
    OptionQuestion,
    QuestionImageUploader
  },
})
export default class OptionGradeClick extends mixins(MakeMixins) implements MakeSurveyTemplate {
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  etcFlag: boolean = false;
  notFlag: boolean = false;
  defaultMaxCount: number = 3;
  mouseEvent: number = 0;
  valueMaxError: boolean = false;

  @Watch('data.V')
  maxCount() {
    if (this.data.V) this.defaultMaxCount = this.data.V.length;
  }

  created() {
    if (this.data.V) this.defaultMaxCount = this.data.V.length;
    this.findEtc();
  }

  findEtc() {
    const etcIndex = this.data.V?.findIndex((item) => item.K === QUESTION.ANSWERS.ETC_KEY) || -1;
    const notIndex = this.data.V?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
    if (etcIndex > -1) this.etcFlag = true;
    if (notIndex > -1) this.notFlag = true;

    //편집할때 [TEXT_n] 제거
    if (this.data.V && this.data.V.length > 0) {
      for (let i = 0; i < this.data.V.length; i++) {
        const { V, K } = this.data.V[i];
        const removeValue = `[TEXT_${K}]`;
        if (V.indexOf(removeValue) > -1) {
          this.data.V[i].V = V.replace(` ${removeValue}`, '');
        }
      }
    }
  }

  resize(e: Event) {
    const target = e.target as HTMLInputElement;
    target.style.height = '1px';
    target.style.height = `${target.scrollHeight + 1}px`;
  }

  // 처음 생성됐을 경우 focus 될 경우 초기값 지우기
  removeContent(aItem: IAnswerValue): void {
    if (aItem?.CREATE) {
      aItem.V = '';
      aItem.CREATE = false;
    }
  }

  private async imageAppend(data: UploadPayload<string, File>): Promise<void> {
    const {dataUrl, file, index} = data as {dataUrl: string, file: File, index: number};
    const sizeCheck = this.fileSizeCheck(file)
    if (sizeCheck) return;
    await this.singleQuestionImageUpload(data);
    if (this.data.V?.length) {
      this.plainText = this.data.V[index].V;
      this.data.V[index].CREATE = false;
      this.data.V[index].V = this.$common.imageTagGenerator(dataUrl) + this.plainText;
      this.data.V[index].FILE = {origin: file, dataUrl: dataUrl }
    }
  }

  private removeFile(index: number): void {
    if (this.data.V) {
      this.plainText = this.data.V[index].V;
      delete this.data.V[index].FILE;
      this.data.V[index].IMG_LOCATION = '';
      this.data.V[index].V = this.$common.imageTagGenerator('') + this.plainText;
    }
    this.dataUrlList = this.dataUrlList.filter(v => v.index !== index);
  }

  set mouseHoverComputed(type) {
    this.mouseEvent = type;
  }
  get mouseHoverComputed() {
    return this.mouseEvent;
  }

  mouseHover(type) {
    this.mouseEvent = type;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  numInRowData(data: string): void {
    this.data.NUM_IN_ROW = data;
  }

  randomData(data: string): void {
    this.data.RANDOM = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  minMaxData({ min, max }: { min: string; max: string }): void {
    if (max.length === 0) this.data.MAX_GRADE = this.data.V?.length.toString();
    else this.data.MAX_GRADE = max;
    this.data.MIN_GRADE = min;
  }

  /**
   * 기타, 없음 있는지 여부 판단
   * @param key
   */
  etcNotCheck(key: string) {
    return key === QUESTION.ANSWERS.ETC_KEY || key === QUESTION.ANSWERS.NOT_KEY;
  }

  /**
   * 기타 보기 추가 값 변경될때 추가 및 삭제 하는 함수
   */
  async etcChange(): Promise<void> {
    if (this.etcFlag) {
      const etcAnswer = {
        C1: '',
        C2: '',
        C3: '',
        K: QUESTION.ANSWERS.ETC_KEY,
        V: QUESTION.ANSWERS.ETC_TEXT,
        SINGLE: false,
        DIVIDER: false,
      };
      const notIndex = this.data.V?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
      // 없음 보기 앞에다가 넣자
      if (notIndex > -1) this.data.V?.splice(notIndex, 0, etcAnswer);
      else this.data.V?.push(etcAnswer);
    } else {
      const etcIndex = this.data.V?.findIndex((item) => item.K === QUESTION.ANSWERS.ETC_KEY) || -1;
      this.data.V?.splice(etcIndex, 1);
    }
  }

  /**
   * 없음 보기 추가 값 변경될때 추가 및 삭제 하는 함수
   */
  async notChange(): Promise<void> {
    const notAnswer = {
      C1: '',
      C2: '',
      C3: '',
      K: QUESTION.ANSWERS.NOT_KEY,
      V: QUESTION.ANSWERS.NOT_TEXT,
      SINGLE: true,
      DIVIDER: false,
    };
    if (this.notFlag) {
      this.data.V?.push(notAnswer);
    } else {
      const notIndex = this.data.V?.findIndex((item) => item.K === QUESTION.ANSWERS.NOT_KEY) || -1;
      this.data.V?.splice(notIndex, 1);
    }
  }

  /**
   * 보기 삭제 및 추가
   * @param index
   * @param add
   */
  answersControl(index: number, add: boolean): void {
    this.valueMaxError = false;
    if (!add) {
      // 보기 삭제
      this.$question.removeAnswer(this.data.V, index);
    } else if (add) {
      //보기 추가
      if (this.data.V && this.data.V.length >= this.$data.setsValueMax) {
        this.valueMaxError = true;
        return;
      }
      this.$question.addAnswer(this.data.V, index);
    }
  }

  get questionNotKey(): string {
    return QUESTION.ANSWERS.NOT_KEY;
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }

  //보기마다 기타 입력 여부
  valueEtcChange(idx: number) {
    const { V = [] } = this.data;
    const answer = V[idx];
    answer.ETC = !answer.ETC;
  }
}
