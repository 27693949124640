
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import { STYLE } from '@/interface/style';

@Component
export default class OptionTextPass extends Vue {
  @Prop({ required: true, default: '' }) pass: string;

  enabled = STYLE.INPUT_ENABLED;
  disabled = STYLE.INPUT_DISABLED;
  passFlag: boolean = false;
  passText: string = '';

  created() {
    this.passText = this.pass;
    this.passFlag = this.passText !== '';
  }

  passChange() {
    if (!this.passFlag) {
      this.passText = '';
      this.$emit('set-data', this.passText);
    }
  }

  private showManual() {
    const url = require('@/assets/images/options/text-pass.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text">설정 시 응답자에게는 문항을 노출하지 않고 건너 뛰게 되며, 데이터는 자동으로 저장 됩니다.</h1>
                    <hr />
                    <img alt="무응답 통과" src="${url}" style='width: 100%;' />
                </div>`,
    };
  }
}
