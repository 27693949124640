import { IUserLogin } from '@/interface/user/user';

export namespace USER {
  export function LoginInit(): IUserLogin {
    return {
      userId: '',
      userPwd: '',
    };
  }
}

export const ADMIN_ID = 'SIMPLE-SURVEY';

export const enum ROLE {
  ADMIN = 'ADMIN',
  USER = 'USER',
}
