
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { IProjectConfig, IQuestionValue, ISurveyContent } from '@/interface/survey/question';
import EventBus from '@/utils/EventBus';
import { ins as axios } from '@/utils/axios';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class MakeTemplateModal extends Vue {
  @Prop() templateTitle?: string;
  @Prop() templateConfig?: IProjectConfig;
  @Prop() templateInfo?: Array<IQuestionValue>;

  @Ref() readonly templateCategory!: HTMLElement;
  @Ref() readonly templateTotalTime!: HTMLElement;
  @Ref() readonly templateTotalQuestion!: HTMLElement;

  localList: Array<ISurveyContent> = [];
  tag: string = '';
  newTemplateCategory: string = '';
  newTemplateTotalQuestion: string = '';
  newTemplateTotalTime: string = '';
  newTemplateAddTags: string[] = [];

  setTemplateInfo() {
    return {
      TITLE: this.templateTitle,
      DATA: this.templateInfo,
      CONFIG: this.templateConfig,
      CATEGORY: this.newTemplateCategory,
      ANSCOUNT: `${this.newTemplateTotalQuestion}문항`,
      TIME: `${this.newTemplateTotalTime}분`,
      HASHTAG: this.newTemplateAddTags,
    };
  }

  validation(): boolean {
    if (!this.newTemplateCategory.length) {
      this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_EMPTY_CATEGORY, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.templateCategory.focus());
      return false;
    } else if (!this.newTemplateTotalQuestion.length) {
      this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_EMPTY_SURVEY_COUNT, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.templateTotalQuestion.focus());
      return false;
    } else if (!this.newTemplateTotalTime.length) {
      this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_TIME, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.templateTotalTime.focus());
      return false;
    } else {
      return true;
    }
  }

  closeTemplateModal() {
    this.resetValue();
    this.$bvModal.hide('new-template-registration-modal');
  }

  async templateRegistration() {
    try {
      const validate = this.validation();
      const sendData = this.setTemplateInfo();
      if (validate) {
        const { data } = await this.axios.post('/project/new/template', sendData);
        const { result } = data;

        if (result) {
          this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
          EventBus.$emit('reloadTemplateGallery', true);
        } else {
          this.$common.makeToast(ToastMessage.MAKE_TEMPLATE_FAILED, ToastVariant.DANGER, this.$bvToast);
        }
        this.resetValue();
        this.$bvModal.hide('new-template-registration-modal');
      }
    } catch (e) {
      console.log(e);
    }
  }
  resetValue() {
    this.newTemplateCategory = '';
    this.newTemplateTotalQuestion = '';
    this.newTemplateTotalTime = '';
    this.newTemplateAddTags = [];
  }
  addTag(): void {
    if (this.tag.length) this.newTemplateAddTags.push(this.tag);
    this.tag = '';
  }
  removeTag(tag: string): void {
    this.newTemplateAddTags.splice(this.newTemplateAddTags.indexOf(tag), 1);
  }
  async openMake(index) {
    try {
      const { SNUM } = this.localList[index];
      const { data } = await axios.get(`/user/verify`, {
        params: { login: 'login' },
      });
      const { token } = data;
      window.open(`/project/new-make/${token}?sNum=${SNUM}`);
    } catch (e) {
      console.log(e);
    }
  }
}
