
import {Component, Vue} from "vue-property-decorator";
import {SOCIAL} from "@/constants/config";
import {v4 as uuidv4} from 'uuid';

@Component({})
export default class NaverLogin extends Vue {

  get stateId() {
    return uuidv4();
  }

  /**
   * @description: state의 경우 NAVER 에서 CSRF 공격을 방지하기 위한 임의의 스트링값을 넘겨주게 되어있음
   */
  naverLogin() {
    try {
      const callbackUrl = SOCIAL.NAVER.CALLBACK;
      const state = this.stateId;
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_NAVER_CLIENT_ID}&redirect_uri=${callbackUrl}&state=${state}`;
      window.location.href = url;
    } catch (e) {
      console.log(e);
    }
  }
}
