
import { Component, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({})
export default class AdminCheck extends Vue {
  token = '';
  path = '';

  async created() {
    this.urlMaker();
    await this.tokenDecode();
  }

  async tokenDecode() {
    try {
      const axiosValue = await this.axios.get(`/user/join-verify-success/${this.token}`);

      const { data } = axiosValue;
      const { result, _doc: doc } = data;
      const { _id, NICKNAME, ROLE, LOGIN_TYPE, NAME, OBJECT_ID: HISTORY_ID } = doc;
      if (result) {
        const loginData = {
          _id,
          NICKNAME,
          ROLE,
          LOGIN_TYPE,
          NAME,
          HISTORY_ID,
          result,
          token: this.token,
        };
        await this.login(loginData);
      }
    } catch (e) {
      console.log(e);
      this.$common.makeToast(ToastMessage.INVALID, ToastVariant.DANGER, this.$bvToast);
    }
  }

  async login(loginData: any) {
    try {
      await this.$store.dispatch('login', { data: {...loginData} });
      await this.$router.push(`/${this.path}`);
    } catch (e) {
      this.$common.makeToast(ToastMessage.INVALID, ToastVariant.DANGER, this.$bvToast);
    }
  }

  /*
    @description: /:domain/:routerType/:id/:token
   */
  private urlMaker() {
    const fullPath = this.$route.path.split('/').filter(Boolean);
    const tokenIndex = fullPath.length - 1;
    this.token = fullPath[tokenIndex];
    this.path = fullPath.slice(0, tokenIndex).join('/');
  }
}
