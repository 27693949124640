
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ISkipValue, IQuestionValue } from '@/interface/survey/question';
import type { IArrayOption } from '@/interface/common';
import { QUESTION } from '@/types/question';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class OptionSkipNoSkip extends Vue {
  @Prop() question: IQuestionValue;
  @Prop({ default: [] }) skip: ISkipValue[];
  @Prop({ default: [] }) NotSkip: ISkipValue[];

  tempSkip: ISkipValue[] = [];
  tempNotSkip: ISkipValue[] = [];
  options: IArrayOption[] = [];
  questionList: IQuestionValue[] = [];

  // 보기 가져오기 문항 체크
  parentName: string | undefined = '';
  parentKey: boolean = false;

  created() {
    this.tempSkip = this.skip;
    this.tempNotSkip = this.NotSkip;
    const { FIXED_QUESTIONS, QUESTION_TYPES } = QUESTION;
    if (this.tempSkip.length === 0) this.tempSkip.push(QUESTION.defaultSkipData());
    if (this.tempNotSkip.length === 0) this.tempNotSkip.push(QUESTION.defaultSkipData());

    this.questionList = this.$question.getMakeList();
    // radio, check ,grade
    this.options = this.questionList
      .filter((item) => {
        const { NAME, TYPE } = item;
        return (
          //   NAME !== this.$question.getDataName &&
          FIXED_QUESTIONS.indexOf(NAME) === -1 &&
          (TYPE === QUESTION_TYPES.RADIO ||
            TYPE === QUESTION_TYPES.CHECK ||
            TYPE === QUESTION_TYPES.GRADE_CLICK ||
            TYPE === QUESTION_TYPES.RADIOSET)
        );
      })
      .map((item) => {
        const { NAME, QUESTION, QNUM } = item;
        const text = this.$common.replaceHtmlTag(QUESTION);
        return {
          text: `${QNUM} - ${text}`,
          value: NAME,
        };
      });
  }

  mounted() {
    this.parentQuestion();
  }

  /*
   * 현재 실행 시킨 문항 question
   * 전체 문항 리스트 questionList
   * 넘겨준 문항을 가지고 부모문항의 ANSWERS의 기타 / 보기 값을 체크
   *
   * */
  parentQuestion() {
    // showHide 값이 show인 문항만 적용
    const childQuestion = this.question.hasOwnProperty('showHide' && 'PARENT_NAME');
    if (childQuestion) {
      const { showHide, PARENT_NAME } = this.question;
      if (showHide === QUESTION.CHILD_TYPE.SHOW) {
        const parentIndex = this.questionList.findIndex((question) => question.NAME === PARENT_NAME);
        const parent = this.questionList[parentIndex];
        this.parentName = parent.QNUM;
        this.parentKey = parent.ANSWERS!.findIndex((question) => question.K === '9997' || question.K === '9998') > -1;
      }
    } else {
      this.parentKey = false;
    }
  }

  /**
   * 조건 row 추가
   * @param skipArray
   * @param index
   */
  addSkipArray(skipArray: ISkipValue[], index: number) {
    const pushData: ISkipValue = QUESTION.defaultSkipData();
    skipArray.splice(index + 1, 0, pushData);
  }

  /**
   * 조건 row 삭제
   * @param skipArray
   * @param index
   */
  spliceSkipArray(skipArray: ISkipValue[], index: number, gubun: string) {
    if (skipArray.length > 1) skipArray.splice(index, 1);
    else {
      if (gubun === 'skip') this.tempSkip = [QUESTION.defaultSkipData()];
      else if (gubun === 'not-skip') this.tempNotSkip = [QUESTION.defaultSkipData()];
      this.$emit('set-data', {
        skip: this.tempSkip,
        notSkip: this.tempNotSkip,
      });
    }
  }

  valueCheck(skip: ISkipValue, index: number, gubun: string) {
    const { NAME, VALUE } = skip;
    const valueArray = VALUE.split(',');
    valueArray.forEach((item, index) => {
      valueArray[index] = this.$common.replaceOnlyNumber(item);
    });
    skip.VALUE = valueArray.join(',');
    const reg = /[^-\,0-9999]/g;
    if (String(VALUE).replace(/ /g, '') !== '' && NAME !== '') {
      if (reg.test(VALUE) === false) {
        const values = valueArray.map((item) => `[${NAME}=${this.$common.replaceOnlyNumber(item)}]==true`);
        skip.CONDITION = values.join('&&');
      }
    }

    if (NAME === '' || VALUE === '')
      this.$common.makeToast(`${ToastMessage.SKIP_NOT_SKIP_MESSAGE} ${VALUE}`, ToastVariant.WARNING, this.$bvToast);

    this.$emit('set-data', {
      skip: this.tempSkip,
      notSkip: this.tempNotSkip,
    });
  }

  getQuestionQNum(name: string): string {
    const findIndex = this.questionList.findIndex((item) => item.NAME === name);
    return findIndex > -1 ? this.questionList[findIndex]['QNUM'] : '';
  }

  private showManual(type: string) {
    switch (type) {
      case 'notSkip':
        const url1 = require('@/assets/images/options/notSkip1.png');
        const url2 = require('@/assets/images/options/notSkip2.png');
        return {
          html: true,
          delay: {
            show: 100,
          },
          placement: 'right',
          content: `<div>
                    <h1 class="option-description--text">특정 보기를 선택하지 않은 사람에게만 문항을 제시하고 싶다면 <b>[문항 제시]</b> 기능을 활용해보세요.</h1>
                    <br/>
                    <img alt="문항제시1" style='width: 100%; padding-top: 20px;' src="${url1}" />
                    <hr />
                    <h2 class="option-description--text"><b>(예시)</b> Q1에서 2)사이다를 선택한 사람에게만 Q2를 묻고 싶은 경우</h2><br/>
                    <h2 class="option-description--text"><b>[대상 문항]</b>에서 Q1 선택 후, <b>[보기 입력]</b>에서 원하는 보기 번호 (2)를 입력 후 저장하세요.</h2>
                    <img alt="문항제시2" style='width: 100%; padding-bottom: 20px;' src="${url2}" />

                </div>`,
        };
      case 'skip':
        const url3 = require('@/assets/images/options/skip1.png');
        const url4 = require('@/assets/images/options/skip2.png');
        return {
          html: true,
          delay: {
            show: 100,
          },
          placement: 'right',
          content: `<div>
                    <h1 class="option-description--text">특정 보기를 선택한 사람에게 문항을 제시하고 싶지 않다면 <b>[문항 생략]</b> 기능을 활용해보세요.</h1>
                    <br/>
                    <img alt="문항생략1" style='width: 100%; padding-top: 20px;' src="${url3}" />
                    <hr />
                    <h2 class="option-description--text"><b>(예시)</b> Q3에서 2)프로야구를 선택한 사람에게 Q4를 질문하고 싶지 않은 경우</h2><br/>
                    <h2 class="option-description--text"><b>[대상 문항]</b>에서 Q3 선택 후, <b>[보기 입력]</b>에서 원하는 보기 번호 (2)를 입력 후 저장하세요.</h2>
                    <img alt="문항생략2" style='width: 100%; padding-bottom: 20px;' src="${url4}" />
                </div>`,
        };
      case 'question_skip':
        const or = require('@/assets/images/options/or.png');
        const and = require('@/assets/images/options/and.png');

        return {
          html: true,
          delay: {
            show: 100,
          },
          placement: 'right',
          content: `<div>
                    <h1 class="option-description--text">(예시) 1,2,3 모두 선택하는 조건을 걸 때 (AND 조건)</h1>
                    <br/>
                    <img alt="문항생략1" style='width: 100%; padding-top: 20px;' src="${or}" />
                    <hr />
                    <h1 class="option-description--text">(예시) 1,2 중 최소 1개 선택하는 조건을 걸 때 (OR 조건)</h1>
                    <br/>
                    <img alt="문항생략1" style='width: 100%; padding-top: 20px;' src="${and}" />
                </div>`,
        };
      case 'question_show':
        const show_or = require('@/assets/images/options/show_and.png');
        const show_and = require('@/assets/images/options/show_or.png');
        return {
          html: true,
          delay: {
            show: 100,
          },
          placement: 'right',
          content: `<div>
                    <h1 class="option-description--text">(예시) 1,2,3 모두 선택하는 조건을 걸 때 (AND 조건)</h1>
                    <br/>
                    <img alt="문항생략1" style='width: 100%; padding-top: 20px;' src="${show_and}" />
                    <hr />
                    <h1 class="option-description--text">(예시) 1,2 중 최소 1개 선택하는 조건을 걸 때 (OR 조건)</h1>
                    <br/>
                    <img alt="문항생략1" style='width: 100%; padding-top: 20px;' src="${show_or}" />
                </div>`,
        };
    }
  }
}
