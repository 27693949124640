export namespace RESULT {
  export const enum CHART_TYPE {
    DEFAULT = 'DEFAULT',
    PIE = 'PIE',
    BAR = 'BAR',
  }

  export const enum CHART_SORT_TYPE {
    DEFAULT,
    VALUE_RANK,
  }
  export const enum TEXT_SORT_TYPE {
    ASC = '오름차순',
    DESC = '내림차순',
  }

  export const enum PDF_IMAGE_TYPE {
    TITLE = 'TITLE',
    CONTENT = 'CONTENT',
  }
}
