import { IQuotaCnt, IQuotaInterValue, IQuotaResetOption } from '@/interface/survey/quota';

export namespace QUOTA {
  export const enum SETTING_OPTION {
    QUOTA,
    ALLOCATE,
    AREA,
    ALL,
  }

  export function resetSettingLoad(): IQuotaResetOption {
    return {
      ALLOCATE: SETTING_OPTION.ALLOCATE,
      QUOTA: SETTING_OPTION.QUOTA,
      AREA: SETTING_OPTION.AREA,
      ALL: SETTING_OPTION.ALL,
    };
  }

  export function settingDataInit(): IQuotaInterValue {
    return {
      RESPONDENT_COUNT: [],
      QUOTA: {
        GENDER: [],
        AGE_5: [],
        AGE_10: [],
        AGE_UNIT: [],
      },
      ALLOCATE: [],
      AREA: [],
    };
  }

  export function quotaCntInit(): IQuotaCnt {
    return {
      '1//2/': '0',
      '1//3/': '0',
      '1//4/': '0',
      '1//5/': '0',
      '1//6/': '0',
      '1//7/': '0',
      '1//8/': '0',
      '1//9/': '0',
      '1//10/': '0',
      '1//11/': '0',
      '2//2/': '0',
      '2//3/': '0',
      '2//4/': '0',
      '2//5/': '0',
      '2//6/': '0',
      '2//7/': '0',
      '2//8/': '0',
      '2//9/': '0',
      '2//10/': '0',
      '2//11/': '0',
    };
  }
}
