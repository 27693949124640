
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class RequestAuthNumber extends Vue {
  @Prop({ required: false, default: '' }) reqUserId: string;
  @Prop({default: false}) findPassword?: boolean;

  userPhone: string = '';
  userObjectId: string = '';
  userId: string = '';
  authNumberConfirm: string = '';
  authNumber: string = '';
  authFlag = false;
  authErrorMessage = '인증번호를 입력해 주세요';
  timerLimit: number = 181;
  timer: ReturnType<typeof setInterval>;
  pending = false;

  @Watch('timerLimit')
  watchTimerLimit() {
    if (this.timerLimit < 1) {
      this.authFlag = false;
      this.authErrorMessage = '인증시간이 만료되었습니다';
      this.timerLimit = 181;

      clearInterval(this.timer);
    }
  }

  auth() {
    if (this.authNumber === this.authNumberConfirm && this.authNumber.length === 6) {
      this.authFlag = true;
      clearInterval(this.timer);
    } else {
      this.$common.makeToast(ToastMessage.WRONG_AUTH_NUMBER, ToastVariant.DANGER, this.$bvToast);
    }
  }

  reset() {
    this.authNumber = '';
    this.authNumberConfirm = '';
    this.authFlag = false;
    this.userPhone = '';
    this.userId = '';
    this.timerLimit = 181;
    this.authErrorMessage = '인증번호를 입력해 주세요';
    clearInterval(this.timer);
  }

  authNumberCheck(): boolean {
    return (
      this.authNumber === this.authNumberConfirm && this.authNumber.length === 6 && this.authFlag && this.timerLimit > 0
    );
  }

  get phoneCheck(): boolean {
    return this.$common.mobileTelCheck(this.userPhone);
  }

  async existsPhone(): Promise<void> {
    try {

      if (this.findPassword && !this.$common.emailCheck(this.reqUserId)) {
        this.$common.makeToast(ToastMessage.NOT_MATCH_EMAIL, ToastVariant.DANGER, this.$bvToast);
        return;
      } else if (!this.phoneCheck) {
        this.$common.makeToast(ToastMessage.NOT_MATCH_PHONE, ToastVariant.DANGER, this.$bvToast);
        return;
      }
      this.pending = true;

      const { data } = await this.axios.get('/user/find-auth',{
        params: {
          phone: this.userPhone,
          userId: this.reqUserId,
        },
      });
      const { exist, objectId, user, authNumber } = data;

      if (exist) {
        const { _id } = user;
        this.userId = _id;
        this.userObjectId = objectId;
        this.$common.makeToast(ToastMessage.SEND_MAIL, ToastVariant.PRIMARY, this.$bvToast);
        this.authNumber = authNumber;

        this.timer = setInterval(() => {
          this.timerLimit--;
          const minute: number = Math.floor(this.timerLimit / 60);
          const second = minute > 0 ? this.timerLimit % (60 * minute) : this.timerLimit;
          const secondStr = String(second + 100).substring(1, 3);
          this.authErrorMessage = `[인증시간: ${minute}:${secondStr}]`;
          if (this.timerLimit <= 0) this.pending = false;
        }, 1000);
      } else {
        this.pending = false;
      }
    } catch (e) {
      console.error(e);
      this.pending = false;
    }
  }
}
