import { render, staticRenderFns } from "./InfoTitle.vue?vue&type=template&id=92edce6a&scoped=true"
import script from "./InfoTitle.vue?vue&type=script&lang=ts"
export * from "./InfoTitle.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92edce6a",
  null
  
)

export default component.exports