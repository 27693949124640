
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IQuestionValue } from '@/interface/survey/question';
import draggable from 'vuedraggable';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

interface IModuleList extends IQuestionValue {
  CHECKED: boolean;
  SHOW_YN: boolean;
}

@Component({
  components: {
    draggable,
  },
})
export default class CrossTableDownloadModal extends Vue {
  @Prop() private SNUM: string;
  @Prop() private modules: Array<IQuestionValue>;
  pending: boolean = false;
  moduleList: Array<IModuleList> = [];
  banners: Array<IModuleList> = [];
  $refs: Vue['$refs'] & {};

  @Watch('modules')
  testFunction() {
    this.watchModuleList();
  }
  // mounted() {
  //   this.watchModuleList();
  // }

  get dragOptions() {
    return {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  watchModuleList() {
    if (this.modules.length === 0) return [];
    this.moduleList = this.modules
      .filter((f) => {
        return (
          f.TYPE === 'RADIO' ||
          f.TYPE === 'RADIOSET' ||
          f.TYPE === 'RADIOSETS' ||
          f.TYPE === 'GRADE' ||
          f.TYPE === 'GRADE_CLICK'
        );
      })
      .map((m) => {
        const { QNUM, QUESTION, TYPE, CHECKED, SHOW_YN } = m;
        const show = SHOW_YN === undefined ? true : SHOW_YN;
        return {
          ...m,
          QNUM,
          QUESTION,
          TYPE,
          CHECKED: CHECKED ? CHECKED : false,
          SHOW_YN: show,
        };
      });
  }

  checkChange(obj, target) {
    const { QNUM, CHECKED } = obj;
    if (target === null) return;
    const index = target.findIndex((f) => f.QNUM === QNUM);
    obj.CHECKED = !CHECKED;
    target.splice(index, 1, obj);
  }

  exchange() {
    const moduleList = this.moduleList.filter((f) => f.CHECKED);
    const bannerList = this.banners.filter((f) => f.CHECKED);
    if (this.banners.length + moduleList.length - bannerList.length > 4) {
      this.$common.makeToast(ToastMessage.SET_BANNER, ToastVariant.DANGER, this.$bvToast);
      return;
    }

    for (const l of moduleList) {
      const { QNUM, SHOW_YN } = l;
      if (!SHOW_YN) continue;

      l.CHECKED = false;
      l.SHOW_YN = false;
      const index = this.modules.findIndex((f) => f.QNUM === QNUM);
      this.modules.splice(index, 1, JSON.parse(JSON.stringify(l)));
      this.banners.push(l);
    }

    for (const b of bannerList) {
      const { QNUM } = b;
      b.CHECKED = false;
      b.SHOW_YN = true;

      const index = this.modules.findIndex((f) => f.QNUM === QNUM);
      this.modules.splice(index, 1, b);

      const bannerIndex = this.banners.findIndex((f) => f.QNUM === QNUM);
      this.banners.splice(bannerIndex, 1);
    }
  }

  async download() {
    this.$bvModal.show('download-process-modal');
    try {
      this.pending = true;
      const banners = this.banners.map((m) => m.QNUM);
      if (banners.length > 0) {
        await this.axios.get(`/project/crossTab?SNUM=${this.SNUM}`)
        location.href = `/api/project/crossTab/download?SNUM=${this.SNUM}&banners=${banners}`;
        this.$common.makeToast(ToastMessage.CREATE_DATA_COMPLETE, ToastVariant.SUCCESS, this.$bvToast);

        setTimeout(() => {
          this.$bvModal.hide('download-process-modal')
          this.$bvModal.hide('crossTable');
        }, 2000);
      } else this.$common.makeToast(ToastMessage.NOT_SELECTED_BANNER, ToastVariant.DANGER, this.$bvToast);
      this.pending = false;
    } catch (e) {
      this.pending = false;
      this.$bvModal.hide('download-process-modal');
      this.$common.makeToast('다운로드에 실패하였습니다. 잠시 후 다시 시도해 주세요.', ToastVariant.DANGER, this.$bvToast);
    }
  }
}
