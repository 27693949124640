
import { Component, Prop } from 'vue-property-decorator';
import type { IQuestionValue, ISkipValue } from '@/interface/survey/question';
import OptionAnswerCheck from '@/components/project/make/options/common/OptionAnswerCheck.vue';
import OptionPageEnd from '@/components/project/make/options/common/OptionPageEnd.vue';
import OptionBtnHide from '@/components/project/make/options/common/OptionBtnHide.vue';
import OptionNumInRow from '@/components/project/make/options/common/OptionNumInRow.vue';
import OptionRandom from '@/components/project/make/options/common/OptionRandom.vue';
import OptionLeftCategory from '@/components/project/make/options/common/OptionLeftCategory.vue';
import OptionDescription from '@/components/project/make/options/common/OptionDescription.vue';
import OptionSkipNotSkip from '@/components/project/make/options/common/OptionSkipNotSkip.vue';
import OptionMinMax from '@/components/project/make/options/common/OptionMinMax.vue';
import OptionTextPass from '@/components/project/make/options/common/OptionTextPass.vue';
import OptionRegex from '@/components/project/make/options/common/OptionRegex.vue';
import { ITextDenyValue } from '@/interface/survey/question';
import OptionQuestion from '@/components/project/make/options/common/OptionQuestion.vue';
import {Properties} from "@/interface/make/properties";
import {mixins} from "vue-class-component";
import {MakeMixins, MakeSurveyTemplate} from "@/mixins/project/MakeMixins";

@Component({
  components: {
    OptionDescription,
    OptionSkipNotSkip,
    OptionMinMax,
    OptionRegex,
    OptionTextPass,
    OptionAnswerCheck,
    OptionPageEnd,
    OptionBtnHide,
    OptionNumInRow,
    OptionRandom,
    OptionLeftCategory,
    OptionQuestion,
  },
})
export default class OptionTextArea extends mixins(MakeMixins) implements MakeSurveyTemplate{
  @Prop({required: true}) private readonly data: Required<IQuestionValue>;

  passData(pass: string): void {
    this.data.PASS = pass;
  }

  answerCheckData(flag: boolean): void {
    this.data.ANSWER_CHECK = flag;
  }

  pageEndData(flag: boolean): void {
    this.data.PAGE_END = flag;
  }

  btnHideData(data: string): void {
    this.data.BTN_HIDE = data;
  }

  descriptionData({ desc, topDesc, bottomDesc }: { desc: string; topDesc: string; bottomDesc: string }): void {
    this.data.DESC = desc;
    this.data.TOP_DESC = topDesc;
    this.data.BOTTOM_DESC = bottomDesc;
  }

  skipNotSkipData({ skip, notSkip }: { skip: ISkipValue[]; notSkip: ISkipValue[] }) {
    this.data.SKIP = skip;
    this.data.NOTSKIP = notSkip;
  }

  minMaxLengthData({ min, max }: { min: string; max: string }): void {
    this.data.MIN_LENGTH = min;
    this.data.MAX_LENGTH = max;
  }

  textDenyData(textDeny: ITextDenyValue[]): void {
    this.data.TEXT_DENY = textDeny;
  }

  async setQuestionTitle(payload: Properties.QuestionTitlePayload): Promise<void> {
    this.data.QUESTION = await this.questionTitle(payload);
  }

}
