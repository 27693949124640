<script>
import { HorizontalBar } from 'vue-chartjs';
import { horizontalBarOption } from '@/mixins/chartOption';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: HorizontalBar,
  props: ['chartData', 'viewOption'],
  data: () => ({
    totalArray: {
      dataArray: [],
      labelArray: [],
      colorArray: [],
      cntArray: [],
      keyArray: [],
    },
    options: {},
  }),
  computed: {},
  methods: {},
  created() {
    this.chartData.forEach((chartData) => {
      const { percent, text, color, cnt, key } = chartData;
      // 차트 라벨에 TEXT 항목 삭제
      const removeValue = `[TEXT_${key}]`;
      let removeTextLabel = this.$common.removeImageTag(text);
      if (text.indexOf(removeValue) > -1) {
        removeTextLabel = removeTextLabel.replace(removeValue, '');
      }
      this.totalArray.labelArray.push(removeTextLabel);
      this.totalArray.dataArray.push(percent);
      this.totalArray.colorArray.push(color);
      this.totalArray.cntArray.push(cnt);
      this.totalArray.keyArray.push(key);
    });
    this.options = horizontalBarOption(this.totalArray.cntArray, this.totalArray.keyArray, this.viewOption);
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(
      {
        labels: this.totalArray.labelArray,
        datasets: [
          {
            backgroundColor: this.totalArray.colorArray,
            borderColor: this.totalArray.colorArray,
            pointBackgroundColor: 'white',
            borderWidth: 1,
            data: this.totalArray.dataArray,
            barPercentage: 0.5,
          },
        ],
      },
      this.options
    );
  },
  watch: {
    dataArray() {
      this.renderChart(
        {
          labels: this.chartHeader,
          datasets: [
            {
              backgroundColor: this.totalArray.colorArray,
              borderColor: this.totalArray.colorArray,
              borderWidth: 1,
              pointBackgroundColor: 'white',
              data: this.totalArray.dataArray,
              barPercentage: 0.5,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>
