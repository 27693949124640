
import { Component, Vue } from 'vue-property-decorator';
import FindId from '@/components/users/FindId.vue';
import FindPw from '@/components/users/FindPw.vue';
import QuestionWriteModal from '@/components/board/QuestionWriteModal.vue';

@Component({
  components: {
    FindPw,
    FindId,
    QuestionWriteModal,
  },
})
export default class FindAccount extends Vue {
  tabIndex: number = 0;
  $refs: Vue['$refs'] & {
    findId: FindId;
    findPw: FindPw;
    QuestionWriteModal: QuestionWriteModal;
  };

  findIdReset() {
    if (this.tabIndex === 0) return;
    this.$refs.findId?.reset();
  }

  findPwReset() {
    if (this.tabIndex === 1) return;
    this.$refs.findPw?.reset();
  }
}
