
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IPolicy} from "@/interface/template/template";
import DUMMY from "@/assets/policy";

@Component
export default class PolicyModal extends Vue{

  policy: IPolicy = {
    policyContent: DUMMY.policyContent,
    privateContent: DUMMY.privateContent,
  };
}
